import { GridCardsProps } from '@/components/common/layout/GridCards';
import { CardsDataBoundary } from '@/components/common/cards/CardsDataBoundary';
import { BroadcastMessageSearchItem } from '@/types/broadcastMessage';
import { BroadcastMessageCard } from '@feature/broadcastMessage/BroadcastMessageCard';

export type BroadcastMessageCardsProps = {
  items?: BroadcastMessageSearchItem[];
} & GridCardsProps;

export const BroadcastMessageCards = (props: BroadcastMessageCardsProps) => (
  <CardsDataBoundary {...props}>
    {props.items?.map((item) => (
      <BroadcastMessageCard key={item.id} broadcastMessage={item} />
    ))}
  </CardsDataBoundary>
);

import { stripTags, truncateString } from '@/shared/helpers/utils';
import { Tooltip } from 'antd';
import { upperFirst } from 'lodash';
import { CSSProperties } from 'react';

interface Props {
  text: string | undefined;
  lines?: number;
  max: number;
  tooltip?: boolean;
  tag?: 'div' | 'span';
}

/**
 * Truncated text -> TODO: rename to Text
 * @param text input text
 * @param lines limit lines count
 * @param max limit length
 * @param tooltip show full input text in tooltip
 * @param tag wrap in tag div|span (div by default)
 */
export const Trunc: FC<Props> = ({ text = '', lines, max, tooltip, tag }) => {
  if (!text?.trim()) return null;

  const processedText = stripTags(text); // remove HTML TAGS
  const str = upperFirst(processedText);

  const lineClampStyle: CSSProperties = {
    display: '-webkit-box',
    WebkitLineClamp: lines,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    lineHeight: 1.3,
  };

  const Element = tag || 'div'; // from props or div
  const Wrapper = tooltip && processedText.length > Number(max) ? Tooltip : ({ children }) => children;

  return (
    <Wrapper title={processedText}>
      <Element style={lines ? lineClampStyle : {}}>{max ? truncateString(str, max) : str}</Element>
    </Wrapper>
  );
};

import { Popover, PopoverProps } from 'antd';
import { PropsWithChildren, ReactNode } from 'react';

import { Modal } from './Modal';
import { useBreakpoints } from '@/hooks/breakpoints/useBreakpoints';

type PopoverResponsiveProps = PropsWithChildren<
  PopoverProps & {
    title?: ReactNode;
    content?: ReactNode;
  }
>;
export const PopoverResponsive = (props: PopoverResponsiveProps) => {
  const { isSm } = useBreakpoints();

  return isSm ? (
    <>
      <div
        onClick={() => {
          props?.onOpenChange?.(true);
        }}
      >
        {props.children}
      </div>
      <Modal
        destroyOnClose
        open={props.open}
        onCancel={(event) => {
          event?.stopPropagation();
          props.onOpenChange?.(false);
        }}
        title={props.title}
      >
        {props.content}
      </Modal>
    </>
  ) : (
    <Popover {...props} />
  );
};

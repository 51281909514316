// Context -> Bind form with Message
import { InputRef } from 'antd';
import React from 'react';
import { CommonMessage, MessengerChannels } from '../types/messages';
import { MessengerProps } from '../ui/Messenger';

// TODO: Performance
type MessengerContextType = MessengerProps & {
  parentMessage: CommonMessage;
  setParentMessage: (msg?: CommonMessage) => void | React.Dispatch<React.SetStateAction<CommonMessage | undefined>>; // reply mode state controller
  editedMessage: CommonMessage;
  setEditedMessage: (msg?: CommonMessage) => void | React.Dispatch<React.SetStateAction<CommonMessage | undefined>>; // edit mode state
  mobileClickedMessage: CommonMessage;
  setMobileClickedMessage?: (msg?: CommonMessage) => void | React.Dispatch<React.SetStateAction<CommonMessage | undefined>>; // mobile actions mode state
  editorRef: React.Ref<InputRef>;
  activeChannel: MessengerChannels;
  // limit messages for better performance
  limit: number;
  setLimit: (limit: number) => void;
  refetch: () => void;
  isFetching: boolean;
  layout: 'wide' | 'narrow' | 'pinned';
  editingEnabled: boolean; // support only
  hoverActionsAvailable: boolean;
  observer: IntersectionObserver;
};

// Context
export const MessengerContext = React.createContext<Partial<MessengerContextType>>({ layout: 'wide' });

// Main hook
export const useMessengerContext = (): Partial<MessengerContextType> => React.useContext(MessengerContext);

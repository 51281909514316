import { Component, ReactNode } from 'react';
import { ErrorPage } from '@/components/common/errors/ErrorPage';

interface Props {
  children: ReactNode;
}

interface State {
  error: { name: string } | null;
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { error: null, hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error('Error Boundary Caught:', error, errorInfo);
  }

  render() {
    const { error, hasError } = this.state;

    const isChunkLoadError = error?.name === 'ChunkLoadError';

    if (hasError) {
      return <ErrorPage code={isChunkLoadError ? 10 : 0} />;
    }
    return this.props.children;
  }
}

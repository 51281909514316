import { TabsProps } from 'antd';
import { Dispatch, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type UseTabsReturnType = {
  activeTab: string;
  setActiveTab: Dispatch<string>;
  smartTabs: Pick<TabsProps, 'defaultActiveKey' | 'onChange' | 'activeKey' | 'size'>;
};

type UseSmartTabsParams = (args: { defaultActiveKey: string; queryKey?: string; props?: TabsProps }) => UseTabsReturnType;
export const useSmartTabs: UseSmartTabsParams = ({ defaultActiveKey, queryKey = 'tab', props }) => {
  const { search } = window.location;
  const url = new URLSearchParams(search);
  const currentTab = url.get(queryKey);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState<string>(currentTab || defaultActiveKey);

  const onChange = (tabKey: string) => {
    url.set(queryKey, tabKey);

    navigate(`${pathname}?${url.toString()}`);

    setActiveTab(tabKey);
  };

  useEffect(() => {
    const url = new URLSearchParams(search);
    const currentTab = url.get(queryKey);

    setActiveTab(currentTab || defaultActiveKey);
  }, [queryKey, search, defaultActiveKey]);

  return {
    activeTab,
    setActiveTab,
    smartTabs: {
      defaultActiveKey,
      onChange,
      activeKey: activeTab,
      size: 'large',
      ...props,
    },
  };
};

export const useUrlPathTabs: UseSmartTabsParams = ({ defaultActiveKey, props }) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(defaultActiveKey);

  const onChange = (key: string) => {
    navigate(`/my-orders/${key}`);
    setActiveTab(key);
  };

  return {
    activeTab,
    setActiveTab,
    smartTabs: {
      defaultActiveKey,
      onChange,
      activeKey: activeTab,
      size: 'large',
      ...props,
    },
  };
};

import { CSSProperties, FunctionComponent } from 'react';
import { Spin } from 'antd';

import s from './SpinFullscreen.module.less';

interface IProps {
  tip?: string;
  style?: CSSProperties;
}
export const SpinFullscreen: FunctionComponent<IProps> = ({ tip, style }) => (
  <Spin size="large" tip={tip} className={s.spinner} style={style} />
);

import { API_BASE_URL } from '@/config/constants';
import { API_TAG_LIST, ApiTag } from '@/config/features';
import { msg, showErrorNotification } from '@/helpers/msg';
import { t } from '@/services/translator';
import { authLogout } from '@/store/auth/authActions';
import { RootState } from '@/store/store';
import { ApiEndpointExtraOptions, ErrorResponse } from '@/types/common/api';
import { IAccessToken } from '@/types/common/auth';
import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import qs from 'query-string';
import { refreshAccessToken } from './auth/authSlice';

let refreshTokenPromise: Promise<boolean> | null = null;

const baseQuery = fetchBaseQuery({
  baseUrl: API_BASE_URL,
  paramsSerializer: (params) => qs.stringify(params, { skipEmptyString: true, skipNull: true }),
  prepareHeaders: (headers, { getState }) => {
    const { accessToken } = (getState() as RootState).auth.tokens;
    accessToken && headers.set('Authorization', `Bearer ${accessToken}`);
    return headers;
  },
});

let _api;

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions: ApiEndpointExtraOptions,
) => {
  const initialAccessToken = (api.getState() as RootState).auth.tokens.accessToken;

  if (refreshTokenPromise) await refreshTokenPromise;

  // Original request
  let result = await baseQuery(args, api, extraOptions);

  // if request is successful do nothing
  if (!result.error) return result;

  const { status } = result.error;

  const currentAccessToken = (api.getState() as RootState).auth.tokens.accessToken;

  // Access token expired
  if (status === 401) {
    const { refreshToken } = (api.getState() as RootState).auth.tokens;

    // if no refresh token logout and return error
    if (!refreshToken) {
      api.dispatch(authLogout(_api));
      return result;
    }

    const isRefreshRequestRequired =
      !refreshTokenPromise && (currentAccessToken === initialAccessToken || currentAccessToken !== refreshToken);

    if (isRefreshRequestRequired) {
      api.dispatch(refreshAccessToken({ accessToken: refreshToken }));

      refreshTokenPromise = (async () => {
        try {
          // Swap tokens before refresh
          const result = await baseQuery({ url: '/auth/refresh', method: 'POST' }, api, extraOptions);

          const { accessToken } = (await result.data) as IAccessToken;

          api.dispatch(refreshAccessToken({ accessToken }));
          refreshTokenPromise = null;
          return true;
        } catch (err) {
          msg.error(t('profile.loggedOut'));
          api.dispatch(authLogout(_api)); // refresh access token failed!
          return false;
        }
      })();
    }

    const refreshResult = refreshTokenPromise ? await refreshTokenPromise : false;

    if (refreshResult) {
      return await baseQuery(args, api, extraOptions);
    }
  }

  // Forbidden or tokens revoked
  if (status === 403) {
    if (api.endpoint === 'viewCurrentUserProfile') {
      msg.error(t('profile.loggedOut'));
      api.dispatch(authLogout(_api));
    } else if (api.endpoint !== 'authRecoverPassword') {
      msg.error('Action not allowed');
    }
    return result;
  }

  // Global error handler
  const data = (result?.error as ErrorResponse)?.data;
  const message: string = data?.error?.replaceAll(/`/g, '').substring(0, 200) || 'Unknown server error';

  const showErrorMessageCondition =
    !extraOptions?.customErrorHandler && typeof args !== 'string' && 'method' in args && String(args.method).toUpperCase() !== 'GET';

  if ([401, 403].includes(status as number)) {
    if (status === 403) {
      msg.error(t('profile.loggedOut'));
    }
  } else if (showErrorMessageCondition) {
    showErrorNotification(message, data?.report);
  }

  console.error('❗️ API', data);
  return result;
};

// Define a service using app URL and expected endpoints
// Enhance generated endpoints with tags: providesTags & invalidatesTags
// https://redux-toolkit.js.org/rtk-query/usage/automated-refetching#tags
// More at: https://www.graphql-code-generator.com/plugins/typescript-rtk-query

export const emptyApi = (_api = createApi({
  reducerPath: '_api',
  tagTypes: API_TAG_LIST,
  refetchOnReconnect: true, // test it
  refetchOnFocus: true, // test it
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
}));

export const getTags = (type: ApiTag, dependentTypes?: ApiTag[]) => ({
  // Mutations
  invalidatesTags: () => {
    let tags: { type: ApiTag; id?: string }[] = [{ type }];
    dependentTypes?.forEach((tag) => {
      tags.push({ type: tag });
    });

    return tags;
  },
  providesTags: (result, error, id) => [{ type, id }], // for single view only
});

export const FILE_DEPENDENT_TAGS: ApiTag[] = ['WriterPass', 'WriterOrder', 'Revision', 'Ticket', 'Grading'];

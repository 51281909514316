import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { emptyApi } from './emptyApi';

import auth from '@/store/auth/authSlice';
import localPreferences from '@/store/localPreferences/localPreferenceSlice';

import breadcrumbs from '@/store/breadcrumbs/breadcrumbsSlice';
import notifications from '@/store/notifications/notificationsSlice';

// shared

// Combine reducers
export const rootReducer = combineReducers({
  auth,
  localPreferences,
  breadcrumbs,
  notifications,
  [emptyApi.reducerPath]: emptyApi.reducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'localPreferences', 'breadcrumbs'],
};

// Setup store
const setupStore = () =>
  configureStore({
    reducer: persistReducer(persistConfig, rootReducer),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
        thunk: true,
        devTools: process.env.NODE_ENV !== 'production',
      }).concat([
        emptyApi.middleware,
        createStateSyncMiddleware({
          whitelist: ['auth/setTokens', 'auth/logout', 'auth/setUser'],
        }),
      ]),
  });

export const store = setupStore();
export const persistor = persistStore(store);

initMessageListener(store);

// * Types
// * https://redux-toolkit.js.org/tutorials/typescript#define-root-state-and-dispatch-types
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
export type AppGetState = () => RootState;

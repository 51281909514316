import { Form, FormItemProps, Input } from 'antd';
import { TextAreaProps } from 'antd/es/input';
import { TextAreaRef } from 'antd/es/input/TextArea';

type Props = {
  className?: string;
  item?: FormItemProps;
  placeholder?: string;
  editorRef?: React.Ref<TextAreaRef> | undefined;
  onSave?: () => void;
} & TextAreaProps;

/**
 * [NEW] Text editor -> wrapped with Form.Item *Recommend
 * @param item override default Form.Item props. default name: "description"
 * @param forwardedRef
 * @param editorProps Antd Textarea props
 * @constructor
 */
export const InputTextEditor: FC<Props> = ({ item, editorRef, onSave, ...editorProps }) => (
  <Form.Item name="description" {...item}>
    <Input.TextArea
      onKeyDown={(e) => {
        if (!((e.ctrlKey || e.metaKey) && e.key === 's')) return;
        e.preventDefault();
        onSave?.();
      }}
      ref={editorRef}
      autoSize={{ minRows: 3, maxRows: 6 }}
      maxLength={12000}
      showCount
      {...editorProps}
    />
  </Form.Item>
);

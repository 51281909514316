import { Button, ButtonProps } from '@/components/common/button/Button';
import { InfoTooltip } from '@/components/common/InfoTooltip';
import { RTKQMutationHook } from '@/types/common/rtkq';
import { Tooltip } from 'antd';
import { upperFirst } from 'lodash';
import { useCallback } from 'react';

export interface ActionButtonProps<QueryType = object | number, ResultType = object> extends Omit<ButtonProps, 'loading' | 'onClick'> {
  name: string;
  condition?: boolean;
  hook: RTKQMutationHook<QueryType, ResultType>;
  tooltip?: boolean;
  payload: QueryType;
  onFinish?: (response: ResultType) => void;
  onError?: (error: unknown) => void;
  help?: string; // use only for simple condition! if "help" provided -> component should render always with ButtonPlaceholder & tooltip
}

/**
 * Mutation action button -> most useful button!
 *
 * Example of use:
 *    <MutationTriggerButton<QueryType, ResultType> ...props />
 *
 * It is recommended to declare in hooks:
 * export const useRevisionActions = (idStatus: RevisionStatuses): UseRevisionActionsReturnType => {
 *   // Conditions
 *   const canDoMagic = ...;
 *
 *   // Actions
 *   return {
 *     actionName: {
 *       name: 'actionName',
 *       hook: useActionNameMutation,
 *       condition: canDoMagic,
 *       icon: ...,
 *       ...& other button props
 *     },
 *   };
 * };
 *
 *
 * @param name - Simple key, also used as Tooltip text
 * @param condition - Render condition (can be undefined)
 * @param hook - Provide api useMutationHook
 * @param payload - Request payload data
 * @param onFinish - Request completed successfully
 * @param onError - Request failed
 * @param help - should be rendered with help tooltip.
 * @param buttonProps - extra button props
 * @constructor
 */
export const MutationTriggerButton = <QueryType, ResultType>({
  name,
  condition,
  hook,
  payload,
  onFinish,
  tooltip = true,
  onError,
  help,
  ...buttonProps
}: ActionButtonProps<QueryType, ResultType>) => {
  const [trigger, { isLoading }] = hook(); // get trigger fn & isLoading flag

  // Placeholders & help tooltip
  // use FeatureGuard for complex conditions
  const defaultLabel = !buttonProps.children && !buttonProps.icon && name; // * can be overwritten with buttonProps.children
  const ButtonPlaceholder = useCallback(
    () => (
      <span>
        <Button size="small" children={defaultLabel} {...buttonProps} disabled />
        <InfoTooltip text={help} />
      </span>
    ),
    [defaultLabel, help, buttonProps]
  );

  // Only if "condition" prop provided explicitly &
  // skip when "undefined" ->
  // coz: some actions need force render always condition={true}
  if (condition !== undefined && !condition) return help ? <ButtonPlaceholder /> : null; // doesn't meet condition -> render nothing

  const btn = (
    <Button
      size="small"
      children={defaultLabel}
      {...buttonProps} // override all props exclude onClick & loading
      loading={isLoading}
      onClick={async (event) => {
        event.stopPropagation(); // cover some cases
        try {
          const response = await trigger(payload).unwrap(); // execute request
          onFinish?.(response); // success
        } catch (err) {
          // used global error handler -> check emptyApi.ts
          onError?.(err); // failed
        }
      }}
    />
  );

  return tooltip ? <Tooltip title={upperFirst(name)}>{btn}</Tooltip> : btn;
};

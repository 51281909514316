import { Button } from '@/components/common/button/Button';

// Styles
import s from '@feature/notification/NotificationButton.module.less';

export const NotificationButton = () => (
  <div className={s.wrapper}>
    <Button icon="fa-bell" type="text" circle />
  </div>
);

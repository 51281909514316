import { TabsProps } from 'antd';
import { Dispatch, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

type UseTabsReturnType = {
  activeTab: string;
  setActiveTab: Dispatch<string>;
  smartTabs: Pick<TabsProps, 'defaultActiveKey' | 'onChange' | 'activeKey' | 'size'>;
};

/**
 *
 * @param defaultActiveKey is required param!
 * @param queryKey
 * @return {
 *   state: activeTab & setActiveTab
 *   spread tabs props: <Tabs {...tabsProps}>
 * }
 */
type UseSmartTabs = (defaultActiveKey: string, queryKey?: string, props?: TabsProps) => UseTabsReturnType;
export const useSmartTabs: UseSmartTabs = (defaultActiveKey, queryKey = 'tab', props = {}) => {
  const { onChange, ...tabProps } = props;

  // const navigate = useNavigate(); -> @deprecated
  const { search } = window.location;
  const url = new URLSearchParams(search);
  const currentTab = url.get(queryKey);
  // const currentTab = useQueryString(queryKey); -> @deprecated

  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState<string>(currentTab || defaultActiveKey);

  const _onChange = (tabKey: string) => {
    url.set(queryKey, tabKey);
    // navigate(`${pathname}?${url.toString()}`); -> @deprecated
    window.history.replaceState({}, pathname, `${pathname}?${url.toString()}`); // instead default history.pushState
    setActiveTab(tabKey);
    onChange?.(tabKey);
  };

  useEffect(() => {
    const url = new URLSearchParams(search);
    const currentTab = url.get(queryKey);

    setActiveTab(currentTab || defaultActiveKey);
  }, [queryKey, search, defaultActiveKey]);

  return {
    activeTab,
    setActiveTab,
    smartTabs: {
      defaultActiveKey,
      onChange: _onChange,
      activeKey: activeTab,
      size: 'large',
      ...tabProps,
    },
  };
};

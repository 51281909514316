import { USER_DATA_KEY } from '@/config/constants';
import { useAppSelector } from '@/hooks/redux';
import { WSMessageEventDetail, TWSMessageHandler, WSMessage } from '@/shared/features/websockets/types/websockets';
import { selectUserId } from '@/store/auth/authSlice';
import { debounce, uniq } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CommonMessage, CommonMessageStatuses, MessengerViewContext } from '../types/messages';
import { isEmojisOnly } from './emojis';
import { getMessageSender } from './messengerHelpers';
import { upsertIncomingMessage } from './messengerService';
import { ws } from '@/helpers/ws';

export const useMessageConditions = (message: CommonMessage) => {
  const idCurrentUser = useAppSelector(selectUserId);

  // Author (sender)
  const sender = getMessageSender(message);
  const author = message[sender];
  const isSender = author?.id === idCurrentUser && sender === USER_DATA_KEY;

  const isEmoji = isEmojisOnly(message.text); // detect emojis

  // Render condition
  const withoutChildren = !message.threadMessages?.length;

  const canRetract =
    isSender && withoutChildren && ![CommonMessageStatuses.Removed, CommonMessageStatuses.Retracted].includes(message.idStatus);

  const canRemove = message.idStatus === CommonMessageStatuses.Posted;

  const canReply = message.idStatus === CommonMessageStatuses.Posted;

  const canEdit = [CommonMessageStatuses.Posted, CommonMessageStatuses.Unchecked].includes(message.idStatus); // specify restrictions according to user roles if any;

  return { isEmoji, canRetract, canRemove, canReply, isSender, canEdit };
};

// TODO: Lock & Unlock autoscroll
export const useChat = (activeMessages: CommonMessage[]) => {
  const chatRef = useRef<HTMLDivElement>(null);

  // Scroll
  const chatEl = chatRef.current;

  const scrollChat = useCallback(
    (force?: boolean, scrollTopPosition?: number) => {
      if (!chatEl) return; // chat not initialized yet

      // Scroll to 1st unread/unchecked message
      const firstUnreadMessageEl = document.querySelector<HTMLDivElement>('div.unread');

      if (firstUnreadMessageEl) {
        console.log('[scroll] unread divider found');
        chatEl.scrollTo({ top: firstUnreadMessageEl.offsetTop - 40, behavior: 'auto' });
        // firstUnreadMessageEl.scrollIntoView({ behavior: 'smooth' });
        return; // skip default scroll
      }

      console.log('SCROLLING TO....', scrollTopPosition || chatEl.scrollHeight);

      // Default scroll to bottom
      chatEl.scrollTo({ top: scrollTopPosition || chatEl.scrollHeight, behavior: force ? 'auto' : 'smooth' });
      console.log('[scroll] default behaviour');
    },
    [chatEl],
  );
  const scrollToBottom = useCallback(() => scrollChat(true), [scrollChat]);

  // Counter
  const prevMsgCountRef = useRef(0);
  const messagesCount = activeMessages.length;

  // Scroll to last message (need testing & review)
  useEffect(() => {
    if (!activeMessages?.length) return; // not initialized
    if (prevMsgCountRef.current && prevMsgCountRef.current < messagesCount) scrollChat(true); // scroll chat with conditions
    prevMsgCountRef.current = messagesCount; // update counter
  }, [activeMessages?.length, messagesCount, scrollChat]);

  return { scrollChat, scrollToBottom, messagesCount, chatRef };
};

/**
 * WebSockets
 * Main handler: handleWebsocketIncomingMessages
 * API:
 *  connect() -> connecting to wsg & register onMessage eventListener -> handleWebsocketIncomingMessages
 *  readyState: WSReadyState
 * UE:
 *  Connect on mount
 *  Disconnect on unmount
 *
 */
type UseMessengerWebSocketsOptions = {
  id: number;
  view: MessengerViewContext;
  path: string;
  onWsMessage?: TWSMessageHandler;
};
export const useMessengerWebSockets = ({ id, path, onWsMessage }: UseMessengerWebSocketsOptions) => {
  const idRef = useRef(id);
  const pathRef = useRef(path);
  const onWsMessageRef = useRef(onWsMessage);

  idRef.current = id;
  pathRef.current = path;

  onWsMessageRef.current = onWsMessage;

  useEffect(() => {
    const onMessageHandler = (event: CustomEvent<WSMessageEventDetail>) => {
      const { path: connectionPath, data } = event.detail;
      if (connectionPath !== pathRef.current) return;

      try {
        const eventData = JSON.parse(data.data) as WSMessage<any>;
        console.log(`[ws] ${eventData.event.name}`);
        onWsMessageRef.current?.(eventData); // extra handler for other events from current wss channel
      } catch (e) {}
      return upsertIncomingMessage(idRef.current, data); // main handler (messenger)
    };

    document.addEventListener('wsMessageReceived', onMessageHandler as EventListener);

    return () => {
      document.removeEventListener('wsMessageReceived', onMessageHandler as EventListener);
    };
  }, []);

  const reconnect = () => {
    ws.addConnection(path, true);
  };

  return { readyState: ws.getConnection(path)?.readyState, reconnect };
};

export const useMessengerHoverActionsAvailability = () => {
  const [hoverActionsAvailable, setHoverActionsAvailable] = useState(true);

  useEffect(() => {
    const checkHoverActionsAvailability = debounce(() => {
      setHoverActionsAvailable(window.matchMedia('(hover: hover) and (min-width: 577px)').matches);
    }, 200);
    checkHoverActionsAvailability();

    window.addEventListener('resize', checkHoverActionsAvailability);

    return () => window.removeEventListener('resize', checkHoverActionsAvailability);
  }, []);

  return hoverActionsAvailable;
};

/**
 * IntersectionObserver for MessageItem
 * @param onReadMessages
 * @example
 * // MessageItem.tsx
 *    useEffect(() => {
 *     if (!observer) return; // no observer from context
 *
 *     const el = messageRef.current;
 *     const shouldObserve = el && !isSender && !message.isRead; // observe only unread messages from others
 *     if (!shouldObserve) return;
 *
 *     observer.observe(el); // observe message
 *
 *     return () => observer.unobserve(el); // cleanup observer
 *   }, [observer, message.id, isSender, message.isRead]);
 */
export const useMessengerObserver = (onReadMessages?: (ids: number[]) => Promise<void>) => {
  // Update array when message is viewed (IntersectionObserver)
  const idsRef = useRef<number[]>([]);

  // IntersectionObserver for MessageItem (memoized)
  const observer = useMemo(() => {
    // Step 1. Observer handler -> Collect ids of read messages
    const handleReadMessages = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (entry.target instanceof HTMLElement) {
          const wrapper = entry.target.closest('#chat');

          if (!wrapper) return;

          const wrapperHeight = wrapper.clientHeight;
          const itemHeight = entry.target.clientHeight;

          if (itemHeight >= wrapperHeight) {
            const scrollFn = () => {
              const scrolledUnder =
                wrapper.getBoundingClientRect().top - entry.target.getBoundingClientRect().top + 100 >= wrapper.clientHeight;

              if (!scrolledUnder) return;

              // @ts-ignore
              const idStr = entry.target.dataset.id; // MessageItem -> data-id

              // Add id to list of viewed messages
              idStr && idsRef.current.push(+idStr);

              console.log('✲ read', idStr);
              observer.unobserve(entry.target);
              triggerReadMessages();
              wrapper.removeEventListener('scroll', scrollFn);
            };
            wrapper.addEventListener('scroll', scrollFn);
          } else if (entry.isIntersecting) {
            const idStr = entry.target.dataset.id; // MessageItem -> data-id

            // Add id to list of viewed messages
            idStr && idsRef.current.push(+idStr);

            console.log('✲ read', idStr);
            observer.unobserve(entry.target);
            triggerReadMessages();
          }
        }
      });
    };

    // Debounced trigger
    const triggerReadMessages = debounce(async () => {
      if (!idsRef.current.length) return;

      // Step 2. Mark viewed messages as read -> by ids (API) -> get cb() from Messenger config
      await onReadMessages?.(uniq(idsRef.current)); // execute callback

      idsRef.current = []; // reset ids list
    }, 1500);

    // Return observer instance
    return new IntersectionObserver(handleReadMessages, { root: null, rootMargin: '0px', threshold: 0.8 });
  }, [onReadMessages]);

  return { observer, viewedMessagesIdsRef: idsRef };
};

/**
 * CORE API Messenger v1.0
 *
 * NOTE: Websocket connection required AccessToken & updateCachedData (RTK API method)
 *
 * [Handle incoming stream]
 * That's why Websocket connection initialized at api/entity -> onCacheEntryAdded
 *
 * Add message instead of invalidatesTags -> used onQueryStarted & updateMessagesRecipe handler
 *
 */
import { Widget } from '@/shared/components/Widget';
import { useReadTicketMessageMutation } from '@/shared/features/messenger/api/messenger';
import { isSupportSystem } from '@/shared/features/messenger/lib/messengerHelpers';
import { playPop } from '@/shared/features/player/player';
import { WSG_POLLING_INTERVAL_SECONDS } from '@/shared/features/websockets/config/config';
import { TWSMessageHandler, WSReadyState } from '@/shared/features/websockets/types/websockets';
import { useSmartPolling } from '@/shared/hooks/useSmartPolling';
import { useSmartTabs } from '@/shared/hooks/useSmartTabs';
import { ICustomer, IWriter } from '@/shared/types/participants';
import { RTKQMutationHook, RTKQueryHook } from '@/shared/types/rtkq';
import { Badge, InputRef, Spin, Tooltip } from 'antd';
import { takeRight } from 'lodash';
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MessengerContext } from '../lib/messengerContext';
import { useChat, useMessengerHoverActionsAvailability, useMessengerObserver, useMessengerWebSockets } from '../lib/messengerHooks';
import { messagesAdapter } from '../lib/messengerService';
import { getMessageInputId, ParsedInputValue } from '../lib/serializeInput';
import {
  CommonMessage,
  CommonMessageContextCreateRequest,
  CommonTicketMessageUpdateRequest,
  MessengerChannels,
  MessengerConfig,
  MessengerViewContext,
  MsgElement,
} from '../types/messages';
import { MessengerFooter } from './layout/MessengerFooter';
import { MessengerSpacer } from './layout/MessengerSpacer';
import { MessageForm } from './MessageForm';
import { MessagesList } from './MessagesList';
import s from './Messenger.module.less';
import { MessengerChannelsTabs } from './MessengerChannelsTabs';

export interface MessengerProps<Channels = MessengerChannels> {
  id: number;
  view: MessengerViewContext;
  layout?: 'wide' | 'narrow' | 'pinned';
  isInteractive: boolean | undefined;
  // if provided unread count minimum limit can be increased
  unreadCount?: number;
  config: MessengerConfig<Channels>;
  hooks: {
    fetchMessages: RTKQueryHook<number, any>;
    addMessage: RTKQMutationHook<CommonMessageContextCreateRequest, CommonMessage>;
    editMessage?: RTKQMutationHook<CommonTicketMessageUpdateRequest, CommonMessage>;
  };
  onWsMessage?: TWSMessageHandler;
  renderMessage?: {
    actions?: MsgElement;
    text?: MsgElement;
    extra?: MsgElement;
    status?: MsgElement;
  };
  // Render custom JSX from messenger config
  renderList?: (messages: CommonMessage[]) => ReactNode;
  participants?: {
    writer?: IWriter | null;
    customer?: ICustomer | null;
  };
  editingEnabled?: boolean;
  interactiveChannels?: {
    [key in Channels as string]: boolean;
  };
  visibleChannels?: {
    [key in Channels as string]: boolean;
  };
  inactiveMessage?: (channel: string) => string;
  hidden?: boolean; // required for PinnedTicketMessenger (if hidden -> onlyConnect & listen WS)
}

/**
 * Messenger main component
 * Check message actions from: DiscardMessage
 */
export const Messenger = <Channels,>(props: MessengerProps<Channels>) => {
  const {
    id,
    view,
    hooks,
    isInteractive,
    config,
    onWsMessage,
    layout = 'wide',
    editingEnabled,
    interactiveChannels,
    visibleChannels,
    hidden = false,
    unreadCount = 0,
  } = props;

  const { icons, colors } = config;

  // Websockets (reconnect & readyState)
  const wss = useMessengerWebSockets({ id, view, path: config.websockets[String(view)], onWsMessage }); // <- check main (common) handler for incoming ws messages inside

  // pooling interval (offline mode) -> disabled when WSG is available or current tab is inactive
  const { pollingInterval } = useSmartPolling(WSG_POLLING_INTERVAL_SECONDS, wss.readyState !== WSReadyState.Open);

  const { data, isLoading, refetch } = hooks.fetchMessages(id, { pollingInterval }); // make query

  // Observe message visibility -> mark as read
  const [markMessagesAsRead] = useReadTicketMessageMutation();
  const handleReadMessages = useCallback(
    async (ids: number[]) => {
      if (isSupportSystem()) return; // skip for support system
      await markMessagesAsRead({ ids, idTicket: id });
    },
    [markMessagesAsRead, id],
  );
  const { observer } = useMessengerObserver(handleReadMessages);

  // Transform EntityState -> to array
  const messages = useMemo(() => (data ? messagesAdapter.getSelectors().selectAll(data) : []), [data]);

  // Editor
  const editorRef = useRef<InputRef>(null); // focus editor
  const focusEditor = useCallback(() => editorRef.current?.focus({ cursor: 'end' }), [editorRef]); // focus editor

  // Thread mode
  const [parentMessage, _setParentMessage] = useState<CommonMessage>(); // used as context

  const [editedMessage, setEditedMessage] = useState<CommonMessage>();

  const [mobileClickedMessage, setMobileClickedMessage] = useState<CommonMessage>();

  const hoverActionsAvailable = useMessengerHoverActionsAvailability();

  // Handle _setParentMessage (thread mode)
  const setParentMessage = useCallback(
    (msg?: CommonMessage) => {
      _setParentMessage(msg);
      focusEditor();
    },
    [focusEditor],
  );

  // Filter messages -> render only thread if "parentMessage" mode is active
  const [limit, setLimit] = useState(Math.max(unreadCount, 100));

  // Use messages (scrolling navigation)
  const { messagesCount, chatRef, scrollChat } = useChat(parentMessage?.threadMessages || messages);

  /*
   * Define default active tab
   * (both visible and interactive regardless of general ticket interactivity)
   */
  const hasWriterActiveTab = visibleChannels?.writer && interactiveChannels?.writer;
  const hasCustomerActiveTab = visibleChannels?.customer && interactiveChannels?.customer;
  const hasPublicActiveTab = visibleChannels?.public && interactiveChannels?.public;

  const defaultActiveChannel = hasPublicActiveTab
    ? 'public'
    : hasCustomerActiveTab
    ? 'customer'
    : hasWriterActiveTab
    ? 'writer'
    : 'private';

  const { smartTabs, activeTab, setActiveTab } = useSmartTabs(defaultActiveChannel, 'c');

  const activeChannel = activeTab as MessengerChannels; // coz -> we already have fix for bad query string

  const direction = config.channels[activeChannel]?.direction;

  // E: Scroll to bottom on change active channel
  useEffect(() => {
    if (isLoading) return;

    setTimeout(() => scrollChat(true), 0); // case: bug fix -> finish scroll (to 100%) position (smooth)
  }, [isLoading, id, activeChannel, scrollChat]);

  // E: Focus editor on mount
  useEffect(() => {
    if (isLoading) return;
    setTimeout(focusEditor, 350);
  }, [isLoading, id, activeChannel, focusEditor]);

  // E: Fix bad query string
  useEffect(() => {
    if (Object.keys(config.channels).includes(activeChannel)) return;
    setActiveTab(defaultActiveChannel);
  }, [activeChannel, config.channels, defaultActiveChannel, setActiveTab]);

  useEffect(() => {
    if (!id) return;
    const storedValue = localStorage.getItem(getMessageInputId(id, activeChannel));

    if (!storedValue) return;
    try {
      const result: ParsedInputValue = JSON.parse(storedValue);
      if (!result.idMessage) return;

      const targetMessage = messages.find(({ id }) => id === result.idMessage);
      if (!targetMessage) return;

      if (result.action === 'edit') {
        setEditedMessage(targetMessage);
      }

      if (result.action === 'reply') {
        _setParentMessage(targetMessage);
      }
    } catch (err) {}
  }, [id, activeChannel, messages]);

  useEffect(() => {
    console.log('[messenger] 5 Sep, 2023');
  }, []);

  if (hidden) return null;

  return (
    <Widget className={s.wrapper}>
      <MessengerContext.Provider
        value={{
          refetch,
          parentMessage,
          setParentMessage,
          editedMessage,
          setEditedMessage,
          editorRef,
          activeChannel,
          limit,
          setLimit,
          layout,
          editingEnabled,
          hoverActionsAvailable,
          mobileClickedMessage,
          setMobileClickedMessage,
          observer, // observe message visibility -> mark as read
          ...props,
        }}
      >
        <Spin spinning={isLoading}>
          <div id="chat" ref={chatRef} className={s.chat}>
            {view === 'ticket' ? (
              <MessengerChannelsTabs
                className={s.channels}
                limit={limit}
                messages={messages}
                onTabClick={() => setParentMessage()} // cancel reply
                {...smartTabs}
              />
            ) : (
              // Messages without idVisibility
              <MessagesList total={messages.length} messages={takeRight(messages, limit)} />
            )}
            <MessengerSpacer />
          </div>
        </Spin>

        {!isLoading && (
          <MessageForm
            channel={activeChannel}
            isInteractive={!!isInteractive}
            currentTabInteractive={!!interactiveChannels?.[activeChannel]}
            view={view}
            onSendMessage={playPop}
            direction={direction}
          >
            <div className={s.status}>
              <Tooltip title={wss.readyState === WSReadyState.Open ? 'You are online' : 'Offline. Click to reconnect'}>
                {wss.readyState === WSReadyState.Open ? (
                  <Badge status="processing" />
                ) : (
                  <span className="clickable" onClick={wss.reconnect}>
                    <Badge status="error" />
                  </span>
                )}
              </Tooltip>

              <span style={{ color: colors.muted }} className="clickable" onClick={wss.reconnect}>
                {wss.readyState === WSReadyState.Closed ? icons.disconnected : direction}
              </span>
            </div>
            <MessengerFooter count={messagesCount} threadMode={!!parentMessage} onClick={scrollChat} />
          </MessageForm>
        )}
      </MessengerContext.Provider>
    </Widget>
  );
};

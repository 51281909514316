import { SERVER_TIME_FORMAT } from '@/config/constants';
import moment, { Moment } from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useTimezone } from '@/hooks/timezone';

type UseClockReturnType = {
  dateTime: Moment;
  formattedTime: string;
  timezone: string;
};

type UseClockOptions = { updateInterval?: number };

export const useClock = (options?: UseClockOptions): UseClockReturnType => {
  const timezone = useTimezone();

  const [dateTime, setDateTime] = useState<Moment>(timezone ? moment().tz(timezone) : moment());

  const formattedTime: string = dateTime.format(SERVER_TIME_FORMAT);

  // Clock
  useEffect(() => {
    const intervalId = setInterval(() => setDateTime(timezone ? moment().tz(timezone) : moment()), options?.updateInterval || 1000);
    return () => clearInterval(intervalId);
  }, [options, timezone]);

  useEffect(() => {
    setDateTime(timezone ? moment().tz(timezone) : moment());
  }, [timezone]);

  return {
    dateTime,
    timezone,
    formattedTime, // 'HH:mm:ss'
  };
};

import { LinkProps, useLocation } from 'react-router-dom';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';

import cn from 'classnames';

interface OwnProps extends LinkProps {
  className?: string;
  activeClassName?: string;
  to: string;
}

export const CustomLink = ({ className, activeClassName = '', children, to, ...props }: OwnProps) => {
  let resolved = useResolvedPath(to);
  let match = !!useMatch({ path: resolved.pathname + '/*', end: true });

  const { pathname, search } = useLocation();

  if (/^\/claimed-orders/gm.test(pathname) && /^\/available-orders/gm.test(to)) {
    match = true;
  }

  if (/^\/inactive-orders/gm.test(pathname) && /^\/my-orders/gm.test(to)) {
    match = true;
  }
  if (/^\/inactive-drawings/gm.test(pathname) && /^\/order-drawings/gm.test(to)) {
    match = true;
  }

  if (/^\/inactive-offers/gm.test(pathname) && /^\/order-offers/gm.test(to)) {
    match = true;
  }
  // Cases for payments
  if (resolved.pathname === '/') {
    match = pathname + search === to;
  }

  return (
    <Link className={cn(className, { [activeClassName]: match })} to={to} {...props}>
      {children}
    </Link>
  );
};

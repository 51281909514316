import { CardsDataBoundary } from '@/components/common/cards/CardsDataBoundary';
import { GridCardsProps } from '@/components/common/layout/GridCards';

import { OrderCard, OrderCardProps } from '../order/OrderCard';

export type OrderCardsProps = {
  items?: OrderCardProps[];
} & GridCardsProps;

export const OrderCards: FC<OrderCardsProps> = (props) => (
  <CardsDataBoundary {...props}>
    {props.items
      ?.filter(({ snapshot }) => !!snapshot)
      ?.map((item) => (
        <OrderCard key={item.idWriterOrder || item.idOrder} {...item} />
      ))}
  </CardsDataBoundary>
);

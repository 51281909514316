import { WriterOrderStatuses } from '@/types/writerOrder';
import { ACTIVE_WRITER_ORDER_STATUSES, INACTIVE_WRITER_ORDER_STATUSES } from '@/hooks/orders/constants';

export const getOrderFeature = (idStatus: WriterOrderStatuses) => {
  return ACTIVE_WRITER_ORDER_STATUSES.includes(idStatus)
    ? 'ActiveWriterOrder'
    : INACTIVE_WRITER_ORDER_STATUSES.includes(idStatus)
    ? 'InactiveWriterOrder'
    : 'ClaimedWriterOrder';
};

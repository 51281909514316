/**
 * Deadline remaining countdown component
 * Using for Paid order.
 * Check also: DeadlineShiftClock
 * Check docs: https://github.com/jsmreese/moment-duration-format
 */
import cn from 'classnames';
import momentDurationFormatSetup from 'moment-duration-format';
import moment from 'moment-timezone';
import { useCallback, useEffect, useState } from 'react';

// Styles
import s from './DeadlineRemainingCountDown.module.less';

interface Props {
  dueDate: string | undefined;
}

/*
 * Setup plugin (monkey patch moment instance)
 * https://github.com/jsmreese/moment-duration-format#usage
 */
// @ts-ignore
momentDurationFormatSetup(moment);

export const DeadlineRemainingCountDown: FC<Props> = ({ dueDate }) => {
  const getDiffSeconds = useCallback(() => {
    const now = moment.utc();
    return moment.utc(dueDate).diff(now, 'seconds');
  }, [dueDate]);

  const [remainingSeconds, setRemainingSeconds] = useState(getDiffSeconds());

  const isExpired = getDiffSeconds() <= 0;
  const format = isExpired ? '[Exp] y[y] w[w] d[d] h[h] m[m] ago' : 'w[w] d[d] h[h] m[m]';

  useEffect(() => {
    if (isExpired) return; // expired date without countdown

    // Start countdown
    const timerId = setInterval(() => {
      setRemainingSeconds(getDiffSeconds());
    }, 1000);
    return () => clearTimeout(timerId);
  }, [isExpired, remainingSeconds, getDiffSeconds, dueDate]);

  useEffect(() => {
    setRemainingSeconds(getDiffSeconds());
  }, [dueDate, getDiffSeconds]);

  if (!dueDate) return null;

  return (
    <span className={cn(s.wrapper, { [s.expired]: isExpired })}>
      {moment
        .duration(remainingSeconds, 'seconds')
        // @ts-ignore Important! coz: plugin "moment-duration-format" -> https://github.com/jsmreese/moment-duration-format#roadmap
        .format(format)}
    </span>
  );
};

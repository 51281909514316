import { Dot } from '@/shared/components/icons/Dot';
import { useMessengerContext } from '@/shared/features/messenger/lib/messengerContext';
import { CommonMessage } from '@/shared/features/messenger/types/messages';
import { MessageAuthor } from '@/shared/features/messenger/ui/MessageAuthor';
import { Space } from 'antd';

// Styles
import s from './MessageItem.module.less'; // styles from message item

type MessageHeaderProps = {
  message: CommonMessage;
  showTime?: boolean;
};

// @member of MessageItem
export const MessageHeader = ({ message, showTime }: MessageHeaderProps) => {
  const { config } = useMessengerContext();

  return (
    <header className={s.header}>
      <Space split={<Dot />}>
        <span className={s.name}>
          <MessageAuthor message={message} />
        </span>
        {showTime && config?.renderMessage.date(message)}
      </Space>
      {message.edited && <>Edited</>}
    </header>
  );
};

import { useViewCurrentUserProfileQuery } from '@/api/profile';
import { WriterStatuses } from '@/types/profile';
import { lazy } from 'react';
import { lazyRetry } from '@/components/common/errors/lazyRetry';
import { LazyPageHOC } from '@/components/common/LazyPageHOC';
import { FEATURE } from '@/config/features';

const ProfilePage = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "ProfilePage" */ '@feature/profile/ProfilePage'), 'ProfilePage'),
);

const HrFlowPage = lazy(() => lazyRetry(() => import(/* webpackChunkName: "HrFlowMainPage" */ '@feature/hrFlow/HrFlowPage'), 'HrFlowPage'));

export const AppIndexPage = () => {
  const { data: profile } = useViewCurrentUserProfileQuery();

  const isNew = profile?.idStatus === WriterStatuses.New;

  return isNew ? (
    <LazyPageHOC title="hiring.welcome" path="/" element={HrFlowPage} />
  ) : (
    <LazyPageHOC title={FEATURE.Profile.title} icon={FEATURE.Profile.icon} path={FEATURE.Profile.path} element={ProfilePage} />
  );
};

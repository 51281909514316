import { ApiTag } from '@/config/features';
import { emptyApi } from '@/store/emptyApi';
import { useAppDispatch } from './redux';

export type InvalidateTagsParams = {
  type: ApiTag;
  id?: number | string;
  container?: string;
}[];

// TODO: Sync between projects
export const useInvalidatesTags = (items: InvalidateTagsParams) => {
  const dispatch = useAppDispatch();

  const tags = items.map((item) => {
    const { id, type, container } = item;

    const obj: {
      type: ApiTag;
      id?: string;
    } = { type };

    if (container || id) {
      obj.id = (container || '') + (id || '');
    }
    return obj;
  });

  return () => dispatch(emptyApi.util.invalidateTags(tags));
};

import { BroadcastMessageSearchItem } from '@/types/broadcastMessage';
import { Card } from 'antd';
import { t } from '@/services/translator';
import s from './BroadcastMessageCard.module.less';
import { BroadcastMessageHistoricTag } from '@feature/broadcastMessage/BroadcastMessageHistoricTag';
import { BroadcastMessageCategoryName } from '@feature/broadcastMessage/BroadcastMessageCategoryName';
import { DateTime } from '@/components/common/DateTime';
import { Button } from '@/components/common/button/Button';
import { useBroadcastMessageContext } from '@feature/broadcastMessage/BroadcastMessageContext';
import { RichText } from '@/shared/components/RichText';

type BroadcastMessageCardProps = {
  broadcastMessage: BroadcastMessageSearchItem;
};

export const BroadcastMessageCard = ({ broadcastMessage }: BroadcastMessageCardProps) => {
  const { setBroadcastMessage } = useBroadcastMessageContext();

  const handleButtonClick = () => {
    setBroadcastMessage?.(broadcastMessage);
  };

  return (
    <Card
      title={
        <div className={s.header}>
          <div className={s.headerRow}>
            <div className={s.title}>{broadcastMessage.writerName}</div>
            {broadcastMessage.isHistoric && <BroadcastMessageHistoricTag />}
          </div>
          <div className={s.headerRow}>
            <BroadcastMessageCategoryName id={broadcastMessage.idCategory} tag />
            <DateTime className={s.date} value={broadcastMessage.dateAdded} icon />
          </div>
        </div>
      }
      className={s.card}
    >
      <div className={s.description}>
        <RichText text={broadcastMessage.writerDescription || t('misc.noDescription')} />
      </div>
      <div className={s.btnWrap}>
        <Button type="primary" onClick={handleButtonClick}>
          {t('misc.read')}
        </Button>
      </div>
    </Card>
  );
};

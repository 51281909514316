import { AppDispatch, AppGetState } from '@/store/store';
import { setLocalPreferences, setSearchPreferences } from './localPreferenceSlice';
import {
  AddSearchPreferenceActionParams,
  getSearchPreferencesWithNewKey,
  getStateWithUpdatedSearchPreferences,
} from '@/shared/components/searchEngine';
import { views, viewConfigs } from '@/components/search/config';
import { LocalPreferences } from '@/types/search';

type UpdatePreferencesParams = {
  preferences: LocalPreferences;
  views: readonly string[];
};

export const updatePreferences = (params: UpdatePreferencesParams) => (dispatch: AppDispatch) => {
  const updatedState = getStateWithUpdatedSearchPreferences(params);

  dispatch(setLocalPreferences(updatedState));
};

export const addSearchPreference =
  ({ key, value }: AddSearchPreferenceActionParams) =>
  (dispatch: AppDispatch, getState: AppGetState) => {
    const { localPreferences } = getState();

    const updatedState = getSearchPreferencesWithNewKey({
      key,
      value,
      views,
      viewConfigs,
      preferences: localPreferences.search || {},
    });

    dispatch(setSearchPreferences(updatedState));
  };

import { createSlice } from '@reduxjs/toolkit';

export type BreadcrumbType = {
  from: string;
  to: string;
  title: string;
};

type BreadcrumbsStateType = {
  items: BreadcrumbType[];
};

const initialState: BreadcrumbsStateType = {
  items: [],
};

export const breadcrumbs = createSlice({
  name: 'breadcrumbs',
  initialState,
  reducers: {
    setBreadcrumbs(
      state,
      {
        payload,
      }: {
        payload: BreadcrumbsStateType;
      },
    ) {
      return { items: payload.items };
    },
  },
});

export const { setBreadcrumbs } = breadcrumbs.actions;

export default breadcrumbs.reducer;

export const breadcrumbsSelector = ({ breadcrumbs }: { breadcrumbs: BreadcrumbsStateType }): BreadcrumbType[] => breadcrumbs.items;

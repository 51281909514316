// Styles
import cn from 'classnames';
import { CSSProperties, } from 'react';
import s from './Page.module.less';

interface WrapperProps {
  className?: string;
  flow?: 'column' | 'row';
  gap?: number;
  wrap?: boolean;
  width?: number;
  style?: CSSProperties;
}

/**
 * Flex (use for content with sidebar)
 * @param children
 * @param gap 15px as default
 * @param flow row as default
 * @param wrap nowrap as default
 * @param width (also set minWidth too)
 * @param style (extra props)
 */
export const Flex: FC<WrapperProps> = ({ gap = 15, flow = 'row', wrap, width, style, ...rest }) => (
  <div
    style={{
      display: 'flex',
      flexFlow: `${flow} ${wrap ? 'wrap' : 'nowrap'}`,
      gap,
      width,
      minWidth: width,
      ...style,
    }}
    {...rest}
  />
);

// Main wrapper for all pages
interface PageProps extends WrapperProps {
  layout?: 'default' | 'other';
}

/**
 * Simple page layout -> idea: same wrapper for all pages (optional)
 * @param props
 */
export const Page: FC<PageProps> = (props) => <div className={s.page} {...props} />;

// Greedy main content (primary) -> flex:1
interface MainProps extends WrapperProps {}

/**
 * Greedy main content
 * (flex: 1 \ flow: column \ gap: 15px \ minWidth: 400px)
 * @param className
 * @param props
 * @constructor
 */
export const Main: FC<MainProps> = ({ className, ...props }) => <Flex className={cn(s.main, className)} {...props} />;

import { DateTime } from '@/components/common/DateTime';
import { Table, useMemoizedColumns } from '@/components/common/Table';
import { t } from '@/services/translator';
import { TableProps } from 'antd';
import { BroadcastMessageSearchItem } from '@/types/broadcastMessage';
import { BroadcastMessageHistoricTag } from '@feature/broadcastMessage/BroadcastMessageHistoricTag';
import s from '@feature/broadcastMessage/BroadcastMessagesTable.module.less';
import { BroadcastMessageCategoryName } from '@feature/broadcastMessage/BroadcastMessageCategoryName';
import { Button } from '@/components/common/button/Button';
import { useBroadcastMessageContext } from '@feature/broadcastMessage/BroadcastMessageContext';
import { RichText } from '@/shared/components/RichText';

type RevisionsTableProps = TableProps<BroadcastMessageSearchItem> & {
  items?: BroadcastMessageSearchItem[];
  isHistoric: boolean;
};

export const BroadcastMessagesTable = ({ items = [], isHistoric, loading, ...props }: RevisionsTableProps) => {
  const { setBroadcastMessage } = useBroadcastMessageContext();

  const columns = useMemoizedColumns<BroadcastMessageSearchItem>([
    {
      title: '',
      width: 30,
      hidden: !isHistoric,
      render: (_) => <BroadcastMessageHistoricTag />,
    },
    {
      title: t('misc.message'),
      width: 340,
      render: (_, { writerName, writerDescription }) => (
        <div>
          <div className={s.title}>{writerName}</div>
          <div className={s.description}>
            <RichText text={writerDescription || t('misc.noDescription')} />
          </div>
        </div>
      ),
    },
    {
      title: t('misc.category'),
      ellipsis: true,
      render: (_, { idCategory }) => <BroadcastMessageCategoryName id={idCategory} tag />,
    },
    {
      title: t('misc.dateAdded'),
      ellipsis: true,
      render: (_, { dateAdded }) => <DateTime value={dateAdded} />,
    },
    {
      title: '',
      width: 60,
      ellipsis: true,
      render: (_, message) => (
        <Button type="link" className={s.button} onClick={() => setBroadcastMessage?.(message)}>
          {t('misc.viewDetails')}
        </Button>
      ),
    },
  ]);

  return <Table className={s.table} dataSource={items} columns={columns} loading={loading} {...props} />;
};

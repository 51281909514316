import cn from 'classnames';

// Styles
import s from './Logo.module.less';

interface IProps {
  className?: string;
}

export const Logo: FC<IProps> = ({ className }) => {
  return (
    <div className={cn(s.logo, className)}>
      <img className={s.image} alt="logo" src="/img/logo.svg" />
    </div>
  );
};

import { BadgeCounter, BadgeCounterProps } from '@/shared/components/BadgeCounter';
import cn from 'classnames';
import { upperFirst } from 'lodash';
import { ReactNode } from 'react';

// Styles
import s from './TabBadge.module.less';

interface Props {
  count?: number | unknown[];
  text: ReactNode | string | undefined;
  dot?: 'default' | 'success' | 'danger' | 'warning' | 'muted' | 'customer' | 'writer';
  counterType?: BadgeCounterProps['type'];
}

export const TabBadge = ({ count, dot, text, counterType, ...badgeProps }: Props): JSX.Element => (
  <span className={s.wrapper}>
    {dot && <span className={cn(s.dot, s[`dot${upperFirst(dot)}`])} />}
    {text}
    {!!count && (
      <BadgeCounter
        size="small"
        type={counterType}
        className={s.badge}
        count={Array.isArray(count) ? count.length : count}
        {...badgeProps}
      />
    )}
  </span>
);

import { TabBadge } from '@/shared/components/TabBadge';
import { MessagesList } from '@/shared/features/messenger/ui/MessagesList';
import { Tabs, TabsProps } from 'antd';
import { takeRight } from 'lodash';
import { ReactNode, useCallback, useMemo, useRef } from 'react';
import { useMessengerContext } from '../lib/messengerContext';
import { getUnreadCount } from '../lib/messengerHelpers';
import { CommonMessage, CommonMessageVisibilities } from '../types/messages';

interface Props extends TabsProps {
  messages: CommonMessage[];
  limit: number;
  children?: ReactNode;
}

export const MessengerChannelsTabs: FC<Props> = ({ messages, limit, children, ...tabProps }) => {
  const { config, layout, visibleChannels, interactiveChannels } = useMessengerContext();

  // Group messages by visibility (#channels)
  const getMessages = useCallback(
    (visibility: CommonMessageVisibilities) => messages.filter((_) => _.idVisibility === visibility),
    [messages],
  );
  const publicMessages = getMessages(CommonMessageVisibilities.Everyone);
  const customerMessages = getMessages(CommonMessageVisibilities.Customer);
  const writerMessages = getMessages(CommonMessageVisibilities.Writer);
  const privateMessages = getMessages(CommonMessageVisibilities.User);

  // Channel interactivity & visibility conditions
  const writerTabVisible = visibleChannels!.writer && (interactiveChannels!.writer || !!writerMessages.length);
  const customerTabVisible = visibleChannels!.customer && (interactiveChannels!.customer || !!customerMessages.length);
  const publicTabVisible = visibleChannels!.public && (interactiveChannels!.public || !!publicMessages.length) && config?.channels.public;

  // Container for main channel
  const _containers = useRef<CommonMessage[]>([]);

  // [Better UI] multiple #channels available -> Render as TABS
  const tabItems: TabsProps['items'] = useMemo(
    () => {
      const channels: TabsProps['items'] = []; // dynamic optimized channels

      // #customer (hide channel from writer)
      if (customerTabVisible && config?.channels.customer) {
        _containers.current = customerMessages;

        channels.push({
          key: 'customer',
          label: (
            <TabBadge counterType="alert" text={config?.channels.customer.label} count={getUnreadCount(customerMessages)} dot="customer" />
          ),
          children: <MessagesList total={customerMessages.length} messages={takeRight(customerMessages, limit)} />,
        });
      }

      // #writer (hide channel from customer)
      if (writerTabVisible && config?.channels.writer) {
        _containers.current = writerMessages;

        channels.push({
          key: 'writer',
          label: <TabBadge counterType="alert" text={config?.channels.writer.label} count={getUnreadCount(writerMessages)} dot="writer" />,
          children: <MessagesList total={writerMessages.length} messages={takeRight(writerMessages, limit)} />,
        });
      }

      // #public
      if (publicTabVisible) {
        _containers.current = publicMessages;

        channels.push({
          key: 'public',
          label: <TabBadge counterType="alert" text={config?.channels.public.label} count={getUnreadCount(publicMessages)} dot="default" />,
          children: <MessagesList total={publicMessages.length} messages={takeRight(publicMessages, limit)} />,
        });
      }

      // #private for support only
      if (config?.channels.private) {
        _containers.current = privateMessages;

        channels.push({
          key: 'private',
          label: <TabBadge count={privateMessages.length} text={config?.channels.private.label} dot="success" />,
          children: <MessagesList total={privateMessages.length} messages={takeRight(privateMessages, limit)} />,
        });
      }

      return channels;
    },
    // eslint-disable-next-line
    [messages, limit, visibleChannels, interactiveChannels],
  );

  // Destroy inactive tab pane -> eats less memory
  return tabItems.length > 1 ? (
    <Tabs
      {...tabProps}
      tabBarStyle={{ minWidth: 115, position: 'sticky' }}
      className={layout}
      size="small"
      tabPosition={layout === 'wide' ? 'left' : 'top'}
      items={tabItems}
    />
  ) : (
    // [Better UI] case: single channel -> render without tabs (as main)
    <MessagesList messages={takeRight(_containers.current, limit)} total={_containers.current.length} />
  );
};

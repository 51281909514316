import { GridCards, GridCardsProps } from '@/components/common/layout/GridCards';
import { Empty } from 'antd';

export type CardsDataBoundaryProps = {
  items?: any[];
  empty?: string;
} & GridCardsProps;

export const CardsDataBoundary: FC<CardsDataBoundaryProps> = ({ empty, loading, items, ...props }) =>
  !loading && !items?.length ? (
    <Empty description={empty} imageStyle={{ height: 150, marginTop: 30 }} image="/img/empty.svg" />
  ) : (
    <GridCards size="large" loading={loading} {...props} />
  );

import { createContext, useContext } from 'react';
import { BroadcastMessage } from '@/types/broadcastMessage';

type BroadcastMessageContextType = {
  setBroadcastMessage: (message: BroadcastMessage) => void;
};

// Context
export const BroadcastMessageContext = createContext<Partial<BroadcastMessageContextType>>({});

// Main hook
export const useBroadcastMessageContext = (): Partial<BroadcastMessageContextType> => useContext(BroadcastMessageContext);

import { ICustomer, IWriter } from '@/shared/types/participants';
import { LogMessage, Patch } from './common/api';
import { Order, OrderSnapshotCondensed } from './order';
import { Revision } from './revision';
import { CommonSearchParams } from './search';
import { TicketTopLevelMessage } from './ticketMessage';
import { WriterOrder } from './writerOrder';
import { Grading } from '@/types/grading';

export type TicketActions = 'resolve' | 'withdraw';

export type TicketCreationContext = 'profile' | 'ticket' | 'revision' | 'order' | 'grading';

export enum TicketStatuses {
  Draft = 1,
  Pending,
  InWork,
  Resolved,
  Canceled,
  Removed,
  Withdrawn,
}

export enum TicketOrigins {
  User = 1,
  Customer,
  Writer,
}

export enum ETicketVisibilities {
  User = 1,
  Customer,
  Writer,
  Everyone,
}

// Basic summary
export type Ticket = {
  id: number;
  dateAdded: string;
  dateDue?: string;
  writerName?: string;
  writerDescription?: string;
  idStatus: TicketStatuses;
  idOrigin: TicketOrigins;
  idVisibility: ETicketVisibilities;
};

export type TicketSearchItem = Ticket & {
  order?: Order;
  orderSnapshot?: OrderSnapshotCondensed;
  revision?: Revision;
  parentTicket?: Ticket;
  writerOrder?: WriterOrder;
  grading?: Grading;
};

// Single
export type TicketDetails = TicketSearchItem & {
  childrenTickets?: Ticket[];
  initialThreadMessages?: TicketTopLevelMessage[];
  customer?: ICustomer;
  writer?: IWriter;
};

// Search engine
export type TicketSearchParams = {
  idFrom?: number;
  idTo?: number;
  dateAddedFrom?: string;
  dateAddedTo?: string;
  dateDueFrom?: string;
  dateDueTo?: string;
  name?: string;
  idStatus?: TicketStatuses;
  idOrder?: number[];
  idOrderSnapshot?: number[];
  idRevision?: number[];
  idParentTicket?: number[];
  sortBy?: TicketSortField;
} & CommonSearchParams;

export type TicketSortField = 'id' | 'dateAdded' | 'name' | 'idStatus';
export type TicketDateField = 'dateAdded' | 'dateDue';

export type TicketBaseCreatePayload = {
  writerName: string;
  writerDescription?: string;
  dateDue?: string;
};

export type TicketCreatePayload =
  | TicketBaseCreatePayload
  | ((TicketBaseCreatePayload & { idGrading: number }) | { idWriterOrder: number } | { idRevision: number } | { idParentTicket: number });

export type TicketCreateRequest = TicketCreatePayload;

export type TicketUpdatePayload = LogMessage & {
  writerName?: string;
  writerDescription?: string;
};

export type TicketUpdateRequest = Patch<TicketUpdatePayload>;

export type TicketUpdateStatusPayload = LogMessage;
export type TicketUpdateStatusRequest = Patch<TicketUpdateStatusPayload>;

export type TicketCreateMessagePayload = {
  name: string;
  description?: string;
  idParentMessage?: number;
};

export type TicketCreateMessageRequest = Patch<TicketCreateMessagePayload>;

export type TicketUpdateFilesPayload = {
  ids: number[];
};

export type TicketUpdateFilesRequest = Patch<TicketUpdateFilesPayload>;

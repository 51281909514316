import { App } from '@/components/App';
import { ErrorBoundary } from '@/components/common/errors/ErrorBoundary';
import { SpinFullscreen } from '@/components/common/spinner/SpinFullscreen';
import { suppressAntdWarnings } from '@/shared/helpers/errors';
import { initSentry } from '@/shared/helpers/sentry';
import { persistor, store } from '@/store/store';
import { ChatwootWidget } from '@feature/chat/ChatwootWidget';
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

// Styles
import 'react-phone-input-2/lib/style.css';
import './styles/global/antd/theme.less';
import './styles/global/index.less';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Suspense fallback={<SpinFullscreen />}>
            <App />
            <ChatwootWidget />
          </Suspense>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </ErrorBoundary>,
);

// Errors
suppressAntdWarnings();

// Handle all exceptions with Sentry
initSentry();

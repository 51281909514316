import { InputTextEditor } from '@/shared/components/InputTextEditor';
import { Trunc } from '@/shared/components/Trunc';
import { getMessageInputId, ParsedInputValue, serializeMessageInputValue } from '@/shared/features/messenger/lib/serializeInput';
import { ClosedIcon } from '@/shared/features/messenger/ui/icons/ClosedIcon';
import { LockedIcon } from '@/shared/features/messenger/ui/icons/LockedIcon';
import { msg } from '@/shared/helpers/msg';
import { Button, Dropdown, Form, Menu, Spin, Tooltip } from 'antd';
import cn from 'classnames';
import React, { ChangeEvent, useEffect, useMemo } from 'react';
import { useMessengerContext } from '../lib/messengerContext';
import { CommonMessage, CommonMessageCreatePayload, CommonMessageUpdatePayload, MessengerViewContext } from '../types/messages';

// Styles
import s from './SendMessageForm.module.less';

interface Props {
  view: MessengerViewContext;
  channel: string;
  onFocusEditor?: () => void;
  onSendMessage?: (message: CommonMessage) => void;

  isInteractive: boolean;
  currentTabInteractive: boolean;
  direction: string;
}

export const MessageForm: FC<Props> = ({
  onFocusEditor,
  children,
  view,
  onSendMessage,
  channel = '#private',
  isInteractive,
  currentTabInteractive,
  direction,
}) => {
  const { id, editorRef, setParentMessage, parentMessage, setEditedMessage, editedMessage, config, refetch, hooks, inactiveMessage } =
    useMessengerContext();
  const idVisibility = config?.channels[channel]?.idVisibility; // not undefined -> config

  const disabled = !isInteractive || !currentTabInteractive;

  const mode = useMemo(() => {
    if (parentMessage) return 'reply';
    if (editedMessage) return 'edit';
    return 'create';
  }, [parentMessage, editedMessage]);

  const [form] = Form.useForm();

  useEffect(() => {
    if (!id || !editedMessage) return;
    localStorage.setItem(getMessageInputId(id, channel), serializeMessageInputValue(editedMessage.text, 'edit', editedMessage.id));
    form.setFieldsValue({ text: editedMessage.text });
  }, [form, editedMessage, id, channel]);

  useEffect(() => {
    if (!id || !parentMessage) return;
    const value = form.getFieldValue('text');
    if (!value?.trim()) return;
    localStorage.setItem(getMessageInputId(id, channel), serializeMessageInputValue(value, 'reply', parentMessage.id));
  }, [form, parentMessage, id, channel]);

  const idTargetMessage = editedMessage?.id || parentMessage?.id;

  useEffect(() => {
    if (!id) return;

    const storedValue = localStorage.getItem(getMessageInputId(id, channel));

    if (!storedValue) {
      form.setFieldsValue({ text: '' });
      return;
    }
    try {
      const result: ParsedInputValue = JSON.parse(storedValue);
      form.setFieldsValue({ text: result.value });
    } catch (err) {}
  }, [form, id, channel]);

  // Select query hook by view
  const [addMessage, { isLoading: addLoading }] = hooks!.addMessage(); // hook [POST] message
  const editHookResult = hooks?.editMessage?.();

  const updateMessage = editHookResult?.[0];
  const isLoading = addLoading || !!editHookResult?.[1].isLoading;

  if (!id) return null;

  const switchToCreateMode = () => {
    mode === 'edit' && clearMessageForm();

    const value = form.getFieldValue('text');
    const inputId = getMessageInputId(id, channel);

    if (value?.trim()) {
      localStorage.setItem(inputId, serializeMessageInputValue(form.getFieldValue('text'), 'create'));
    } else {
      localStorage.removeItem(inputId);
    }

    setParentMessage?.();
    setEditedMessage?.();
  };

  // Send on Cmd+Enter
  const handleKeyDown = (e) => {
    // Cancel thread mode
    e.key === 'Escape' && switchToCreateMode(); // exit reply mode

    // Send message
    if (e.shiftKey) return; // Shift+Enter -> add new line

    if (e.keyCode === 13) {
      form.submit(); // Enter -> send message
      e.preventDefault();
    }
  };

  const clearMessageForm = () => {
    form.setFieldsValue({ text: '' }); // coz: reset lose input focus
  };

  const resetTextToOriginal = () => {
    form.setFieldsValue({ text: editedMessage?.text || '' });
  };

  const editMessage = async (text: string) => {
    if (text.trim() === editedMessage?.text?.trim() || !updateMessage || !editedMessage) {
      clearMessageForm();
      switchToCreateMode();
      return false;
    }
    try {
      return await updateMessage({ id: editedMessage.id, idTicket: id, body: { text } }).unwrap();
    } catch (err) {
      return false;
    }
  };

  const createMessage = async (text: string) => {
    // Reply mode
    const idParentMessage: number | undefined = parentMessage?.idThreadMessage || parentMessage?.id;
    // Basic payload
    const body: CommonMessageCreatePayload = { idParentMessage, text, idTicket: id }; // request body
    // Extra payload for ticket messages
    if (view === 'ticket') body.idVisibility = idVisibility; // only for ticket

    try {
      return await addMessage(body).unwrap();
    } catch (err) {
      return false;
    }
  };

  const handleSubmit = async ({ text }: CommonMessageCreatePayload | CommonMessageUpdatePayload) => {
    if (!id) return;

    if (!text) return msg.warn('Please add some text'); // check is text empty

    clearMessageForm(); // clear before sent... no wait for response... CP-1636

    const sentMessage = await (mode === 'edit' ? editMessage(text) : createMessage(text));

    if (!sentMessage) {
      form.setFieldsValue({ text }); // restore (coz: textarea was cleaned before send)
      return;
    }

    localStorage.removeItem(getMessageInputId(id, channel));

    switchToCreateMode();

    await onSendMessage?.(sentMessage); // cb
  };

  const handleInputTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value?.trim()) {
      localStorage.setItem(getMessageInputId(id, channel), serializeMessageInputValue(event.target.value, mode, idTargetMessage));
    } else {
      localStorage.removeItem(getMessageInputId(id, channel));
    }
  };

  return (
    <div
      id="msg-form"
      className={cn(s.wrapper, {
        [s.disabled]: disabled,
      })}
      onKeyDown={handleKeyDown}
    >
      {
        /* PREVIEW REPLIED/EDITED MESSAGE (UX) */
        ['reply', 'edit'].includes(mode) && (
          <div className={s.preview}>
            <span>
              <span>
                {
                  {
                    reply: config?.icons.quote,
                    edit: config?.icons?.edit,
                  }[mode]
                }{' '}
                {
                  {
                    reply: 'Reply',
                    edit: 'Edit:',
                  }[mode]
                }
              </span>
              <Trunc
                text={
                  {
                    reply: parentMessage,
                    edit: editedMessage,
                  }[mode]?.text
                }
                max={100}
                lines={1}
              />
            </span>

            <Button type="text" size="small" icon={config?.icons.cancel} onClick={switchToCreateMode} />
          </div>
        )
      }

      <Form className={s[`${channel}`]} form={form} onFinish={handleSubmit} disabled={disabled}>
        {/* EDITOR WITH CHILDREN! YAP, YAP! */}
        <div className={s.editorWrap}>
          <InputTextEditor
            autoFocus
            className={s.recipient}
            onFocus={onFocusEditor}
            editorRef={editorRef}
            placeholder={`${config?.channels[channel].direction} channel`}
            onChange={handleInputTextChange}
            item={{ name: 'text', noStyle: true }}
            maxLength={1000}
            autoSize={{ minRows: 3, maxRows: 10 }}
          />
          {disabled && (
            <div className={s.disabledOverlay}>
              <div className={s.disabledIcon}>{isInteractive ? <LockedIcon /> : <ClosedIcon />}</div>
              <div className={s.disabledText}>
                {direction} channel
                <br />
                {inactiveMessage?.(channel)}
              </div>
            </div>
          )}
        </div>

        {/* HINT / POST MESSAGE BUTTON & MENU */}
        <footer className={s.footer}>
          {/* EXTRA */}
          <div className={s.children}>{children}</div>

          {/* SENDING INDICATOR */}
          <span>
            <Spin size="small" spinning={isLoading} delay={100} />
          </span>

          <span className={s.submit}>
            {/* MENU */}
            <Dropdown
              placement="top"
              trigger={['click']}
              children={<Button type="text" className={s.footerDropdownBtn} size="small" icon={config?.icons.actions} />}
              overlay={
                <Menu>
                  <Menu.Item key={0} onClick={refetch}>
                    Sync messages
                  </Menu.Item>
                  <Menu.Item key={1} onClick={clearMessageForm}>
                    Clear input
                  </Menu.Item>
                  {mode === 'edit' && (
                    <Menu.Item key={2} onClick={resetTextToOriginal}>
                      Reset to original
                    </Menu.Item>
                  )}
                </Menu>
              }
            />

            {/* HINT */}
            <span className={s.hint}>
              <Tooltip
                title={
                  <>
                    <kbd>Shift+Enter</kbd> → Add new line <br />
                    <kbd>Enter</kbd> → Send message <br />
                    <kbd>Esc</kbd> → Exit edit (reply) mode <hr />
                    Click on &quot;Channel status&quot; (Sender & Recipient) for reconnect Messenger manually.
                  </>
                }
              >
                Help
              </Tooltip>
            </span>

            {/* SEND */}
            {!disabled && (
              <Button className={s.submitBtn} size="small" disabled={isLoading} htmlType="submit">
                {
                  {
                    reply: 'Reply to message',
                    create: 'Send message',
                    edit: 'Save',
                  }[mode]
                }
              </Button>
            )}
          </span>
        </footer>
      </Form>
    </div>
  );
};

import { AllHTMLAttributes, } from 'react';
import { Tag, Tooltip } from 'antd';
import cn from 'classnames';
import s from './Dictionary.module.less';
import { useDictionary } from '@/hooks/dicts';
import { DictTables } from '@/types/common/dict';
import { camelCase } from 'lodash';

export type DictionaryProps = {
  id: number;
  tag?: boolean;
  key?: string;
  dot?: boolean;
} & Omit<AllHTMLAttributes<HTMLSpanElement>, 'id'>;

export const Dictionary: FC<DictionaryProps & { dict: DictTables }> = ({ id, dict, key = 'name', dot, tag, className, ...props }) => {
  const { values, isDictReady } = useDictionary(dict);
  if (!isDictReady) return null;

  const dictClassName = camelCase(dict);

  if (!id) return <Tag color="red">[d] id?</Tag>;
  if (!values) return <Tag color="red">[d] key {dict} not found</Tag>;

  const dictionary = values[id];
  if (!dictionary) return <Tag color="red">[d] invalid id {id}</Tag>;

  const Wrapper = tag ? Tag : (props) => <span {...props} />;

  const displayName = dot ? dictionary[key].split(' ').reduce((prev: string, next: string) => prev + next[0], '') : dictionary[key];

  return (
    <Tooltip title={dot ? dictionary[key] : null}>
      <Wrapper
        className={cn(
          {
            [s.wrapper]: tag || dot,
            [s.dotTag]: dot,
          },
          s[dictClassName],
          s[`${dictClassName}-${id}`],
          className
        )}
        {...props}
      >
        {displayName}
      </Wrapper>
    </Tooltip>
  );
};

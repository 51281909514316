import { GridCardsProps } from '@/components/common/layout/GridCards';
import { RevisionSearchItem } from '@/types/revision';

import { RevisionCard } from './RevisionCard';
import { CardsDataBoundary } from '@/components/common/cards/CardsDataBoundary';

export type RevisionCardsProps = {
  items?: RevisionSearchItem[];
  context?: 'order';
} & GridCardsProps;

export const RevisionCards: FC<RevisionCardsProps> = ({ context, ...props }) => (
  <CardsDataBoundary {...props}>
    {props.items?.map((item) => (
      <RevisionCard key={item.id} item={item} context={context} />
    ))}
  </CardsDataBoundary>
);

import { useFetchAllDictsQuery } from '@/api/dicts';
import { DefaultCatalog, DictTables } from '@/types/common/dict';

type UseDictionaryReturn = {
  values: Record<string, DefaultCatalog> | null;
  isDictReady: boolean;
};
export const useDictionary = (table: DictTables): UseDictionaryReturn => {
  const { data, isSuccess } = useFetchAllDictsQuery(); // make query or get from cache
  return {
    isDictReady: isSuccess,
    values: data ? data[table] : null,
  };
};

import ReactJson, { ReactJsonViewProps } from 'react-json-view';

// Styles
import s from './Debug.module.less';

interface IProps extends Omit<ReactJsonViewProps, 'src'> {
  data: object | undefined;
  title?: string;
}

export const Debug: FC<IProps> = ({ data, title, ...reactJsonProps }) => {
  if (!data || !window.location.origin.match(/local|stage/)) return null;

  return (
    <div className={s.wrapper}>
      {title && <h2>{title}</h2>}
      <ReactJson src={data} collapsed={true} displayDataTypes={false} {...reactJsonProps} />
    </div>
  );
};

import { useDeadlineCountdown } from '@/hooks/deadline';
import { DeadlineProgressBar } from '@/components/common/DeadlineProgressBar';

type OrderDeadlineProgressBarProps = {
  dateDue?: string;
  deadline?: number;
};
export const OrderDeadlineProgressBar = ({ dateDue, deadline }: OrderDeadlineProgressBarProps) => {
  const { remainingSeconds } = useDeadlineCountdown(dateDue);

  if (!dateDue) return null;

  const percentageValue = (remainingSeconds / 3600 / Number(deadline)) * 100;

  return <DeadlineProgressBar percentageValue={percentageValue} />;
};

export const getMessageInputId = (idTicket: number, channel: string) => `ticket-message-${idTicket}-${channel}`;

export type ParsedInputValue = {
  value: string;
  action: 'create' | 'edit' | 'reply';
  idMessage?: number;
};
export const serializeMessageInputValue = (value: string, action: 'create' | 'edit' | 'reply', idMessage?: number) =>
  JSON.stringify({
    value,
    action,
    idMessage,
  });

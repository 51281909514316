import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import moment from 'moment';

i18n
  .use(Backend)
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      interpolation: { escapeValue: false },
      fallbackLng: 'en',
      returnEmptyString: false,
      // debug: process.env.NODE_ENV !== 'production',
    },
    () => {
      moment.locale(i18n.language);
    },
  );
export const t = i18n.t;

export const translator = i18n;
export const changeLanguage = (val: string): void => {
  i18n.changeLanguage(val, () => {
    moment.locale(val);
  });
};

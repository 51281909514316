/**
 * TODO: Debug & check popover behavior in support system!
 * Removed code from content <div> `onMouseLeave={() => setOpen(false)}` @max attn!
 */
import { useMessengerContext } from '@/shared/features/messenger/lib/messengerContext';
import s from '@/shared/features/messenger/ui/MessageItem.module.less';
import { Button, Popover, Space } from 'antd';
import cn from 'classnames';
import React, { ReactNode, useState } from 'react';

type MessageItemActionsPopoverProps = {
  isPosted: boolean;
  icon?: ReactNode;
  actions?: ReactNode;
};

export const MessageItemActionsPopover = ({ isPosted, icon, actions }: MessageItemActionsPopoverProps) => {
  const { hoverActionsAvailable } = useMessengerContext();
  const [open, setOpen] = useState(false);

  return hoverActionsAvailable ? (
    <span
      style={{
        position: 'relative',
        verticalAlign: 'middle',
      }}
    >
      <Popover
        open={open}
        onOpenChange={setOpen}
        placement="bottom"
        overlayStyle={{ top: 100 }}
        trigger="click"
        destroyTooltipOnHide // eats less memory
        getPopupContainer={(triggerNode) => triggerNode.parentElement as HTMLElement}
        overlayClassName={s.actionsOverlay}
        content={<div className={cn(s.actionsInner, { [s.inactive]: !isPosted })}>{actions}</div>}
      >
        <Button size="small" icon={icon} type="text" />
      </Popover>
    </span>
  ) : (
    <Space direction="vertical" style={{ width: '100%' }}>
      {actions}
    </Space>
  );
};

import { DivElement } from '@/shared/types/dom';
import cn from 'classnames';

// Styles
import s from './Widget.module.less';

interface WidgetProps extends DivElement {
  className?: string;
  bordered?: boolean;
  width?: number;
  style?: React.CSSProperties;
  order?: number;
}

/**
 * Display as widget (transparent card)
 * @param children is required
 * @param bordered boolean
 * @param className optional
 * @param ...props supports all HTMLDivElement props
 */
export const Widget: FC<WidgetProps> = ({ children, bordered, className, width, style, order, ...divProps }) => (
  /*
   * TODO: Adaptive?
   * const { isLgMin } = useBreakpoints();
   */
  <div style={{ order, width, minWidth: width, ...style }} className={cn(s.wrapper, { [s.bordered]: bordered }, className)} {...divProps}>
    {children}
  </div>
);

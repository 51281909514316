// Docs: https://ant.design/@/notification/
import { Debug } from '@/components/common/Debug';
import { t } from '@/services/translator';
import { message, notification } from 'antd';

message.config({ top: 5, duration: 3, maxCount: 1 });

// Create link for unique name (different from antd message)
export const msg = message;

export const showErrorNotification = (message: string, data: any) => {
  notification.error({
    message: (
      <>
        {message}
        <Debug
          data={data}
          name={t('misc.details') + ':'}
          collapsed
          displayDataTypes={false}
          indentWidth={1}
          displayObjectSize={false}
          enableClipboard={false}
          // @ts-ignore
          displayArrayKey={false}
          style={{ fontSize: 12 }}
        />
      </>
    ),
    duration: 0,
  }); // notification instead of msg -> coz: large text
};

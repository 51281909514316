import { t } from '@/services/translator';
import { Form, FormItemProps, Input, InputProps } from 'antd';
import { useBreakpoints } from '@/hooks/breakpoints/useBreakpoints';

type InputSearchProps = {
  item?: FormItemProps;
} & InputProps;

export const InputSearch: FC<InputSearchProps> = ({ item, ...inputProps }) => {
  const { isXs } = useBreakpoints();
  return (
    <Form.Item name="name" {...item}>
      <Input.Search placeholder={t('searchEngine.search')} allowClear style={{ minWidth: isXs ? 'auto' : 240 }} {...inputProps} />
    </Form.Item>
  );
};

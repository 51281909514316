import { BadgeCounter } from '@/components/common/BadgeCounter';
import { FA } from '@/components/common/icons/FA';
import { FontAwesomeIcon } from '@/types/common/fa';
import { ReactNode } from 'react';
import cn from 'classnames';

// Styles
import s from './TabBadge.module.less';
import { upperFirst } from 'lodash';

interface IProps {
  icon?: FontAwesomeIcon;
  count?: number | unknown[];
  text: ReactNode;
  dot?: 'success' | 'danger';
}

export const TabBadge = ({ dot, icon, count, text, ...badgeProps }: IProps): JSX.Element => {
  return (
    <span className={s.wrapper}>
      {dot && <span className={cn(s.dot, s[`dot${upperFirst(dot)}`])} />}
      <span style={{ marginRight: 4 }}>{icon ? <FA type={icon} text={text} className={s.icon} /> : text}</span>
      {!!count && <BadgeCounter className={s.badge} count={Array.isArray(count) ? count.length : count} {...badgeProps} />}
    </span>
  );
};

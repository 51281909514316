import { useState } from 'react';

export const useFlag = (initialValue = false): [boolean, () => void, (flag: boolean) => void] => {
  const [flag, setFlag] = useState(initialValue);

  const toggleFlag = () => {
    setFlag((prev) => !prev);
  };

  return [flag, toggleFlag, setFlag];
};

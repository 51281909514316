import { useGetTicketQuery } from '@/api/tickets';
import { Button } from '@/components/common/button/Button';
import { TinyButton } from '@/components/common/button/TinyButton';
import { FeatureLink } from '@/components/common/FeatureLink';
import { getColor } from '@/helpers/utils';
import { useFeaturedTicket } from '@/hooks/featuredTicket';
import { BadgeCounter } from '@/shared/components/BadgeCounter';
import { SpaceBetween } from '@/shared/components/layout/SpaceBetween';
import { Trunc } from '@/shared/components/Trunc';
import { useGetTicketMessagesQuery } from '@/shared/features/messenger/api/messenger';
import { getUnreadCount } from '@/shared/features/messenger/lib/messengerHelpers';
import { messagesAdapter } from '@/shared/features/messenger/lib/messengerService';
import { useFlag } from '@/shared/hooks/useFlag';
import { WriterMessenger } from '@feature/messenger/WriterMessenger';
import { TicketStatusName } from '@feature/ticket/TicketStatusName';
import { Space } from 'antd';
import cn from 'classnames';

import s from './PinnedTicketMessenger.module.less';

interface Props {}

/**
 * NOTE: Pinned ticket Messenger mounted in AppBaseLayout
 */
export const PinnedTicketMessenger: FC<Props> = () => {
  const [id, setId] = useFeaturedTicket();
  const [hidden, toggle] = useFlag(true);

  const { data: ticket } = useGetTicketQuery(id, { skip: !id });
  const { data: _messages } = useGetTicketMessagesQuery(id, { skip: !id });

  const isActive = Boolean(ticket && id);
  const messages = _messages ? messagesAdapter.getSelectors().selectAll(_messages) : [];
  const unreadCount = getUnreadCount(messages);

  if (!id || !ticket) return null;

  // BADGE
  if (hidden)
    return (
      <div className={cn(s.wrapper, s.hidden)} onClick={toggle}>
        {isActive && (
          <div style={{ position: 'absolute', top: -10, left: -10 }}>
            <BadgeCounter count={unreadCount} overflowCount={unreadCount} type="alert" />
          </div>
        )}
        <Button type="text" icon="fa-messages" theme="fad" color={getColor('primary')} />
        {isActive && <span className={s.id}>#{ticket?.id}</span>}

        <WriterMessenger ticket={ticket} hidden />
      </div>
    );

  // MESSENGER
  return (
    <div className={s.wrapper}>
      <h4 className={s.heading}>
        <SpaceBetween>
          <FeatureLink id={ticket.id} feature="Ticket" className={s.link}>
            <TicketStatusName id={ticket.idStatus} dot /> #{ticket.id}{' '}
            <strong>
              <Trunc text={ticket.writerName} max={30} />
            </strong>
          </FeatureLink>
          <Space>
            <TinyButton icon="fa-minus" onClick={toggle} />
            <TinyButton modification="red" icon="fa-close" onClick={() => setId(0)} />
          </Space>
        </SpaceBetween>
      </h4>
      <WriterMessenger ticket={ticket} layout="pinned" />
    </div>
  );
};

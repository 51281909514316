import { FA } from '@/components/common/icons/FA';
import { DATE_FORMAT_DEFAULT } from '@/config/constants';
import { useTimezone } from '@/hooks/timezone';
import { Tooltip } from 'antd';
import cn from 'classnames';
import { upperFirst } from 'lodash';
import moment from 'moment-timezone';

// Styles
import s from './DateTime.module.less';
import { getTimezoneName } from '@/hooks/timezone';
import { getColor } from '@/helpers/utils';

interface Props {
  className?: string;
  children?: any;
  value: string | undefined; // datetime value
  /** @deprecated tooltip */
  tooltip?: boolean;
  /** @deprecated tooltipValue */
  tooltipValue?: string;
  icon?: boolean;
  prefix?: string; // works only with icon?
  customerTimezone?: boolean; // render by customer tz
  customFormat?: string;
  fromNow?: boolean;
  type?: 'default' | 'secondary';
  showExpired?: boolean;
}

export const DateTime: FC<Props> = ({
  className,
  value,
  customFormat,
  fromNow,
  icon,
  prefix,
  customerTimezone,
  type = 'default',
  showExpired = true,
}) => {
  const tz = useTimezone();

  const localTimezone = moment.tz.guess();
  const isCustom = customerTimezone && tz !== localTimezone;
  const formatShort = `MM-DD HH:mm`;
  const format = customFormat || `${DATE_FORMAT_DEFAULT}${isCustom ? ' z' : ''}`; // z -> time shift

  const preferredDate = moment.utc(value).tz(tz);
  const alternativeDate = moment.utc(value).tz(localTimezone); // inverted for tooltip
  const isExpired = preferredDate.isBefore(moment.utc());

  if (!value) return <>n\a</>;

  return (
    <Tooltip
      title={
        <>
          <strong style={{ whiteSpace: 'nowrap' }}>
            <FA type="fa-clock" theme="fas" text={getTimezoneName(String(preferredDate.tz()))} />
          </strong>
          <hr />
          <div>
            {alternativeDate.format(`${formatShort} ${isCustom ? 'z' : ''}`)} / {getTimezoneName(String(alternativeDate.tz()))}
          </div>
          <hr />
          <div>{moment(value).format(formatShort)} / UTC</div>
        </>
      }
    >
      <span className={cn(s.wrapper, s[`type${upperFirst(type)}`], className)}>
        {!showExpired && fromNow ? (
          preferredDate.fromNow()
        ) : (
          <>
            {icon && <FA type="fa-calendar-lines" text={prefix} color={getColor('muted')} />} {preferredDate.format(format)}
            {fromNow && (
              <>
                {' '}
                <span className={s.divider}>/</span>{' '}
                <span className={cn(s.fromNow, { [s.expired]: isExpired })}>{preferredDate.fromNow()}</span>
              </>
            )}
          </>
        )}
      </span>
    </Tooltip>
  );
};

import { useAuthGlobalLogoutMutation, useAuthLogoutMutation } from '@/api/auth';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { AuthState, selectAuth } from './authSlice';
import { emptyApi } from '@/store/emptyApi';
import { authLogout } from '@/store/auth/authActions';

export const useAuth = (): AuthState => useAppSelector(selectAuth);

export const useGracefulLogout = () => {
  const [requestLogout] = useAuthLogoutMutation();
  const [requestGlobalLogout] = useAuthGlobalLogoutMutation();

  const dispatch = useAppDispatch();

  const logoutFn = async (global?: boolean) => {
    // logout with endpoint
    await (global ? requestGlobalLogout : requestLogout)();
    dispatch(authLogout(emptyApi));
  };

  return {
    logout: logoutFn,
  };
};

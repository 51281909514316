import { Button } from '@/components/common/button/Button';
import { Flex } from '@/components/common/layout/page/Page';
import { setDocumentTitle } from '@/helpers/dom';
import { Result } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { t } from '@/services/translator';
import { FA } from '@/components/common/icons/FA';
import { getColor } from '@/helpers/utils';

// Styles
import cn from 'classnames';
import s from './ErrorPage.module.less';

type ErrorPageProps = {
  code: number;
};

export const ErrorPage = ({ code }: ErrorPageProps) => {
  const reloadPage = () => globalThis.location.reload();

  const [count, setCount] = useState(120);

  useEffect(() => {
    setDocumentTitle([0, 10].includes(code) || !code ? 'Unknown error' : `Error ${code}`);
  }, [code]);

  const error = useMemo(() => {
    if (code === 404) {
      return {
        title: t('errors.error404title'),
        subTitle: t('errors.error404subtitle'),
        icon: 404,
        buttons: ['back', 'home'],
      };
    } else if (code >= 400 && code < 500) {
      return {
        title: t('errors.error400title'),
        subTitle: t('errors.error400subtitle'),
        icon: 400,
        buttons: ['back'],
      };
    } else if (code === 503) {
      return {
        title: t('errors.error503title'),
        subTitle: t('errors.error503subtitle'),
        icon: <FA type="fa-history" theme="fad" style={{ fontSize: 80 }} color={getColor('primary')} />,
        buttons: ['reload'],
      };
      // client error
    } else if (code === 0) {
      return {
        title: t('errors.clientErrorTitle'),
        subTitle: t('errors.clientErrorSubtitle'),
        icon: null,
        buttons: ['reload'],
      };
      // client ChunkLoadError
    } else if (code === 10) {
      return {
        title: t('errors.clientChunkErrorTitle'),
        subTitle: t('errors.clientChunkErrorSubtitle'),
        icon: null,
        buttons: ['reload'],
      };
    } else {
      return {
        title: t('errors.error500title'),
        subTitle: t('errors.error500subtitle'),
        icon: 500,
        buttons: ['back', 'reload'],
      };
    }
  }, [code]);

  useEffect(() => {
    if (code !== 500) return;
    const id = setInterval(() => {
      if (!count) {
        clearInterval(id);
        return reloadPage();
      }
      setCount(count - 1);
    }, 1000);
    return () => clearInterval(id);
  }, [code, count]);

  return (
    <Flex className={cn(s.wrapper, s[`code${code}`])}>
      <Result
        status="error"
        style={{ paddingTop: 0 }}
        className={s.container}
        icon={
          typeof error.icon === 'number' ? (
            <img src={`/img/errors/${error.icon}.svg`} alt={`Error ${error.icon}`} />
          ) : (
            <div>{error.icon}</div>
          )
        }
        title={<div className={s.title} dangerouslySetInnerHTML={{ __html: error.title }} />}
        subTitle={<div className={s.subTitle} dangerouslySetInnerHTML={{ __html: error.subTitle }} />}
        extra={error.buttons.map((btnType) => {
          switch (btnType) {
            case 'back':
              return (
                <Button
                  key={btnType}
                  icon="fa-arrow-left"
                  type="primary"
                  size="large"
                  onClick={async () => {
                    window.location.href = '/';
                  }}
                >
                  {t('errors.back')}
                </Button>
              );
            case 'home':
              return (
                <a href="/" key={btnType}>
                  <Button icon="fa-house" size="large">
                    {t('errors.goHome')}
                  </Button>
                </a>
              );
            case 'reload':
              return (
                <Button key={btnType} onClick={reloadPage} icon="fa-arrow-rotate-back" size="large">
                  {t('errors.reloadPage')}
                </Button>
              );
            default:
              return null;
          }
        })}
      />
    </Flex>
  );
};

import { RouteItem, routes } from '@/config/routes/routesList';
import { Feature } from '../features';

export type AppRoute = Omit<RouteItem, 'feature'> & Feature;

export const getAppRoutes = (): AppRoute[] => {
  const routeAdapter = ({ feature, single, element }: RouteItem) => {
    const { title, titleSingle, path } = feature;
    return { ...feature, single, title: (single && titleSingle) || title, path: single ? (`/${path}/:id` as `/${string}`) : path, element };
  };
  return routes.map(routeAdapter);
};

import { Divider } from 'antd';
import cn from 'classnames';
import moment from 'moment/moment';
import { useMessengerContext } from '../lib/messengerContext';
import { getMessageSender, isSupportSystem } from '../lib/messengerHelpers';
import { CommonMessage, CommonMessageStatuses } from '../types/messages';
import { MessengerLimitDivider } from './layout/MessengerLimitDivider';
import { MessageItem } from './MessageItem';
import s from './MessageItem.module.less'; // styles from MessageItem
import { MessageMobileActionsModal } from './MessageMobileActionsModal';

/**
 * Render messages recursively
 * @param messages
 * @param className
 */
type MessagesListProps = {
  messages: CommonMessage[] | undefined;
  className?: string; // thread
  total?: number;
};
export const MessagesList: FC<MessagesListProps> = ({ messages, total = 0, className }) => {
  const { isFetching, setLimit, isInteractive, limit, renderList, activeChannel, config, mobileClickedMessage, interactiveChannels } =
    useMessengerContext();

  if (!config || isFetching) return null;

  const channelIsInteractive = isInteractive && interactiveChannels && activeChannel && interactiveChannels[activeChannel];

  if (!messages?.length) {
    return channelIsInteractive ? (
      <Divider className={s.emptyWrap}>
        <div className={s.empty}>💬 This is the very beginning of your message history</div>
      </Divider>
    ) : null;
  }

  return (
    <>
      {renderList?.(messages)}
      {limit && setLimit && <MessengerLimitDivider total={total} limit={limit} onLimitChange={setLimit} />}
      {messages?.map((msg, idx) => {
        // Previous message
        const prevMsg = (idx && messages?.[idx - 1]) || ({} as CommonMessage);
        const prevAuthorKey = getMessageSender(prevMsg);
        const prevAuthor = idx && prevMsg && prevMsg[prevAuthorKey];

        // Next message
        const nextAuthorKey = getMessageSender(msg);
        const nextAuthor = msg[getMessageSender(msg)];

        // Conditions
        const isNotSameAuthor = !idx || prevAuthor?.id !== nextAuthor?.id || prevAuthorKey !== nextAuthorKey; // skip first, group by id when authorKeys are different
        const isDifferentTime = moment(msg.dateAdded).diff(prevMsg?.dateAdded, 'seconds') > 30; // group all messages diff 30seconds

        // Only for WS & CS! Hide retracted messages
        if (!isSupportSystem() && msg.idStatus === CommonMessageStatuses.Retracted) return null;

        return (
          <MessageItem
            key={msg.id}
            className={cn(className, {
              // NOTE: Always compare with false, because isRead can be undefined or null
              unread: isSupportSystem() ? msg.idStatus === CommonMessageStatuses.Unchecked : msg.isRead === false, // scroll to 1st .unread -> check messengerHooks -> useChat (hook)
            })}
            showAuthor={isNotSameAuthor || msg.edited} // group by author
            showTime={!idx || isDifferentTime || msg.edited} // group by time
            message={msg}
          >
            {/* Render children / Thread messages */}
            {!!msg?.threadMessages?.length && <MessagesList className={s.thread} messages={msg?.threadMessages} />}
          </MessageItem>
        );
      })}
      {mobileClickedMessage && <MessageMobileActionsModal mobileClickedMessage={mobileClickedMessage} />}
    </>
  );
};

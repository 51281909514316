import { Currencies } from '@/types/common/currency';
import cn from 'classnames';
import { ReactNode } from 'react';

// Styles
import s from './Currency.module.less';

// Main component

type CurrencyProps = {
  idCurrency?: Currencies;
  value?: number;
  prefix?: ReactNode;
  suffix?: ReactNode;
  percentage?: number;
  hideZero?: boolean;
  className?: string;
  markPositive?: boolean;
  primaryColor?: boolean;
};

// Currency wrapper
export const Currency: FC<CurrencyProps> = ({
  value = 0,
  percentage,
  hideZero,
  idCurrency = 1,
  prefix,
  suffix,
  className,
  markPositive,
  primaryColor,
}) => {
  if (hideZero && !value && !percentage) return null;

  return (
    <span className={cn(s.wrapper, className)}>
      <span className={s.prefix}>{prefix}</span>
      <span className={s.values}>
        {!!(value || !hideZero) && (
          <span
            className={cn(s.data, {
              [s.negative]: value < 0,
              [s.positive]: value > 0 && markPositive,
              [s.primary]: primaryColor,
            })}
          >
            {Number(value).toFixed(2)} {idCurrency === 1 ? 'USD' : 'EUR'}
          </span>
        )}
        {!!percentage && <span className={s.data}>{percentage} % </span>}
      </span>
      <span className={s.suffix}>{suffix}</span>
    </span>
  );
};

import { useSearchPageParams } from '@/shared/components/searchEngine';
import {
  notificationsBroadcastMessagesView,
  notificationsHistoricBroadcastMessagesView,
  notificationsPopoverBroadcastMessagesView,
  notificationsPopoverHistoricBroadcastMessagesView,
} from '@/components/search/config';
import { importantNoticesNotificationParams, messagesNotificationParams } from '@/helpers/notifications';
import { SearchEngine } from '@/components/search/SearchEngine';
import { NotificationOpenAllButton } from '@feature/notification/NotificationOpenAlllButton';
import { Spin } from 'antd';
import { Debug } from '@/components/common/Debug';
import { useSearchBroadcastMessagesQuery } from '@/api/broadcastMessages';
import { BroadcastMessageCards } from '@feature/broadcastMessage/BroadcastMessageCards';
import { BroadcastMessagesTable } from '@feature/broadcastMessage/BroadcastMessagesTable';
// import { useSmartPolling } from '@/shared/hooks/useSmartPolling';
// import { COUNTER_INTERVAL_SECONDS } from '@/config/constants';

type NotificationBroadcastMessagesTabProps = {
  isPopover?: boolean;
  isHistoric: boolean;
};

export const NotificationBroadcastMessagesTab = ({ isPopover = true, isHistoric }: NotificationBroadcastMessagesTabProps) => {
  const searchConfig = isHistoric
    ? isPopover
      ? notificationsPopoverHistoricBroadcastMessagesView
      : notificationsHistoricBroadcastMessagesView
    : isPopover
    ? notificationsPopoverBroadcastMessagesView
    : notificationsBroadcastMessagesView;

  const overrides = isHistoric ? importantNoticesNotificationParams : messagesNotificationParams;

  // const { pollingInterval } = useSmartPolling(COUNTER_INTERVAL_SECONDS);

  const { params, setParams } = useSearchPageParams(searchConfig.id, searchConfig.defaultParams, overrides);

  const { data, isFetching, isLoading } = useSearchBroadcastMessagesQuery(
    params,
    // { pollingInterval }
  );

  return isLoading ? (
    <div
      style={{
        height: 140,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Spin size="large" />
    </div>
  ) : (
    <SearchEngine
      params={params}
      setParams={setParams}
      total={data?.total}
      config={searchConfig}
      saveParams
      extra={isPopover && <NotificationOpenAllButton tab={isHistoric ? 'important-notices' : 'messages'} />}
    >
      {isPopover ? (
        <BroadcastMessagesTable
          items={data?.items}
          isHistoric={isHistoric}
          style={{ width: isPopover ? 900 : 'auto' }}
          loading={isFetching}
        />
      ) : (
        <BroadcastMessageCards items={data?.items} loading={isFetching} />
      )}
      <Debug data={data} />
    </SearchEngine>
  );
};

import { FRONT_TIME_FORMAT, SERVER_TIME_FORMAT } from '@/config/constants';
import moment, { Moment } from 'moment-timezone';
import { lowerCase, upperFirst } from 'lodash';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

// Sync this type with _variables.app.less
export type AppColors =
  | 'text'
  | 'success'
  | 'success-hover'
  | 'primary'
  | 'warning'
  | 'muted'
  | 'gray'
  | 'info'
  | 'danger'
  | 'user'
  | 'writer'
  | 'customer'
  | 'turquoise'
  | 'link';

export const getCSSVar = (name: string) => getComputedStyle(document.documentElement).getPropertyValue(name);

export const getColor = (color: AppColors) => {
  return getCSSVar(`--${color}-color`);
};

export const isTouchDevice = () => !window.matchMedia('(hover: hover) and (pointer: fine)').matches;

type DateAddedRestored = {
  dateRestored?: string;
  dateAdded: string;
};

type DateRemoved = {
  dateRemoved?: string;
};

export const sortAssociationsByDateAddedRestored = (a: DateAddedRestored, b: DateAddedRestored) =>
  moment(a.dateRestored || a.dateAdded).isBefore(b.dateRestored || b.dateAdded) ? 1 : -1;

export const sortAssociationsByDateRemoved = (a: DateRemoved, b: DateRemoved) => (moment(a.dateRemoved).isBefore(b.dateRemoved) ? 1 : -1);

export const timeBackendToMoment = (time?: string) => (time ? moment(time, SERVER_TIME_FORMAT) : null);
export const timeFormatMomentToBackend = (time: Moment) => time.format(SERVER_TIME_FORMAT);
export const timeFormatMomentToFrontend = (time: Moment) => time.format(FRONT_TIME_FORMAT);
export const timeFormatBackendToFrontend = (time: string) => moment(time, SERVER_TIME_FORMAT).format(FRONT_TIME_FORMAT);

export const stripTags = (str?: string) => str?.replace(/(<([^>]+)>)/gi, '');

export const normalCase = (str: string) => upperFirst(lowerCase(str));
export const replaceTemplate = (str: string, s: string) => (str.match('%s') ? str.replace('%s', s) : str);

// Get error code from FetchBaseQueryError -> todo: move to /shared repo
export const getErrorCode = (error: FetchBaseQueryError | SerializedError | undefined): number => {
  if (!error) return 0; // unknown
  if ('originalStatus' in error) return error.originalStatus; // main
  if ('status' in error) return +error.status || 0; // fallback
  return 0; // default
};

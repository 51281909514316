async function playSound(url) {
  const instance = new Audio(url);

  instance.volume = 0.2;

  try {
    await instance.play();
  } catch (err) {}

  // cleanup
  setTimeout(() => (instance.srcObject = null), 1000);
}

export const playPop = () => playSound('/sound/sent.mp3');
export const playNotification = () => playSound('/sound/notification.mp3'); // TODO: use it for incoming messages?...

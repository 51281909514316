import { useMessengerContext } from '../../lib/messengerContext';

interface Props {
  threadMode?: boolean;
  count: number;
  onClick?: () => void;
}

export const MessengerFooter: FC<Props> = ({ threadMode, count, onClick }) => {
  const { config, layout } = useMessengerContext();
  return layout === 'pinned' ? null : (
    <div style={{ fontSize: 12, color: config?.colors.muted, cursor: 'pointer' }} onClick={onClick}>
      <div>
        {!!count && (
          <>
            {config?.icons.messages} {count} in {threadMode ? 'current' : 'main'} thread.{' '}
          </>
        )}
        {count >= 5 && <span style={{ width: 32 }}>Scroll to the end</span>}
        {threadMode && <div>(Press Esc to exit from active thread)</div>}
      </div>
    </div>
  );
};

import { WriterOrderStatuses } from '@/types/writerOrder';

export const ACTIVE_WRITER_ORDER_STATUSES = [
  WriterOrderStatuses.Assigned,
  WriterOrderStatuses.InWork,
  WriterOrderStatuses.Submitted,
  WriterOrderStatuses.UnableToProceed,
  WriterOrderStatuses.Pending,
  WriterOrderStatuses.Paused,
  WriterOrderStatuses.Ready,
];
export const INACTIVE_WRITER_ORDER_STATUSES = [
  WriterOrderStatuses.Unassigned,
  WriterOrderStatuses.Aborted,
  WriterOrderStatuses.Dismissed,
  WriterOrderStatuses.Withdrawn,
  WriterOrderStatuses.Rejected,
  WriterOrderStatuses.Completed,
];

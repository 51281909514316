import { OrderDeadlineProgressBar } from '@feature/order/OrderDeadlineProgressBar';
import { TimeCountDown } from '@/components/common/TimeCountDown';
import { DateTime } from '@/components/common/DateTime';

type OrderDeadlineProps = {
  variant: 'card' | 'default';
  dateDue: string;
  deadline: number;
};

export const OrderDeadline = ({ dateDue, deadline, variant }: OrderDeadlineProps) => {
  return (
    <div>
      <DateTime value={dateDue} />
      <br />
      <TimeCountDown dueDate={dateDue} />
      <div style={variant === 'card' ? { marginLeft: -74 } : {}}>
        <OrderDeadlineProgressBar dateDue={dateDue} deadline={deadline} />
      </div>
    </div>
  );
};

import { usePreferences } from '@/hooks/preferences';

export const useFeaturedTicket = (): [number, (id: number) => void] => {
  const { preferences, updatePreferences } = usePreferences();

  const id = preferences.idPinnedTicket || 0;
  const setId = (newId: number) => {
    return updatePreferences({ idPinnedTicket: id === newId ? 0 : newId }); // toggle mode
  };

  return [id, setId];
};

import { API_PATH } from '@/config/features';
import { emptyApi as api } from '@/store/emptyApi';
import { DefaultCatalog, DictTables } from '@/types/common/dict';
import { Country } from '@/types/country';

// Entity
const path = API_PATH.Dicts;
const type = 'Dicts';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchAllDicts: build.query<Record<DictTables, Record<string, DefaultCatalog>>, void>({
      query: () => ({ url: `/dicts` }),
      keepUnusedDataFor: 1800, // 30 min
    }),
    searchCountries: build.query<Record<string, Country>, void>({
      query: () => ({ url: `${path}/countries?countryStatus=1` }),
      providesTags: () => [{ type, id: 'search' }],
    }),
  }),
  overrideExisting: false,
});

export { injectedRtkApi as enhancedApi };

export const { useFetchAllDictsQuery, useSearchCountriesQuery } = injectedRtkApi;

import { useSearchPageParams } from '@/shared/components/searchEngine';
import { notificationsRevisionsPopoverView, notificationsRevisionsView } from '@/components/search/config';
import { useSearchRevisionsQuery } from '@/api/revisions';
import { revisionNotificationParams } from '@/helpers/notifications';
import { SearchEngine } from '@/components/search/SearchEngine';
import { RevisionsTable } from '@feature/revision/RevisionsTable';
import { NotificationOpenAllButton } from '@feature/notification/NotificationOpenAlllButton';
import { Spin } from 'antd';
import { InputSearch } from '@/components/search/InputSearch';
import { t } from '@/services/translator';
import { Debug } from '@/components/common/Debug';
import { RevisionCards } from '@feature/revision/RevisionCards';
// import { useSmartPolling } from '@/shared/hooks/useSmartPolling';
// import { COUNTER_INTERVAL_SECONDS } from '@/config/constants';

type NotificationRevisionsTabProps = {
  isPopover?: boolean;
};

export const NotificationRevisionsTab = ({ isPopover = true }: NotificationRevisionsTabProps) => {
  const searchConfig = isPopover ? notificationsRevisionsPopoverView : notificationsRevisionsView;
  const { params, setParams } = useSearchPageParams(searchConfig.id, searchConfig.defaultParams);

  // const { pollingInterval } = useSmartPolling(COUNTER_INTERVAL_SECONDS);

  const { data, isFetching, isLoading } = useSearchRevisionsQuery(
    { ...params, ...revisionNotificationParams },
    // { pollingInterval }
  );

  return isLoading ? (
    <div
      style={{
        height: 140,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Spin size="large" />
    </div>
  ) : (
    <SearchEngine
      params={params}
      setParams={setParams}
      total={data?.total}
      config={searchConfig}
      searchField={isPopover ? undefined : <InputSearch placeholder={t('searchEngine.search')} />}
      controls={isPopover ? undefined : <></>}
      saveParams
      extra={isPopover && <NotificationOpenAllButton tab="revisions" />}
    >
      {isPopover ? (
        <RevisionsTable items={data?.items} style={{ width: isPopover ? 900 : 'auto' }} loading={isFetching} />
      ) : (
        <RevisionCards items={data?.items} loading={isFetching} />
      )}
      <Debug data={data} />
    </SearchEngine>
  );
};

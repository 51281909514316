import { DateTime } from '@/components/common/DateTime';
import { CustomerIcon } from '@/components/common/icons/CustomerIcon';
import { FA } from '@/components/common/icons/FA';
import { UserIcon } from '@/components/common/icons/UserIcon';
import { WriterIcon } from '@/components/common/icons/WriterIcon';
import { TIME_FORMAT } from '@/config/constants';
import { getColor } from '@/helpers/utils';
import {
  CommonMessageStatuses,
  CommonMessageVisibilities,
  MessengerChannels,
  MessengerConfig,
} from '@/shared/features/messenger/types/messages';
import moment from 'moment-timezone';

export const MESSENGER_CONFIG: MessengerConfig<Extract<MessengerChannels, 'writer' | 'public'>> = {
  websockets: {
    ticket: 'writers/messages',
  },

  icons: {
    disconnected: <FA type="fa-chain-broken" text="Disconnected" />,
    customer: <CustomerIcon />,
    writer: <WriterIcon />,
    user: <UserIcon />,
    messages: <FA type="fa-messages" theme="far" />,
    quote: <FA type="fa-messages" theme="fad" color={getColor('primary')} />,
    spinner: <FA type="fa-spinner-third" className="fa-spin" />,
    reply: <FA type="fa-reply" theme="far" color={getColor('primary')} />,
    actions: <FA type="fa-ellipsis-v" theme="far" />,
    cancel: <FA type="fa-times" />,
    unknown: '🤨',
    // Simplify statuses only Unread (check) / Read (double check)
    [CommonMessageStatuses.Unchecked]: <FA type="fa-check" />,
    [CommonMessageStatuses.Posted]: <FA type="fa-check-double" />,
    [CommonMessageStatuses.Declined]: <FA type="fa-check-double" />,
    // Discarded
    [CommonMessageStatuses.Removed]: <FA type="fa-trash" />,
    [CommonMessageStatuses.Retracted]: <FA type="fa-times" />,
  },

  colors: {
    primary: getColor('muted'),
    customer: getColor('muted'),
    writer: getColor('muted'),
    support: getColor('muted'),
    muted: getColor('text'),
  },

  channels: {
    // Chat with support (writer private channel)
    writer: {
      label: '#support',
      idVisibility: CommonMessageVisibilities.Writer,
      direction: 'You & Support',
    },
    // Chat with customer (public)
    public: {
      label: '#customer',
      idVisibility: CommonMessageVisibilities.Everyone,
      direction: 'You & Customer',
    },
  },

  renderMessage: {
    date: ({ dateAdded }) => {
      const now = moment();
      const fromNow = now.diff(dateAdded, 'hour') < 1; // group all messages diff 30seconds
      const isSameDay = now.diff(dateAdded, 'day') <= 1;
      return <DateTime value={dateAdded} fromNow={fromNow} customFormat={isSameDay ? TIME_FORMAT : `DD MMM ${TIME_FORMAT}`} />;
    },
  },
};

interface Props {
  count?: number;
}

/**
 * This trick fixing scroll & fixed position of editor
 */
export const MessengerSpacer: FC<Props> = ({ count = 6 }) => (
  <>
    {Array.from({ length: count }).map((_, idx) => (
      <br key={idx} />
    ))}
  </>
);

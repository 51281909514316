import moment from 'moment/moment';
import { useCallback, useEffect, useState } from 'react';

export const useDeadlineCountdown = (dueDate?: string, countExpired?: boolean) => {
  const getDiffSeconds = useCallback(() => {
    const now = moment().utc();
    return moment.utc(dueDate).diff(now, 'seconds');
  }, [dueDate]);

  const [remainingSeconds, setRemainingSeconds] = useState(getDiffSeconds());

  const isExpired = getDiffSeconds() <= 0;

  useEffect(() => {
    if (isExpired && !countExpired) return; // expired date without countdown

    // Start countdown
    const timerId = setInterval(() => {
      setRemainingSeconds(getDiffSeconds());
    }, 1000);
    return () => clearTimeout(timerId);
  }, [isExpired, remainingSeconds, getDiffSeconds, dueDate, countExpired]);

  useEffect(() => {
    setRemainingSeconds(getDiffSeconds());
  }, [dueDate, getDiffSeconds]);

  return {
    isExpired,
    remainingSeconds,
  };
};

import { SpinFullscreen } from '@/components/common/spinner/SpinFullscreen';
import { SITE_NAME } from '@/config/constants';
import { AppRoute } from '@/config/routes';
import React, { ReactElement, Suspense, useEffect } from 'react';
import { t } from '@/services/translator';
import { useLocation, useParams } from 'react-router-dom';

// Component which serves the purpose of a "root route element"
export const LazyPageHOC = ({ element: Element, title, icon, path }: AppRoute): ReactElement => {
  const id = useParams().id;
  const { pathname } = useLocation();

  // Fix scroll position onNavigation
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const docTitle = path.match(/:id/g) ? `${id} • ` + t(title) : t(title);
    document.title = String(docTitle) + ' • ' + SITE_NAME;
  }, [title, path, id]);

  return (
    <Suspense fallback={<SpinFullscreen />}>
      <Element title={t(title)} icon={icon} />
    </Suspense>
  );
};

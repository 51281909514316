import { useBreakpoints } from '@/hooks/breakpoints/useBreakpoints';

export const WinXs: FC = ({ children }) => {
  const { isXs } = useBreakpoints();
  return isXs && children ? <>{children}</> : null;
};
export const WinXsMin: FC = ({ children }) => {
  const { isXsMin } = useBreakpoints();
  return isXsMin && children ? <>{children}</> : null;
};
export const WinSm: FC = ({ children }) => {
  const { isSm } = useBreakpoints();
  return isSm && children ? <>{children}</> : null;
};
export const WinSmMin: FC = ({ children }) => {
  const { isSmMin } = useBreakpoints();
  return isSmMin && children ? <>{children}</> : null;
};
export const WinMd: FC = ({ children }) => {
  const { isMd } = useBreakpoints();
  return isMd && children ? <>{children}</> : null;
};
export const WinMdMin: FC = ({ children }) => {
  const { isMdMin } = useBreakpoints();
  return isMdMin && children ? <>{children}</> : null;
};
export const WinLg: FC = ({ children }) => {
  const { isLg } = useBreakpoints();
  return isLg && children ? <>{children}</> : null;
};
export const WinLgMin: FC = ({ children }) => {
  const { isLgMin } = useBreakpoints();
  return isLgMin && children ? <>{children}</> : null;
};
export const WinXl: FC = ({ children }) => {
  const { isXl } = useBreakpoints();
  return isXl && children ? <>{children}</> : null;
};
export const WinXlMin: FC = ({ children }) => {
  const { isXlMin } = useBreakpoints();
  return isXlMin && children ? <>{children}</> : null;
};
export const WinXxl: FC = ({ children }) => {
  const { isXxl } = useBreakpoints();
  return isXxl && children ? <>{children}</> : null;
};
export const WinXxlMin: FC = ({ children }) => {
  const { isXxlMin } = useBreakpoints();
  return isXxlMin && children ? <>{children}</> : null;
};
export const WinXxxl: FC = ({ children }) => {
  const { isXxxl } = useBreakpoints();
  return isXxxl && children ? <>{children}</> : null;
};

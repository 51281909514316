import { Spin } from 'antd';
import cn from 'classnames';
import { upperFirst } from 'lodash';
import { CSSProperties } from 'react';

// Styles
import s from './GridCards.module.less';

export type GridCardsProps = {
  size?: 'large' | 'normal' | 'small' | 'xs' | 'xxs' | 'col-2' | 'col-1';
  type?: 'default' | 'card';
  minHeight?: 'default' | 'small';
  loading?: boolean;
  className?: string;
  style?: CSSProperties;
};

export const GridCards: FC<GridCardsProps> = ({
  size = 'normal',
  type = 'default',
  minHeight = 'default',
  style,
  loading = false,
  children,
  className,
}) => (
  <Spin spinning={loading}>
    <div
      className={cn(
        s.wrapper,
        s[size],
        s[type],
        s[`minHeight${upperFirst(minHeight)}`],
        {
          [s.loading]: loading,
        },
        className,
      )}
      style={style}
    >
      {children}
    </div>
  </Spin>
);

import { throttle } from 'lodash';
import { useCallback } from 'react';

/**
 * Throttle function react hook
 * @param fn
 * @param delay
 * @returns throttled function
 * @example
 * const throttledRefetch = useDebouncedFn(refetch, 3000);
 */
export const useThrottle = (fn: () => void, delay = 1000) =>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useCallback(
    throttle(fn, delay), // 300ms delay
    [fn], // dependencies
  );

import { MESSENGER_CONFIG } from '@/config/messenger';
import { useBreakpoints } from '@/hooks/breakpoints/useBreakpoints';
import { useInvalidatesTags } from '@/hooks/invalidateTags';
import { useAddTicketMessageMutation, useGetTicketMessagesQuery } from '@/shared/features/messenger/api/messenger';
import { CommonTicketWithUnreadMessages } from '@/shared/features/messenger/types/messages';
import { Messenger, MessengerProps } from '@/shared/features/messenger/ui/Messenger';
import { useThrottle } from '@/shared/hooks/useThrottle';
import { useAuth } from '@/store/auth/authHooks';
import { ETicketVisibilities, TicketDetails, TicketStatuses } from '@/types/ticket';
import { RetractTicketMessage } from '@feature/messenger/RetractMessage';

type WriterMessengerProps = Pick<MessengerProps, 'unreadCount' | 'hidden' | 'layout'> & {
  ticket: TicketDetails | CommonTicketWithUnreadMessages;
};

// TODO: Wait for backend to fix the issue with event id mismatch and replace with WSEventTypes.TicketMessage
export const WriterMessenger = ({ ticket, ...messengerProps }: WriterMessengerProps) => {
  const { isLgMin } = useBreakpoints();

  const { user: writer } = useAuth();

  const refetchAll = useInvalidatesTags([{ type: 'TicketMessage' }]);
  const throttledRefetchAll = useThrottle(refetchAll, 1000);

  // Can write messages if ticket is in draft, pending or in work status -> used custom flag instead of messengerConditions coz CommonTicketWithUnreadMessages
  const canWriteMessages = [TicketStatuses.Draft, TicketStatuses.Pending, TicketStatuses.InWork].includes(ticket.idStatus);

  const channelInteractivityConditions = {
    writer: true, // always interactive
    public: ticket.idVisibility === ETicketVisibilities.Everyone && !!ticket.customer,
  };

  const channelVisibilityConditions = {
    writer: true, // always visible
    public: ticket.idVisibility === ETicketVisibilities.Everyone || !!ticket.customer,
  };

  return (
    <Messenger
      {...messengerProps}
      id={ticket.id}
      isInteractive={canWriteMessages}
      participants={{
        writer: writer,
        customer: ticket.customer,
      }}
      view="ticket"
      layout={isLgMin ? 'wide' : 'narrow'}
      config={MESSENGER_CONFIG}
      renderMessage={{
        extra: (msg) => <RetractTicketMessage message={msg} />,
      }}
      hooks={{
        addMessage: useAddTicketMessageMutation,
        fetchMessages: useGetTicketMessagesQuery,
      }}
      interactiveChannels={channelInteractivityConditions}
      visibleChannels={channelVisibilityConditions}
      inactiveMessage={(channel) =>
        channelVisibilityConditions?.[channel] && !channelInteractivityConditions?.[channel]
          ? 'This channel is locked and you cannot send messages here. Please contact support in case you have any concerns!'
          : 'You cannot chat here since the ticket is closed. In case you need to solve a new problem, you can create a new ticket.'
      }
      onWsMessage={(wsMessage) => {
        if (wsMessage.data.idTicket !== ticket.id) return;
        console.log('WriterMessenger.wsMessage', wsMessage);
        switch (wsMessage.event.name.toLowerCase()) {
          // [x] case WSEventTypes.TicketMessage:
          // [BUG] backend mismatch -> got id 4 (Issue message) instead of 7 (Ticket message) and that's why we use event name instead of id
          // TODO: Fix on backend and replace with WSEventTypes.TicketMessage
          case 'ticket message':
            console.log('TicketMessenger [counter update] [updateCounter]');
            throttledRefetchAll();
            break;
          default:
            console.log('TicketMessenger [default]', wsMessage.event.name);
        }
      }}
    />
  );
};

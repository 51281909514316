import { FeatureType, getFeatureURL } from '@/config/features';
import { getAppRoutes } from '@/config/routes';
import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import { breadcrumbsSelector, setBreadcrumbs } from '@/store/breadcrumbs/breadcrumbsSlice';
import { t } from 'i18next';
import { ReactNode, MouseEvent as ReactMouseEvent } from 'react';
import { Link, LinkProps, matchPath, useLocation } from 'react-router-dom';

export type FeatureLinkProps = {
  feature: FeatureType;
  id?: number | string;
  text?: ReactNode;
  params?: Record<string, string>;
} & Omit<LinkProps, 'to' | 'id'>;

export const FeatureLink: FC<FeatureLinkProps> = ({ feature: entity, id, text, children, params, onClick, ...props }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const breadcrumbs = useAppSelector(breadcrumbsSelector);

  let toUrl = getFeatureURL(entity, id);

  if (params) {
    toUrl += '?';
    Object.entries(params).forEach(([key, value]) => (toUrl += `${key}=${value}`));
  }

  const getRoutes = () => getAppRoutes().map(({ path, title }) => ({ path, title }));

  const routes = getRoutes();

  const targetSingleRoute = routes.find(({ path, title }) => title && !!matchPath(path, toUrl));

  const urlEnd = toUrl.split('/').pop();

  const entityId = Number(urlEnd) ? urlEnd : '';

  const appendBreadCrumb = () => {
    dispatch(
      setBreadcrumbs({
        items: [
          ...breadcrumbs,
          {
            from: location.pathname + window.location.search,
            title: targetSingleRoute ? t(targetSingleRoute.title) + (entityId ? ' ID ' + entityId : '') : '',
            to: toUrl,
          },
        ],
      }),
    );
  };

  const handleClick = (event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (toUrl !== location.pathname) {
      appendBreadCrumb();
    }

    onClick?.(event);
  };

  return (
    <Link to={toUrl} {...props} onClick={handleClick}>
      {children || text}
    </Link>
  );
};

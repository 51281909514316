import { Card } from 'antd';
import { ReactNode, useState, MouseEvent, CSSProperties } from 'react';
import cn from 'classnames';

import s from './FeaturePreviewCard.module.less';
import { FeatureLink } from '@/components/common/FeatureLink';
import { FeatureType } from '@/config/features';
import { DetailsList, DetailsListItem } from '@/components/common/layout/DetailsList';
import { t } from '@/services/translator';
import { DateTime } from '@/components/common/DateTime';
import { upperFirst } from 'lodash';
import { isTouchDevice } from '@/helpers/utils';
import { InfoTooltip } from '../InfoTooltip';
import { FA } from '../icons/FA';
import AnimateHeight from 'react-animate-height';
import { Trunc } from '../Trunc';

type FeaturePreviewCardProps = {
  feature?: FeatureType;
  type?: 'primary' | 'secondary';
  id?: number | string;
  title: ReactNode;
  status?: ReactNode;
  dateAdded?: string;
  headingBottom?: ReactNode;
  topDetails?: DetailsListItem[];
  details: DetailsListItem[];
  description?: ReactNode | string;
  onClick?: (event: MouseEvent) => void;
  alignLeft?: boolean;
  style?: CSSProperties;
};

export const FeaturePreviewCard = ({
  title,
  details,
  topDetails,
  feature: entity,
  id,
  status,
  dateAdded,
  description,
  headingBottom,
  type = 'primary',
  onClick,
  alignLeft,
  style,
}: FeaturePreviewCardProps) => {
  const [descriptionHeight, setDescriptionHeight] = useState<number | 'auto'>(0);

  const toggleCollapse = () => {
    setDescriptionHeight(descriptionHeight === 0 ? 'auto' : 0);
  };

  const handleButtonClick = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    toggleCollapse();
  };

  return (
    <Card
      size="small"
      className={cn(s.card, s[`type${upperFirst(type)}`], {
        [s[`noLink`]]: !entity,
      })}
      style={style}
    >
      <div className={s.inner}>
        <div className={s.heading}>
          <div className={s.headingMain}>
            <div className={s.headingLeft}>
              <h2 className={s.title}>{title}</h2>
              {description &&
                (isTouchDevice() ? (
                  <div className={s.infoButton} onClick={handleButtonClick}>
                    <FA className={s.icon} type="fa-info-circle" theme="far" color="var(--black-color)" />
                  </div>
                ) : (
                  <InfoTooltip text={description} className={s.tooltip} type="info" truncate />
                ))}
            </div>
            <div className={s.headingRight}>{status}</div>
          </div>
          {description && isTouchDevice() && (
            <AnimateHeight duration={250} height={descriptionHeight}>
              <div className={s.description}>
                <Trunc text={String(description)} max={500} lines={5} />
              </div>
            </AnimateHeight>
          )}
          {dateAdded && (
            <div className={s.headingDate}>
              <span>{t('misc.dateAdded')}:</span> <DateTime value={dateAdded} />
            </div>
          )}
          {headingBottom && <div className={s.headingBottom}>{headingBottom}</div>}
        </div>
        {topDetails && (
          <>
            <DetailsList align={alignLeft ? 'left' : 'between'} noBg={type === 'secondary' || details.length < 2} details={topDetails} />
            <hr style={{ margin: '12px 0' }} />
          </>
        )}
        <DetailsList align={alignLeft ? 'left' : 'between'} noBg={type === 'secondary' || details.length < 2} details={details} />
        {entity && <FeatureLink feature={entity} id={id} className={s.entityLink} onClick={onClick} />}
      </div>
    </Card>
  );
};

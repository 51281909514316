import { WriterOrderStatuses } from '@/types/writerOrder';
import { RevisionStatuses } from '@/types/revision';

export const orderNotificationParams = {
  idStatus: [WriterOrderStatuses.Assigned, WriterOrderStatuses.Pending],
};

export const revisionNotificationParams = {
  idStatus: [RevisionStatuses.Pending],
};

export const importantNoticesNotificationParams = {
  isHistoric: true,
  isRead: false,
};

export const messagesNotificationParams = {
  isHistoric: false,
  isRead: false,
};

import { Button, ButtonProps } from '@/components/common/button/Button';
import { AppColors, getColor } from '@/helpers/utils';

import { FontAwesomeIcon } from '@/types/common/fa';
// Styles
import s from './TinyButton.module.less';

type Props = ButtonProps & {
  icon: FontAwesomeIcon; // always required
};

const localColors: { [key: string]: AppColors } = {
  'fa-edit': 'primary',
  'fa-times': 'danger',
  'fa-check': 'success',
};

export const TinyButton: FC<Props> = (props) => (
  <Button
    className={s.button}
    style={{ padding: 0, width: 22, height: 22, minWidth: 22 }}
    size="small"
    theme="fas"
    color={getColor(localColors[props.icon])}
    {...props}
  />
);

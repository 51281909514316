import { t } from '@/services/translator';
import { Button } from '@/components/common/button/Button';
import { FeatureLink } from '@/components/common/FeatureLink';

type NotificationOpenAllButtonProps = {
  tab: 'orders' | 'revisions' | 'important-notices' | 'messages';
};

export const NotificationOpenAllButton = ({ tab }: NotificationOpenAllButtonProps) => (
  <FeatureLink feature="Notifications" params={{ tab }} target="_blank">
    <Button type="primary" size="small">
      {t('misc.openAll')}
    </Button>
  </FeatureLink>
);

import cn from 'classnames';
import Linkify from 'linkify-react';

// Styles
import s from './RichText.module.less';

interface Props {
  className?: string;
  text: string | undefined;
  empty?: string;
}

const linkifyOptions = {
  className: s.link,
  defaultProtocol: 'https',
  target: '_blank',
  rel: 'nofollow',
  style: { color: 'red', fontWeight: 'bold' },
};

/*
 * Helpers
 * All HTML will be ignored
 */
const cleanupRichText = (text = '') => text.replace(/\n{4,}/gi, '\n');

/**
 * Render text as Editor content. Based on HTML component & Editor styles
 * Linkify is a JavaScript plugin. Use Linkify to find links in plain-text and convert them to HTML <a> tags. It automatically highlights URLs
 * Docs: https://linkify.js.org/docs/
 */
export const RichText: FC<Props> = ({ text = '', empty, className }) => (
  <div className={cn(className, s.wrapper)}>{text ? <Linkify options={linkifyOptions}>{cleanupRichText(text)}</Linkify> : empty}</div>
);

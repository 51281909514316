import { Trunc } from '@/shared/components/Trunc';
import { useMessengerContext } from '@/shared/features/messenger/lib/messengerContext';
import { useMessageConditions } from '@/shared/features/messenger/lib/messengerHooks';
import { CommonMessage, CommonMessageStatuses } from '@/shared/features/messenger/types/messages';
import { EditMessageButton } from '@/shared/features/messenger/ui/EditMessageButton';
import { MessageHeader } from '@/shared/features/messenger/ui/MessageHeader';
import { MessageItemActionsPopover } from '@/shared/features/messenger/ui/MessageItemActionsPopover';
import { ReplyMessageButton } from '@/shared/features/messenger/ui/ReplyMessageButton';
import { Modal, Space } from 'antd';

export const MessageMobileActionsModal = ({ mobileClickedMessage }: { mobileClickedMessage: CommonMessage }) => {
  const { config, hoverActionsAvailable, setMobileClickedMessage, renderMessage, editingEnabled } = useMessengerContext();

  const { canReply, canEdit } = useMessageConditions(mobileClickedMessage);

  const isUnchecked = mobileClickedMessage.idStatus === CommonMessageStatuses.Unchecked;
  const isPosted = mobileClickedMessage.idStatus === CommonMessageStatuses.Posted;

  return (
    <Modal
      open={!hoverActionsAvailable && !!mobileClickedMessage}
      title="Select action:"
      destroyOnClose
      footer={false}
      afterClose={() => setMobileClickedMessage?.(undefined)}
      onCancel={() => setMobileClickedMessage?.(undefined)}
    >
      <Space style={{ width: '100%' }} direction="vertical">
        <MessageHeader message={mobileClickedMessage} showTime />
        <Trunc text={mobileClickedMessage.text} max={100} lines={1} />
        {renderMessage?.text?.(mobileClickedMessage, false)}
        {renderMessage?.extra?.(mobileClickedMessage, false)}
        {editingEnabled && canEdit && <EditMessageButton message={mobileClickedMessage} hover={false} />}
        {canReply && <ReplyMessageButton message={mobileClickedMessage} />}
        {(isPosted || isUnchecked) && renderMessage?.actions && (
          <MessageItemActionsPopover
            isPosted={isPosted}
            icon={config?.icons.actions}
            actions={renderMessage?.actions(mobileClickedMessage, false)}
          />
        )}
      </Space>
    </Modal>
  );
};

import { Spin, Tabs } from 'antd';
import { useEffect, useRef } from 'react';
import { useSearchWriterOrdersQuery } from '@/api/writerOrders';
import {
  importantNoticesNotificationParams,
  messagesNotificationParams,
  orderNotificationParams,
  revisionNotificationParams,
} from '@/helpers/notifications';
import { useSearchRevisionsQuery } from '@/api/revisions';
import { TabBadge } from '@/components/common/TabBadge';
import { t } from '@/services/translator';
import { FEATURE } from '@/config/features';
import { NotificationRevisionsTab } from '@feature/notification/tabs/NotificationRevisionsTab';
import { NotificationOrdersTab } from '@feature/notification/tabs/NotificationOrdersTab';
import { useSmartTabs } from '@/hooks/tabs';
import { useSearchBroadcastMessagesQuery } from '@/api/broadcastMessages';
import { NotificationBroadcastMessagesTab } from '@feature/notification/tabs/NotificationBroadcastMessagesTab';
import { useAppDispatch } from '@/hooks/redux';
import { setWatchingNotifications } from '@/store/notifications/notificationsSlice';
// import { useSmartPolling } from '@/shared/hooks/useSmartPolling';
// import { COUNTER_INTERVAL_SECONDS } from '@/config/constants';

type NotificationTab = 'orders' | 'revisions';

type NotificationTabsProps = {
  onLinkClick?: () => void;
  isPopover?: boolean;
  initialTab?: NotificationTab;
};

const { TabPane } = Tabs;

export const NotificationTabs = ({ onLinkClick, isPopover = true, initialTab }: NotificationTabsProps) => {
  const tabWrapperRef = useRef<HTMLDivElement>(null);

  // const { pollingInterval } = useSmartPolling(COUNTER_INTERVAL_SECONDS);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setWatchingNotifications(true));
    return () => {
      dispatch(setWatchingNotifications(false));
    };
  }, [dispatch]);

  const { smartTabs, setActiveTab, activeTab } = useSmartTabs({ defaultActiveKey: initialTab || 'orders' });

  const { data: writerOrders, isLoading: ordersLoading } = useSearchWriterOrdersQuery(
    {
      ...orderNotificationParams,
      size: 1,
    },
    // {
    //   pollingInterval,
    // },
  );

  const { data: revisions, isLoading: revisionsLoading } = useSearchRevisionsQuery(
    {
      ...revisionNotificationParams,
      size: 1,
    },
    // {
    //   pollingInterval,
    // },
  );

  const { data: importantNotices, isLoading: importantNoticesLoading } = useSearchBroadcastMessagesQuery(
    {
      ...importantNoticesNotificationParams,
      size: 1,
    },
    // {
    //   pollingInterval,
    // },
  );

  const { data: messages, isLoading: messagesLoading } = useSearchBroadcastMessagesQuery(
    {
      ...messagesNotificationParams,
      size: 1,
    },
    // {
    //   pollingInterval,
    // },
  );

  const isLoading = ordersLoading || revisionsLoading || importantNoticesLoading || messagesLoading;

  // close popover on link click
  useEffect(() => {
    const wrapperDiv = tabWrapperRef.current;
    if (!wrapperDiv) return;

    const handleWrapperClick = (event: MouseEvent) => {
      const hasParentLink = (event.target as HTMLElement).closest('a');
      if (hasParentLink) onLinkClick?.();
    };

    wrapperDiv.addEventListener('click', handleWrapperClick);
    return () => {
      wrapperDiv.removeEventListener('click', handleWrapperClick);
    };
  }, [tabWrapperRef, onLinkClick]);

  const tabs = [
    {
      key: 'orders',
      condition: !!writerOrders?.total,
      tab: <TabBadge icon={FEATURE.ActiveWriterOrder.icon} text={t('order.orders')} count={writerOrders?.total} />,
      children: <NotificationOrdersTab isPopover={isPopover} />,
    },
    {
      key: 'revisions',
      condition: !!revisions?.total,
      tab: <TabBadge icon={FEATURE.Revision.icon} text={t('revisions.revisions')} count={revisions?.total} />,
      children: <NotificationRevisionsTab isPopover={isPopover} />,
    },
    {
      key: 'important-notices',
      condition: !!importantNotices?.total,
      tab: <TabBadge icon={FEATURE.ImportantNotice.icon} text={t(FEATURE.ImportantNotice.title)} count={importantNotices?.total} />,
      children: <NotificationBroadcastMessagesTab isHistoric isPopover={isPopover} />,
    },
    {
      key: 'messages',
      condition: !!messages?.total,
      tab: <TabBadge icon={FEATURE.Message.icon} text={t(FEATURE.Message.title)} count={messages?.total} />,
      children: <NotificationBroadcastMessagesTab isHistoric={false} isPopover={isPopover} />,
    },
  ].filter(({ condition }) => condition);

  const tabKeys = tabs.map(({ key }) => key);

  useEffect(() => {
    if (!tabKeys.includes(activeTab) && tabKeys.length) {
      setActiveTab(tabKeys[0]);
    }
  }, [activeTab, setActiveTab, tabKeys]);

  const tabsProps = isPopover ? {} : smartTabs;

  return isLoading ? (
    <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
      <Spin size="large" />
    </div>
  ) : tabs.length ? (
    <div ref={tabWrapperRef}>
      <Tabs {...tabsProps} size={isPopover ? 'middle' : 'large'}>
        {tabs.map(({ key, ...tab }) => (
          <TabPane key={key} {...tab} />
        ))}
      </Tabs>
    </div>
  ) : (
    <p>{t('misc.noNotifications')}</p>
  );
};

import { useEffect, useRef } from 'react';
import { WSAnyMessage, WSMessageEventDetail, WSTicketData, WSTicketMessageData } from '@/shared/features/websockets/types/websockets';
import { askNotificationPermission } from '@/hooks/notifications/notifications';
import { MESSENGER_CONFIG } from '@/config/messenger';
import { useAuth } from '@/store/auth/authHooks';
import { TAB_NOTIFICATION_KEYS } from '@/config/constants';
import { notification as antNotification } from 'antd';

const notificationExistsInStorage = (idEvent: number, idNotification: number) => {
  const allKeys = localStorage.getItem(TAB_NOTIFICATION_KEYS);

  if (!allKeys) return false;

  try {
    const parsed = JSON.parse(allKeys);

    return !!parsed.find((item) => item.idEvent === idEvent && item.idNotification === idNotification);
  } catch (err) {
    localStorage.removeItem(TAB_NOTIFICATION_KEYS);
    return false;
  }
};

const addNotificationToStorage = (idEvent: number, idNotification: number) => {
  const allKeys = localStorage.getItem(TAB_NOTIFICATION_KEYS);

  let updatedValue = JSON.stringify([
    {
      idEvent,
      idNotification,
    },
  ]);

  try {
    if (allKeys) {
      const parsed = JSON.parse(allKeys);

      if (Array.isArray(allKeys)) {
        const targetNotification = !!parsed.find((item) => item.idEvent === idEvent && item.idNotification === idNotification);

        if (!targetNotification) {
          parsed.push({
            idEvent,
            idNotification,
          });

          updatedValue = JSON.stringify(parsed);
        }
      }
    }
  } catch (err) {}

  localStorage.setItem(TAB_NOTIFICATION_KEYS, updatedValue);
};

export const useMessageNotifications = () => {
  const { user } = useAuth();

  const userIdRef = useRef(user?.id);
  userIdRef.current = user?.id;

  useEffect(() => {
    const handleBroadcastNotification = async (
      event: CustomEvent<WSMessageEventDetail>,
      idEvent: number,
      idNotification: number,
      notificationsAllowed: boolean,
    ) => {
      addNotificationToStorage(idEvent, idNotification);
      let title = '';
      const image = `https://${window.location.host}/img/favicon.png`;
      let body = '';
      let url = '';

      try {
        const eventData: WSAnyMessage = JSON.parse(event.detail.data.data as string);

        const { idEventType } = eventData.data;

        if (idEventType >= 100 && idEventType <= 199) {
          const data = eventData.data as WSTicketMessageData;

          // ignore tickets that are being updated by current user
          if (data?.writer?.id === userIdRef.current) return;

          title = 'New ticket message related event.';
          body = 'Check message';
          url = `/tickets/${data.idTicket}`;
        } else if (idEventType >= 200 && idEventType <= 299) {
          const ticketData = eventData.data as WSTicketData;
          if (ticketData?.writer?.id === userIdRef.current) return;
          title = 'New ticket related event.';
          body = 'Check ticket';
          url = `/tickets/${ticketData?.id}`;
        } else if (idEventType >= 300 && idEventType <= 399) {
          const revisionData = eventData.data as WSTicketData;
          if (revisionData?.writer?.id === userIdRef.current) return;
          title = 'New revision related event.';
          body = 'Check revision';
          url = `/revisions/${revisionData?.id}`;
        }

        if (title) {
          if (notificationsAllowed && typeof Notification !== 'undefined') {
            // sending notification
            const notification = new Notification(title, { body, image });

            notification.onclick = (event) => {
              event.preventDefault(); // prevent the browser from focusing the Notification's tab
              window.open(`https://${window.location.host}${url}`, '_blank');
            };
          } else {
            antNotification.open({
              message: title,
              description: body,
              onClick: () => {
                window.open(`https://${window.location.host}${url}`, '_blank');
              },
            });
          }

          const audio = new Audio('/sound/notification.mp3');

          audio.volume = 0.5;
          audio.muted = false;

          audio.addEventListener('canplaythrough', () => {
            try {
              audio.play();
            } catch (err) {}
          });

          audio.addEventListener('ended', () => {
            audio.remove();
          });
        }
      } catch (err) {
        console.log('ERROR', err);
      }

      setTimeout(() => {
        const allKeys = localStorage.getItem(TAB_NOTIFICATION_KEYS);
        if (!allKeys) return;
        try {
          const parsed = JSON.parse(allKeys);
          localStorage.setItem(
            TAB_NOTIFICATION_KEYS,
            JSON.stringify(parsed.filter((item) => !(item.idEvent === idEvent && item.idNotification === idNotification))),
          );
        } catch (err) {}
      }, 5000);
    };

    const handleNotification = async (event: CustomEvent<WSMessageEventDetail>) => {
      // Not allowed
      if (event.detail.path !== MESSENGER_CONFIG.websockets.ticket) return;

      const notificationsAllowed = await askNotificationPermission();

      const eventData: WSAnyMessage = JSON.parse(event.detail.data.data as string);

      setTimeout(() => {
        const notificationIsInStorage = notificationExistsInStorage(eventData.event.id, eventData.data.id);
        if (notificationIsInStorage) {
          // already triggered
          return;
        }

        handleBroadcastNotification(event, eventData.event.id, eventData.data.id, notificationsAllowed);
      }, Math.floor(Math.random() * (500 + 1)));
    };

    document.addEventListener('wsMessageReceived', handleNotification as any);
    return () => {
      document.removeEventListener('wsMessageReceived', handleNotification as any);
    };
  }, []);
};

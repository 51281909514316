import React from 'react';
import { Tooltip } from 'antd';
import { upperFirst } from 'lodash';
import { stripTags } from '@/helpers/utils';

export const trunc = (str: string, max: number): string => (str.length > max ? str.substr(0, max - 1) + '…' : str);

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  text: string | undefined;
  lines?: number;
  max: number;
  tooltip?: boolean;
}
export const Trunc: FC<Props> = ({ text = '', lines = 1, max, tooltip, ...props }) => {
  if (!text || !String(text).trim()) return null;
  // remove HTML TAGS
  const processedText = '' + stripTags(text);
  const str = upperFirst(String(processedText));

  const Wrapper = tooltip && processedText.length > Number(max) ? Tooltip : ({ children }: { children: React.ReactElement }) => children;

  return (
    <Wrapper title={processedText}>
      <div
        style={
          lines
            ? {
                display: '-webkit-box',
                WebkitLineClamp: lines,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                lineHeight: 1.3,
              }
            : {}
        }
        dangerouslySetInnerHTML={{ __html: max ? trunc(str, max) : str }}
        {...props}
      />
    </Wrapper>
  );
};

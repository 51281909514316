import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { SerializedError } from '@reduxjs/toolkit';
import { SpinFullscreen } from '@/components/common/spinner/SpinFullscreen';
import { ErrorPage } from '@/components/common/errors/ErrorPage';
import { PropsWithChildren, useEffect, useState } from 'react';
import { getErrorCode } from '@/helpers/utils';

type PageDataLoaderProps<T> = PropsWithChildren<{
  error: FetchBaseQueryError | SerializedError | undefined;
  data?: T;
  onClientError?: () => void;
  blockEmptyData?: boolean;
}>;

export const PageDataLoader = <T,>({ children, error, data, onClientError, blockEmptyData }: PageDataLoaderProps<T>) => {
  const [retried, setRetried] = useState<boolean>();

  // TODO: Cover with ts guard...
  // @ts-ignore
  const isSuppressedError = error?.status === 400 && error?.data?.error.match(/time restriction/i); // suppress time restriction errors

  // bad params autofix
  useEffect(() => {
    if (isSuppressedError) return; // skip retry suppressed errors
    if (!error) setRetried(false);
    if (!retried && onClientError && error && 'status' in error && error.status < 500 && error.status >= 400) {
      onClientError();
      setRetried(true);
    }
  }, [error, isSuppressedError, onClientError, retried]);

  if (error && !isSuppressedError) return <ErrorPage code={getErrorCode(error)} />;
  if (!data && blockEmptyData) return <SpinFullscreen />;

  return <>{children}</>;
};

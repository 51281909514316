import { isSupportSystem } from '../lib/messengerHelpers';
import { CommonMessage, CommonMessageVisibilities } from '../types/messages';

interface Props {
  message: CommonMessage;
}

const UnreadFlag = (props: { color: string }) => (
  <strong style={{ color: props.color, opacity: 1 }}>{isSupportSystem() ? '⚑' : '•'}</strong>
);

export const MessageUnreadFlag: FC<Props> = ({ message }) => (
  // NOTE: Always compare with === false, because it can be undefined
  <>
    {message?.isRead === false && <UnreadFlag color="#1890ff" />}
    {[
      CommonMessageVisibilities.Writer, // writer is recipient
      CommonMessageVisibilities.Everyone, // public message
    ].includes(message.idVisibility) &&
      message?.isReadByWriter === false && <UnreadFlag color="#32b4d0" />}
    {[
      CommonMessageVisibilities.Customer, // customer is recipient
      CommonMessageVisibilities.Everyone, // public message
    ].includes(message.idVisibility) &&
      message?.isReadByCustomer === false && <UnreadFlag color="#d768ab" />}
  </>
);

import { DateTime } from '@/components/common/DateTime';
import { FeaturePreviewCard } from '@/components/common/cards/FeaturePreviewCard';
import { t } from '@/services/translator';
import { RevisionSearchItem } from '@/types/revision';

import { RevisionStatusName } from './RevisionStatusName';
import { WriterOrderLink } from '@feature/order/writerOrder/WriterOrderLink';
import { DeadlineRemainingCountDown } from '@/components/common/DeadlineRemainingCountDown';

type RevisionCardProps = {
  item: RevisionSearchItem;
  context?: 'order';
};

export const RevisionCard: FC<RevisionCardProps> = ({ item, context }) => {
  const { id, writerName, writerOrder, dateDue, dateAdded, idStatus, magnitude } = item;

  return (
    <FeaturePreviewCard
      feature="Revision"
      id={id}
      title={writerName || `${t('revisions.revision')} ID ${id}`}
      status={<RevisionStatusName id={idStatus} tag />}
      dateAdded={dateAdded}
      details={[
        {
          title: t('order.orderId'),
          condition: context !== 'order',
          description: <WriterOrderLink id={writerOrder.id} idStatus={writerOrder.idStatus} idOrder={writerOrder.idOrder} showStatus />,
        },
        {
          title: t('revisions.magnitude'),
          condition: !!magnitude,
          description: magnitude?.writerName,
        },
        {
          title: t('misc.dateDue'),
          description: dateDue ? (
            <>
              <DateTime value={dateDue} /> / <DeadlineRemainingCountDown dueDate={dateDue} />
            </>
          ) : (
            t('misc.notAvailable')
          ),
        },
      ]}
    />
  );
};

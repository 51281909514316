import { Avatar } from 'antd';
import { useMessengerContext } from '../lib/messengerContext';
import { getDisplayName, getMessageSender } from '../lib/messengerHelpers';
import { useMessageConditions } from '../lib/messengerHooks';
import { CommonMessage } from '../types/messages';

interface Props {
  message: CommonMessage;
}

export const MessageAuthor: FC<Props> = ({ message }) => {
  const { config } = useMessengerContext();
  const { isSender } = useMessageConditions(message);
  const authorKey = getMessageSender(message);
  const icon = config?.icons[authorKey === 'author' ? 'user' : authorKey]; // todo: discuss with @stanly

  return (
    <>
      <Avatar size="small" style={{ background: 'white', boxShadow: '#e3eef8 0px 1px 2px' }} children={icon || '🥸'} />
      {isSender ? 'Me' : getDisplayName(message)}
    </>
  );
};

import { CSSProperties, ReactNode } from 'react';
import uuid from 'react-uuid';
import cn from 'classnames';
import s from './DetailsList.module.less';

export type DetailsListItem = {
  title?: ReactNode;
  condition?: boolean;
  description?: ReactNode;
  className?: string;
  muted?: boolean;
};

export type DetailsListProps = {
  inverted?: boolean;
  noBg?: boolean;
  details: DetailsListItem[];
  style?: CSSProperties;
  className?: string;
  align?: 'between' | 'left';
};

export const DetailsList: FC<DetailsListProps> = ({ details, inverted, style, noBg, align }) => (
  <ul
    className={cn(s.details, {
      [s.inverted]: inverted,
      [s.noBg]: noBg,
      [s.alignLeft]: align === 'left',
    })}
    style={style}
  >
    {details
      .filter(({ condition }) => (typeof condition === 'boolean' ? condition : true))
      .map(({ title, description, muted, className }) => (
        <li
          key={uuid()}
          className={cn(
            s.detailItem,
            {
              [s.detailItemPrimary]: !title,
              [s.muted]: muted,
            },
            className,
          )}
        >
          {title && <div className={s.detailTitle}>{title}:</div>}
          <div className={s.detailDescription}>{description}</div>
        </li>
      ))}
  </ul>
);

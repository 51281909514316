import { useViewCurrentUserProfileQuery } from '@/api/profile';
import moment from 'moment-timezone';
import { usePreferences } from '@/hooks/preferences';

// Sync differences between timezone names on back-end and front-end
export const getTimezoneName = (value: string) =>
  ({
    'Europe/Kiev': 'Europe/Kyiv',
  }[value] || value);

export const useTimezone = () => {
  const { data: profile } = useViewCurrentUserProfileQuery();
  const { preferences } = usePreferences();

  return getTimezoneName(preferences.timezone || profile?.timezone || moment.tz.guess());
};

import { Route, Routes, Navigate } from 'react-router-dom';
import { lazy } from 'react';
import { lazyRetry } from '@/components/common/errors/lazyRetry';
import { useAuth } from '@/store/auth/authHooks';
import { FEATURE } from '@/config/features';
import Dashboard from '@/components/common/Dashboard';

const ForgotPasswordPage = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "ForgotPasswordPage" */ '@/components/features/auth/ForgotPasswordPage'),
    'ForgotPasswordPage',
  ),
);

const ResetPasswordPage = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "ResetPasswordPage" */ '@/components/features/auth/ResetPasswordPage'), 'ResetPasswordPage'),
);

const SignUpPage = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "SignUpPage" */ '@/components/features/auth/SignUpPage'), 'SignUpPage'),
);

const LoginPage = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "LoginPage" */ '@/components/features/auth/LoginPage'), 'LoginPage'),
);

export const AppMainRoutes = () => {
  const { isLoggedIn } = useAuth();

  const REDIRECT_PATH = FEATURE.Profile.path;

  const AUTH_GUEST_ROUTES = [
    {
      path: '/reset-password/:magicUuid',
      Component: ResetPasswordPage,
    },
    {
      path: '/forgot-password',
      Component: ForgotPasswordPage,
    },
    {
      path: '/sign-up',
      Component: SignUpPage,
    },
  ];

  return (
    <Routes>
      {AUTH_GUEST_ROUTES.map(({ path, Component }) => (
        <Route key={path} path={path} element={isLoggedIn ? <Navigate to={REDIRECT_PATH} /> : <Component />} />
      ))}
      <Route path="*" element={isLoggedIn ? <Dashboard /> : <LoginPage />} />
    </Routes>
  );
};

import { useViewCurrentUserProfileQuery } from '@/api/profile';
import { Button } from '@/components/common/button/Button';
import { ProfileTimezoneSelect } from '@/components/common/form/ProfileTimezoneSelect';
import { WriterIcon } from '@/components/common/icons/WriterIcon';
import { Navigation } from '@/components/common/layout/Navigation';
import { Logo } from '@/components/common/logo/Logo';
import { APP_VERSION } from '@/config/constants';
import { PinnedTicketMessenger } from '@feature/messenger/PinnedTicketMessenger';
import { UnreadTicketMessagesCounter } from '@feature/ticket/message/inbox/UnreadTicketMessagesCounter';
import { Drawer, Space } from 'antd';
import { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import { FA } from '../icons/FA';
import cn from 'classnames';
import { t } from '@/services/translator';

// Styles
import s from './AppBaseLayout.module.less';
import { NotificationPopover } from '@feature/notification/NotificationPopover';
import { NotificationButton } from '@feature/notification/NotificationButton';
import { FeatureLink } from '@/components/common/FeatureLink';
import { WriterStatuses } from '@/types/profile';
import { usePreferences } from '@/hooks/preferences';
import { WinSm, WinSmMin } from '@/hooks/breakpoints/winSizes';
import { LogoutPopover } from '@/components/common/layout/LogoutPopover';
import { useAppContext } from '@/components/AppContext';
import { useAppDispatch } from '@/hooks/redux';
import { resetSearchPagination } from '@/store/localPreferences/localPreferenceSlice';

export type MenuTooltipParams = {
  visible: boolean;
  top: number;
  left: number;
  content: string;
};

export const AppBaseLayout: FC = ({ children }): ReactElement => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [menuToolipParams, setMenuTooltipParams] = useState<MenuTooltipParams | null>(null);

  const { preferences, updatePreferences } = usePreferences();

  const { isFullscreenMode } = useAppContext();

  const { avatar, isCompactNavigation } = preferences;

  const toggleCompactNavigation = (value: boolean) => updatePreferences({ isCompactNavigation: value }); // save

  const { data: profile } = useViewCurrentUserProfileQuery();

  const isNew = profile?.idStatus === WriterStatuses.New;

  const dispatch = useAppDispatch();

  return (
    <>
      {menuToolipParams && (
        <div
          style={{
            top: menuToolipParams.top,
            left: menuToolipParams.left,
          }}
          className={cn(s.menuTooltip, {
            [s.menuTooltipVisible]: menuToolipParams.visible,
          })}
        >
          {menuToolipParams.content}
        </div>
      )}
      <WinSm>
        <Drawer
          width={320}
          title={
            <div className={s.drawerTitle}>
              <FeatureLink feature="Profile" onClick={() => setDrawerVisible(false)}>
                <span className={s.avatar}>
                  <span className={s.avatarWrap}>{avatar ? <img src={avatar} className={s.avatarImage} alt="" /> : <WriterIcon />}</span>

                  <span className={s.drawerAvatarUserName}>
                    <span>{profile?.publicName || `${profile?.firstName} ${profile?.lastName}`}</span>
                    <span>{profile?.id}</span>
                  </span>
                </span>
              </FeatureLink>
            </div>
          }
          closeIcon={<FA type="fa-xmark" />}
          placement="left"
          visible={drawerVisible}
          onClose={() => setDrawerVisible(false)}
          className={s.drawer}
        >
          <Navigation setDrawerVisible={setDrawerVisible} onCollapsedMenuItemHover={setMenuTooltipParams} />
        </Drawer>
      </WinSm>

      <div className={cn(s.wrapper, { [s.compact]: isCompactNavigation, [s.newUser]: isNew, [s.fullScreen]: isFullscreenMode })}>
        <header className={s.header}>
          <div className={s.headerWrap}>
            <FeatureLink
              className={s.logo}
              feature={isNew ? 'Profile' : 'WorkSummary'}
              onClick={() => {
                dispatch(resetSearchPagination());
              }}
            >
              <Logo className={s.logoImage} />
            </FeatureLink>
            <WinSm>
              <ProfileTimezoneSelect />
            </WinSm>
          </div>

          <WinSmMin>
            <div className={s.headerGroup}>
              <ProfileTimezoneSelect />
              <UnreadTicketMessagesCounter />
              {!isNew && <NotificationPopover />}
              <Link to="/">
                <span className={s.avatar}>
                  <span className={s.avatarWrap}>{avatar ? <img src={avatar} className={s.avatarImage} alt="" /> : <WriterIcon />}</span>

                  {profile?.firstName}
                </span>
              </Link>

              <LogoutPopover />
            </div>

            {!isFullscreenMode && (
              <Button
                onClick={() => toggleCompactNavigation(!isCompactNavigation)}
                className={cn(s.toggleNavigation, { [s.toggleNavigationCompact]: isCompactNavigation })}
                icon={isCompactNavigation ? 'fa-chevron-right' : 'fa-chevron-left'}
              >
                {t(isCompactNavigation ? 'misc.expand' : 'misc.collapse')}
              </Button>
            )}
          </WinSmMin>
          <WinSm>
            <Space>
              <UnreadTicketMessagesCounter />
              {!isNew && (
                <FeatureLink feature="Notifications">
                  <NotificationButton />
                </FeatureLink>
              )}
              <Button icon="fa-bars" type="text" onClick={() => setDrawerVisible(true)} />
            </Space>
          </WinSm>
        </header>

        {!isFullscreenMode && (
          <WinSmMin>
            <nav className={s.left}>
              <ul className={s.navigation}>
                <Navigation setDrawerVisible={setDrawerVisible} onCollapsedMenuItemHover={setMenuTooltipParams} />
              </ul>
            </nav>
          </WinSmMin>
        )}

        <div className={s.main}>
          <main className={s.content}>{children}</main>

          <footer id="bottom" className={s.footer}>
            <span className={s.copyright}>CoreProject • Writer • v{APP_VERSION}</span>
          </footer>
        </div>
      </div>

      <PinnedTicketMessenger />
    </>
  );
};

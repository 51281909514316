import { GeneralSearchParams, LocalSearchEngineProps, CoreSearchEngine } from '@/shared/components/searchEngine';
import { BadgeCounter } from '@/components/common/BadgeCounter';
import { FA } from '@/components/common/icons/FA';
import { getColor } from '@/helpers/utils';
import { t } from '@/services/translator';
import { views } from '@/components/search/config';
import '@/shared/components/searchEngine/index.less';

import s from './SearchEngine.module.less';
import { useAppDispatch } from '@/hooks/redux';
import { addSearchPreference } from '@/store/localPreferences/localPreferenceActions';
import { useCallback } from 'react';
import { useBreakpoints } from '@/hooks/breakpoints/useBreakpoints';

type SearchEngineProps<T extends GeneralSearchParams> = LocalSearchEngineProps<T>;

export const SearchEngine = <T extends GeneralSearchParams>(props: SearchEngineProps<T>) => {
  const { isLg } = useBreakpoints();
  const dispatch = useAppDispatch();

  const handleSearchParamsChange = useCallback(
    (id, value) => {
      dispatch(
        addSearchPreference({
          key: id,
          value,
        }),
      );
    },
    [dispatch],
  );

  return (
    <CoreSearchEngine
      className={s.wrapper}
      counterComponent={BadgeCounter}
      labels={{
        filters: t('misc.filters'),
        close: t('misc.close'),
        search: t('searchEngine.search'),
        totalItems: (opts) => t('searchEngine.totalItems', { count: opts?.count }),
        resetToDefaults: t('searchEngine.resetToDefaults'),
        clearAllFilters: t('searchEngine.clearAllFilters'),
        sortPreferences: t('searchEngine.sortPreferences'),
        sortOrder: t('searchEngine.order'),
        sortParams: (options) => t(`misc.${options?.name}`),
        dateParams: (options) => t(`misc.${options?.name}`),
        by: t('searchEngine.by'),
        from: t('searchEngine.from'),
        to: t('searchEngine.to'),
        asc: t('searchEngine.asc'),
        desc: t('searchEngine.desc'),
      }}
      icons={{
        filter: <FA type="fa-filter" theme="far" />,
        resetFilterMenu: <FA type="fa-ellipsis-v" theme="far" />,
        resetToDefaults: <FA theme="fas" type="fa-filter info" color={getColor('info')} />,
        clearFilters: <FA theme="fas" color={getColor('danger')} type={`${isLg ? 'fa-times' : 'fa-times-circle'} warning`} />,
        sortAsc: <FA type="fa-sort-amount-up" theme="far" />,
        sortDesc: <FA type="fa-sort-amount-down" theme="far" />,
        sortAscAlt: <FA type="fa-sort-amount-up-alt" theme="far" />,
        sortDescAlt: <FA type="fa-sort-amount-down-alt" theme="far" />,
        dateRange: <FA type="fa-calendar" theme="far" />,
        closeDatePicker: <FA type="fa-times" />,
        showExtendedControls: null,
        hideExtendedControls: null,
      }}
      layout={isLg ? 'mobile' : 'desktop'}
      views={views}
      onSearchParamsChange={handleSearchParamsChange}
      {...props}
    />
  );
};

import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@/store/store';

type BreadcrumbsStateType = {
  isWatchingNotifications: boolean;
};

const initialState: BreadcrumbsStateType = {
  isWatchingNotifications: false,
};

export const notifications = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setWatchingNotifications(
      state,
      {
        payload,
      }: {
        payload: boolean;
      },
    ) {
      return { isWatchingNotifications: payload };
    },
  },
});

export const { setWatchingNotifications } = notifications.actions;

export default notifications.reducer;

export const isWatchingNotificationsSelector = ({ notifications }: RootState): boolean => notifications.isWatchingNotifications;

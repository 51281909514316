import cn from 'classnames';
import { Property } from 'csstype';
import { CSSProperties } from 'react';

// Styles
import s from './SpaceBetween.module.less';

interface Props {
  style?: CSSProperties;
  className?: string;
  align?: Property.AlignItems;
}

export const SpaceBetween: FC<Props> = ({ className, align, style, children }) => (
  <div style={{ alignItems: align, ...style }} className={cn(s.wrapper, className)}>
    {children}
  </div>
);

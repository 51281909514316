import { RichText } from '@/shared/components/RichText';
import { MessageUnreadFlag } from '@/shared/features/messenger/ui/MessageUnreadFlag';
import cn from 'classnames';
import { useEffect, useRef } from 'react';
import { useMessengerContext } from '../lib/messengerContext';
import { useMessageConditions } from '../lib/messengerHooks';
import { CommonMessage, CommonMessageStatuses } from '../types/messages';
import { EditMessageButton } from './EditMessageButton';
import { MessageHeader } from './MessageHeader';
import s from './MessageItem.module.less';
import { MessageItemActionsPopover } from './MessageItemActionsPopover';
import { ReplyMessageButton } from './ReplyMessageButton';

interface MessageItemProps {
  message: CommonMessage;
  showAuthor?: boolean;
  showTime?: boolean;
  className?: string;
}

/**
 * Render message item
 */
export const MessageItem: FC<MessageItemProps> = ({ message, showTime, showAuthor, className, children }) => {
  const { canReply, canEdit, isSender, isEmoji } = useMessageConditions(message);
  const { view, config, renderMessage, editingEnabled, hoverActionsAvailable, isInteractive, setMobileClickedMessage, observer } =
    useMessengerContext(); // common context with form

  const messageRef = useRef<HTMLDivElement>(null); // message element ref for observer

  // Observe message visibility -> mark as read
  useEffect(() => {
    if (!observer) return; // no observer from context | skip for support system

    const el = messageRef.current;
    const shouldObserve = el && !isSender && !message.isRead; // observe only unread messages from others

    if (!shouldObserve) return;

    // NOTE: Check useMessengerObserver.ts for more details
    observer.observe(el); // observe message

    return () => observer.unobserve(el);
  }, [isSender, message.isRead, observer]);

  if (!message || !view) return null;

  // Shorten conditions
  const isUnchecked = message.idStatus === CommonMessageStatuses.Unchecked;
  const isPosted = message.idStatus === CommonMessageStatuses.Posted;

  const text = message.text || '⚠️';

  const status = config?.defineStatusIconVisibility
    ? config.defineStatusIconVisibility(!!hoverActionsAvailable, message.idStatus) && config?.icons[message.idStatus]
    : config?.icons[message.idStatus];

  const onMessageClick = () => {
    if (hoverActionsAvailable) return;
    setMobileClickedMessage?.(message);
  };

  return (
    <>
      <div
        ref={messageRef}
        id={`msg-id-${message.id}`}
        data-id={message.id} // pass id for observer
        className={cn(s.messageItem, className, s[`status-${message.idStatus}`], s[isSender ? 'isSender' : 'isRecipient'], {
          [s.sticker]: isEmoji,
        })}
      >
        {/* AUTHOR */}
        {(showAuthor || showTime) && <MessageHeader message={message} showTime={showTime} />}

        {/* MESSAGE */}
        <div className={s.main}>
          <div className={s.balloon} onClick={onMessageClick}>
            <RichText text={text} />
            {hoverActionsAvailable && renderMessage?.text?.(message, hoverActionsAvailable)}
          </div>

          {/* STATUS & VISIBILITY */}
          <div className={s.status}>{status}</div>

          {renderMessage?.status?.(message)}

          <MessageUnreadFlag message={message} />

          <div className={s.actions}>
            {renderMessage?.extra?.(message, hoverActionsAvailable)}
            {isInteractive && hoverActionsAvailable && (
              <>
                {/* EDIT MODE */}
                {editingEnabled && canEdit && <EditMessageButton message={message} hover={hoverActionsAvailable} />}
                {/* MESSAGE MAIN ACTIONS */}
                {(isPosted || isUnchecked) && renderMessage?.actions && (
                  <MessageItemActionsPopover
                    isPosted={isPosted}
                    icon={config?.icons.actions}
                    actions={renderMessage?.actions(message, hoverActionsAvailable)}
                  />
                )}

                {/* REPLY MODE */}
                {canReply && <ReplyMessageButton message={message} />}
              </>
            )}
          </div>
        </div>
      </div>
      {children}
    </>
  );
};

import { TicketTopLevelMessage } from './../types/ticketMessage';
import {
  TicketCreateRequest,
  TicketUpdateRequest,
  TicketUpdateStatusRequest,
  TicketCreateMessageRequest,
  TicketUpdateFilesRequest,
} from '@/types/ticket';
import { API_PATH } from '@/config/features';
import { emptyApi as api, getTags } from '@/store/emptyApi';
import { Ticket, TicketDetails, TicketSearchItem, TicketSearchParams } from '@/types/ticket';
import { SearchView } from '@/types/search';
import { TicketCustomerFileAssociation } from '@/types/file/customerFile';
import { TicketUserFileAssociation } from '@/types/file/userFile';
import { TicketOwnFileAssociation } from '@/types/file/ownFile';

// Entity
const type = 'Ticket';
const path = API_PATH[type];

const { providesTags, invalidatesTags } = getTags(type);

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    searchTickets: build.query<SearchView<TicketSearchItem>, TicketSearchParams>({
      query: (params) => ({ url: `${path}`, params }),
      providesTags: [{ type, id: 'search' }],
    }),
    getTicket: build.query<TicketDetails, number>({
      query: (id) => ({ url: `${path}/${id}` }),
      providesTags,
    }),
    createTicket: build.mutation<Ticket, TicketCreateRequest>({
      query: (body) => ({ url: `${path}`, method: 'POST', body }),
      invalidatesTags,
    }),
    updateTicket: build.mutation<void, TicketUpdateRequest>({
      query: ({ id, body }) => ({ url: `${path}/${id}`, method: 'PUT', body }),
      invalidatesTags,
    }),
    resolveTicket: build.mutation<void, TicketUpdateStatusRequest>({
      query: ({ id, body }) => ({ url: `${path}/${id}/resolve`, method: 'PATCH', body }),
      invalidatesTags,
    }),
    withdrawTicket: build.mutation<void, TicketUpdateStatusRequest>({
      query: ({ id, body }) => ({ url: `${path}/${id}/withdraw`, method: 'PATCH', body }),
      invalidatesTags,
    }),
    searchTicketAMessages: build.query<TicketTopLevelMessage[], number>({
      query: (id) => ({ url: `${path}/${id}/messages` }),
      providesTags: (result, error, id) => [{ type, id: 'messages' + id }],
    }),
    createTicketAMessage: build.mutation<TicketTopLevelMessage, TicketCreateMessageRequest>({
      query: ({ id, body }) => ({ url: `${path}/${id}/messages`, method: 'POST', body }),
      invalidatesTags: (result, error, { id }) => [{ type, id: 'messages' + id }],
    }),
    searchTicketACustomerFiles: build.query<TicketCustomerFileAssociation[], number>({
      query: (id) => ({ url: `${path}/${id}/a/customer-files` }),
      providesTags: (result, error, id) => [{ type, id: 'customerFiles' + id }],
    }),
    searchTicketAUserFiles: build.query<TicketUserFileAssociation[], number>({
      query: (id) => ({ url: `${path}/${id}/a/user-files` }),
      providesTags: (result, error, id) => [{ type, id: 'userFiles' + id }],
    }),
    searchTicketAWriterFiles: build.query<TicketOwnFileAssociation[], number>({
      query: (id) => ({ url: `${path}/${id}/a/writer-files` }),
      providesTags: (result, error, id) => [{ type, id: 'writerFiles' + id }],
    }),
    claimTicketAWriterFiles: build.mutation<TicketOwnFileAssociation[], TicketUpdateFilesRequest>({
      query: ({ id, body }) => ({ url: `${path}/${id}/a/writer-files/claim`, method: 'PUT', body }),
      invalidatesTags: (result, error, { id }) => [{ type, id: 'writerFiles' + id }],
    }),
    withdrawTicketAWriterFiles: build.mutation<TicketOwnFileAssociation[], TicketUpdateFilesRequest>({
      query: ({ id, body }) => ({ url: `${path}/${id}/a/writer-files/withdraw`, method: 'DELETE', body }),
      invalidatesTags: (result, error, { id }) => [{ type, id: 'writerFiles' + id }],
    }),
  }),
  overrideExisting: false,
});

export { injectedRtkApi as enhancedApi };

export const {
  useSearchTicketsQuery,
  useLazySearchTicketsQuery,
  useGetTicketQuery,
  useCreateTicketMutation,
  useUpdateTicketMutation,
  useResolveTicketMutation,
  useWithdrawTicketMutation,
  useSearchTicketAMessagesQuery,
  useLazySearchTicketAMessagesQuery,
  useCreateTicketAMessageMutation,
  useSearchTicketACustomerFilesQuery,
  useLazySearchTicketACustomerFilesQuery,
  useSearchTicketAUserFilesQuery,
  useLazySearchTicketAUserFilesQuery,
  useSearchTicketAWriterFilesQuery,
  useLazySearchTicketAWriterFilesQuery,
  useClaimTicketAWriterFilesMutation,
  useWithdrawTicketAWriterFilesMutation,
} = injectedRtkApi;

import { FA } from '@/components/common/icons/FA';
import { getColor } from '@/helpers/utils';
import { t } from '@/services/translator';
import { Select, Space } from 'antd';
import { SelectProps } from 'antd/lib/select';
import moment from 'moment-timezone';
import { useEffect, useState, MouseEvent } from 'react';
import { PopoverResponsive } from '../PopoverResponsive';

// Styles
import s from './ProfileTimezoneSelect.module.less';
import { useClock } from '@/hooks/clock';
import { PRIMARY_TIME_ZONES, TIMEZONES } from '@/config/constants';
import { isEqual } from 'lodash';
import { usePreferences } from '@/hooks/preferences';
import { WinSmMin } from '@/hooks/breakpoints/winSizes';
import { useTimezone, getTimezoneName } from '@/hooks/timezone';

const { Option } = Select;

interface IProps extends SelectProps {
  onChange?: (value: string) => void;
}

const primaryTimezoneNames = PRIMARY_TIME_ZONES.filter((name) => TIMEZONES.includes(name));

export const ProfileTimezoneSelect: FC<IProps> = ({ onChange, ...props }) => {
  const { updatePreferences } = usePreferences();
  const timezone = useTimezone();

  const [selectVisible, setSelectVisible] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);

  const initialTimezonesList =
    timezone && !primaryTimezoneNames.includes(timezone) && TIMEZONES.includes(timezone)
      ? [timezone, ...primaryTimezoneNames]
      : primaryTimezoneNames;

  const [availableTimezones, setAvailableTimezones] = useState(initialTimezonesList);
  // Clock
  const { dateTime } = useClock({ updateInterval: 1000 });

  const triggerChange = async (value) => {
    moment.tz.setDefault(value); // important!
    updatePreferences({ timezone: value });

    onChange?.(value);
  };

  useEffect(() => {
    moment.tz.setDefault(timezone); // important!
  }, [timezone]);

  const handleTimezoneSwitch = (event: MouseEvent, showAll: boolean) => {
    setAvailableTimezones(showAll ? TIMEZONES : initialTimezonesList);
    setSelectVisible(true);
  };

  const initialView = isEqual(availableTimezones, initialTimezonesList);

  return (
    <div className={s.wrapper}>
      <PopoverResponsive
        trigger="click"
        open={popoverVisible}
        title={
          timezone ? (
            <>
              {t('misc.selectedTimezone')} <strong>{timezone}</strong>
            </>
          ) : (
            <>{t('misc.selectTimezone')}</>
          )
        }
        content={
          <>
            <div style={{ marginBottom: 4 }}>{dateTime.format('LLL z')}</div>
            <div style={{ marginBottom: 4 }}>
              <span
                style={{
                  color: getColor('muted'),
                  cursor: 'pointer',
                  fontSize: 12,
                }}
                onClick={(event) => handleTimezoneSwitch(event, initialView)}
              >
                {t(initialView ? 'profile.showAllTimezones' : 'profile.showPrimaryTimezones')}
              </span>
            </div>
            <Select
              placeholder={t('misc.selectTimezone')}
              showArrow
              showSearch
              style={{ minWidth: '100%' }}
              open={selectVisible}
              onChange={triggerChange}
              onDropdownVisibleChange={setSelectVisible}
              value={timezone}
              {...props}
            >
              {availableTimezones.map((tz) => (
                <Option key={tz} value={tz}>
                  {getTimezoneName(tz)}
                </Option>
              ))}
            </Select>
          </>
        }
        onOpenChange={(visible) => {
          if (!visible) {
            setSelectVisible(false);
          }
          setPopoverVisible(visible);
        }}
        destroyTooltipOnHide
      >
        {dateTime ? (
          <Space className={s.clock}>
            <WinSmMin>
              <FA type="fa-clock" theme="far" color={getColor('primary')} />
            </WinSmMin>
            <span>
              <strong>{dateTime.format('HH:mm:ss')}</strong>
              {dateTime.format(' z')}
            </span>
            <WinSmMin>
              <FA type={selectVisible ? 'fa-chevron-up' : 'fa-chevron-down'} theme="fas" />
            </WinSmMin>
          </Space>
        ) : (
          t('misc.selectTimezone')
        )}
      </PopoverResponsive>
    </div>
  );
};

import { useMessengerContext } from '@/shared/features/messenger/lib/messengerContext';
import { CommonMessage } from '@/shared/features/messenger/types/messages';
import { Button, Tooltip } from 'antd';

type ReplyMessageButtonProps = {
  message: CommonMessage;
};
export const ReplyMessageButton = ({ message }: ReplyMessageButtonProps) => {
  const { config, setParentMessage, setEditedMessage, setMobileClickedMessage, hoverActionsAvailable } = useMessengerContext();
  const handleReply = () => {
    setParentMessage?.(message);
    setEditedMessage?.(undefined);
    setMobileClickedMessage?.(undefined);
  };
  return hoverActionsAvailable ? (
    <Tooltip title="Reply">
      <Button type="link" icon={config?.icons.reply} size="small" style={{ minWidth: 24 }} onClick={handleReply} />
    </Tooltip>
  ) : (
    <Button
      icon={config?.icons.reply}
      onClick={handleReply}
      style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <span style={{ marginLeft: 4 }}>Reply</span>
    </Button>
  );
};

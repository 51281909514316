import { FA } from '@/components/common/icons/FA';

import { Modal as AntModal, ModalProps } from 'antd';
import cn from 'classnames';

// Styles
import s from './Modal.module.less';

interface IProps extends ModalProps {}

export const Modal: FC<IProps> = ({ className, ...props }) => (
  <AntModal className={cn(s.wrapper, className)} footer={null} destroyOnClose closeIcon={<FA type="fa-times" />} width={450} {...props} />
);

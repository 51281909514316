import { MutationTriggerButton } from '@/components/common/button/MutationTriggerButton';
import { useRetractTicketMessageMutation } from '@/shared/features/messenger/api/messenger';
import { useMessengerContext } from '@/shared/features/messenger/lib/messengerContext';
import { useMessageConditions } from '@/shared/features/messenger/lib/messengerHooks';
import { CommonMessage, CommonMessageStatuses } from '@/shared/features/messenger/types/messages';
import { useId } from '@/shared/hooks/useId';

interface Props {
  message: CommonMessage;
}

export const RetractTicketMessage: FC<Props> = ({ message }) => {
  const idTicket = useId();
  const { hoverActionsAvailable, setMobileClickedMessage } = useMessengerContext();
  const { canRetract } = useMessageConditions(message);

  // TODO: Clarify
  // For WS & CS retract only unchecked messages (synced with backend conditions)
  if (!canRetract || message.idStatus !== CommonMessageStatuses.Unchecked) return null;

  return (
    <MutationTriggerButton
      payload={{ id: message.id, idTicket }}
      name="Retract"
      icon="fa-message-slash"
      // condition={canRetract}
      hook={useRetractTicketMessageMutation}
      style={
        hoverActionsAvailable
          ? { border: 'none', background: 'none' }
          : {
              width: '100%',
              minHeight: 32,
            }
      }
      onFinish={() => {
        setMobileClickedMessage?.(undefined);
      }}
    >
      {!hoverActionsAvailable && 'Retract'}
    </MutationTriggerButton>
  );
};

import { API_PATH } from '@/config/features';
import { emptyApi as api, getTags } from '@/store/emptyApi';
import { SearchView } from '@/types/search';
import {
  BroadcastMessage,
  BroadcastMessageDetails,
  BroadcastMessageMarkAsReadRequest,
  BroadcastMessageSearchItem,
  BroadcastMessageSearchParams,
} from '@/types/broadcastMessage';

// Entity
const type = 'BroadcastMessage';
const path = API_PATH[type];

const { providesTags, invalidatesTags } = getTags(type);

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    searchBroadcastMessages: build.query<SearchView<BroadcastMessageSearchItem>, BroadcastMessageSearchParams>({
      query: (params) => ({ url: `${path}`, params }),
      providesTags: () => [{ type, id: 'search' }],
    }),
    getBroadcastMessage: build.query<BroadcastMessageDetails, number>({
      query: (id) => ({ url: `${path}/${id}` }),
      providesTags,
    }),
    markBroadcastMessagesAsRead: build.mutation<BroadcastMessage[], BroadcastMessageMarkAsReadRequest>({
      query: (body) => ({ url: `${path}/mark-as-read`, method: 'PUT', body }),
      invalidatesTags,
      extraOptions: { customErrorHandler: true },
    }),
  }),
  overrideExisting: false,
});

export { injectedRtkApi as enhancedApi };

export const { useSearchBroadcastMessagesQuery, useLazyGetBroadcastMessageQuery, useMarkBroadcastMessagesAsReadMutation } = injectedRtkApi;

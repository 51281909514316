import { FA } from '@/components/common/icons/FA';
import { BadgeCounter } from '@/shared/components/BadgeCounter';
import { useGetTicketMessagesUnreadCountQuery } from '@/shared/features/messenger/api/messenger';
import { useProfile } from '@/shared/hooks/useProfile';
import { useSmartPolling } from '@/shared/hooks/useSmartPolling';
import { WriterStatuses } from '@/types/profile';
import { Link } from 'react-router-dom';

interface Props {
  showCount?: boolean;
}

export const UnreadTicketMessagesCounter: FC<Props> = ({ showCount = true }) => {
  const { idStatus } = useProfile();
  const isNew = idStatus === WriterStatuses.New;
  const { pollingInterval } = useSmartPolling(30);
  const { data: unreadCount } = useGetTicketMessagesUnreadCountQuery(undefined, {
    skip: !showCount || isNew,
    pollingInterval, // refresh every 30 seconds only when browser tab is active
  });

  if (isNew) return null; // don't show counter for new writers

  return (
    <Link to="/inbox" style={{ color: 'inherit' }}>
      <BadgeCounter count={unreadCount} type="alert" overflowCount={999}>
        <FA type="fa-messages" theme="far" />
      </BadgeCounter>
    </Link>
  );
};

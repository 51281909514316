import { IWriter } from '@/shared/types/participants';
import { RootState } from '@/store/store';
import { ITokenPair } from '@/types/common/auth';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Email } from '@/types/email';
import { Phone } from '@/types/phone';

type AuthUser = IWriter & {
  primaryEmail?: Email;
  primaryPhone?: Phone;
};
export interface AuthState {
  isLoggedIn: boolean;
  tokens: ITokenPair;
  user: AuthUser;
}

export const authInitialState: AuthState = {
  isLoggedIn: false,
  tokens: {
    refreshToken: '',
    accessToken: '',
  },
  user: {
    id: 0,
    firstName: '',
    lastName: '',
    name: '',
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    setUser: (state: AuthState, { payload }: PayloadAction<AuthUser>) => {
      state.user = payload;
    },
    setTokens: (state: AuthState, { payload }: PayloadAction<ITokenPair>) => {
      state.tokens = payload;
      state.isLoggedIn = true;
    },
    refreshAccessToken: (state: AuthState, { payload }: PayloadAction<{ accessToken: string }>) => {
      state.tokens.accessToken = payload.accessToken;
    },
    logout: () => authInitialState,
  },
});

export default authSlice.reducer;

export const { setTokens, refreshAccessToken, logout, setUser } = authSlice.actions;

// Selectors
export const selectAuth = ({ auth }: RootState) => auth;
export const selectIsLoggedIn = ({ auth }: RootState) => auth.isLoggedIn;
export const selectUserId = ({ auth }: RootState) => auth.user.id;

export const tokensSelector = ({ auth }: RootState) => auth.tokens;

import { DateTime } from '@/components/common/DateTime';
import { Table, useMemoizedColumns } from '@/components/common/Table';
import { t } from '@/services/translator';
import { TableProps } from 'antd';
import { WriterOrderLink } from '@feature/order/writerOrder/WriterOrderLink';
import { RevisionSearchItem } from '@/types/revision';
import { RevisionStatusName } from '@feature/revision/RevisionStatusName';
import { FeatureLink } from '@/components/common/FeatureLink';

type RevisionsTableProps = TableProps<RevisionSearchItem> & {
  items?: RevisionSearchItem[];
};

export const RevisionsTable = ({ items = [], loading, ...props }: RevisionsTableProps) => {
  const columns = useMemoizedColumns<RevisionSearchItem>([
    {
      title: t('misc.status'),
      ellipsis: true,
      render: (_, { idStatus }) => <RevisionStatusName id={idStatus} tag />,
    },
    {
      title: t('revisions.revision'),
      ellipsis: true,
      render: (_, { id, writerName }) => (
        <FeatureLink feature="Revision" id={id}>
          {writerName}
        </FeatureLink>
      ),
    },
    {
      title: t('order.orderId'),
      ellipsis: true,
      render: (_, { writerOrder, order }) => {
        return <WriterOrderLink id={writerOrder.id} idOrder={order.id} idStatus={writerOrder.idStatus} showStatus />;
      },
    },
    {
      title: t('revisions.magnitude'),
      ellipsis: true,
      render: (_, { magnitude }) => magnitude.writerName,
    },
    {
      title: t('misc.deadline'),
      ellipsis: true,
      render: (_, { dateDue }) => {
        return dateDue ? <DateTime value={dateDue} /> : t('misc.notAvailable');
      },
    },
  ]);

  return <Table dataSource={items} columns={columns} loading={loading} {...props} />;
};

import { useMessengerContext } from '@/shared/features/messenger/lib/messengerContext';
import { CommonMessage } from '@/shared/features/messenger/types/messages';
import { Button } from 'antd';

type EditMessageButtonProps = {
  message: CommonMessage;
  hover?: boolean;
};

export const EditMessageButton = ({ message, hover }: EditMessageButtonProps) => {
  const { setEditedMessage, setParentMessage, setMobileClickedMessage, config } = useMessengerContext();
  const handleClick = () => {
    setParentMessage?.(undefined);
    setEditedMessage?.(message);
    setMobileClickedMessage?.(undefined);
  };

  return hover ? (
    <Button type="text" icon={config?.icons.edit} size="small" style={{ minWidth: 24 }} onClick={handleClick} />
  ) : (
    <Button icon={config?.icons.edit} onClick={handleClick} style={{ width: '100%' }}>
      <span style={{ marginLeft: 4 }}>Edit message</span>
    </Button>
  );
};

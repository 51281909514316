import s from './DeadlineProgressBar.module.less';

type DeadlineProgressBarProps = {
  percentageValue: number;
};
export const DeadlineProgressBar = ({ percentageValue }: DeadlineProgressBarProps) => {
  const getBackgroundColor = () => {
    if (percentageValue >= 71) {
      return '#0078F0';
    } else if (percentageValue >= 51) {
      return '#78CD62';
    } else if (percentageValue >= 25) {
      return '#fd0';
    } else if (percentageValue >= 0.01) {
      return '#FFC53D';
    }
    return '#FF4D4F';
  };

  return (
    <div className={s.wrapper}>
      <div
        className={s.inner}
        style={{
          borderRadius: 10,
          width: `${percentageValue <= 0 ? 100 : percentageValue.toFixed(1)}%`,
          backgroundColor: getBackgroundColor(),
        }}
      />
    </div>
  );
};

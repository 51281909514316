import { useAuthLoginMutation } from '@/api/auth';
import { useAppDispatch } from '@/hooks/redux';
import { authLogout } from '@/store/auth/authActions';
import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SpinFullscreen } from './spinner/SpinFullscreen';
import { emptyApi } from '@/store/emptyApi';

export const MagicLogin = () => {
  const [loginFn] = useAuthLoginMutation();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const magicUuid = String(useParams().magicUuid);

  const login = useCallback(async () => {
    dispatch(authLogout(emptyApi));
    try {
      await loginFn({
        magicUuid,
      }).unwrap();
    } catch (err) {
      //
    }

    navigate('/');
  }, [magicUuid, loginFn, navigate, dispatch]);

  useEffect(() => {
    login();
  }, [login]);

  return <SpinFullscreen />;
};

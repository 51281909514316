import { useGracefulLogout } from '@/store/auth/authHooks';
import { Popover, Space } from 'antd';
import { Button } from '@/components/common/button/Button';
import { t } from '@/services/translator';

export const LogoutPopover = () => {
  const { logout } = useGracefulLogout();

  return (
    <Popover
      trigger="click"
      destroyTooltipOnHide
      placement="bottomRight"
      content={
        <Space direction="vertical" style={{ width: '100%' }}>
          <Button type="text" onClick={() => logout()} style={{ width: '100%', textAlign: 'left' }}>
            {t('profile.logOut')}
          </Button>
          <Button type="text" onClick={() => logout(true)}>
            {t('profile.logOutGlobal')}
          </Button>
        </Space>
      }
    >
      <Button icon="fa-sign-out-alt" size="small" type="text" />
    </Popover>
  );
};

import { FeatureLink, FeatureLinkProps } from '@/components/common/FeatureLink';
import { WriterOrderStatuses } from '@/types/writerOrder';
import { WriterOrderStatusName } from '@feature/order/writerOrder/WriterOrderStatusName';
import { Space } from 'antd';
import { getOrderFeature } from '@/hooks/orders/getOrderFeature';

type WriterOrderLinkProps = {
  id: number;
  idOrder: number;
  idStatus: WriterOrderStatuses;
  showStatus?: boolean;
} & Omit<FeatureLinkProps, 'feature' | 'id'>;

export const WriterOrderLink = ({ id, idOrder, idStatus, showStatus, children, ...props }: WriterOrderLinkProps) => {
  return showStatus ? (
    <Space style={{ position: 'relative', zIndex: 1 }}>
      <WriterOrderStatusName id={idStatus} dot />
      <WriterOrderLink id={id} idStatus={idStatus} idOrder={idOrder} />
    </Space>
  ) : (
    <FeatureLink feature={getOrderFeature(idStatus)} id={id} {...props}>
      {children || idOrder}
    </FeatureLink>
  );
};

import { getPreferencesWithoutPages, SearchDictInterface } from '@/shared/components/searchEngine';
import { RootState } from '@/store/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocalPreferences } from '@/types/search';

export const defaultPreferences: LocalPreferences = {
  search: {},
};

const localPreferenceSlice = createSlice({
  name: 'localPreferences',
  initialState: defaultPreferences,
  reducers: {
    setSearchPreferences: (state: LocalPreferences, { payload }: PayloadAction<SearchDictInterface>) => {
      state.search = payload;
    },
    resetSearchPagination: (state: LocalPreferences) => getPreferencesWithoutPages(state),
    setLocalPreferences(state: LocalPreferences, { payload }: PayloadAction<LocalPreferences>) {
      return payload;
    },
    resetPreferences: () => defaultPreferences,
  },
});

export const { setSearchPreferences, resetPreferences, setLocalPreferences, resetSearchPagination } = localPreferenceSlice.actions;

export const searchPreferencesSelector = ({ localPreferences }: RootState): SearchDictInterface | undefined => localPreferences.search;

export default localPreferenceSlice.reducer;

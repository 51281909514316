import { BroadcastMessage } from '@/types/broadcastMessage';
import { Card, Space } from 'antd';
import { BroadcastMessageCategoryName } from '@feature/broadcastMessage/BroadcastMessageCategoryName';
import { BroadcastMessageHistoricTag } from '@feature/broadcastMessage/BroadcastMessageHistoricTag';
import { getColor } from '@/helpers/utils';

type BroadcastMessageRelatedPreviewProps = {
  broadcastMessage: BroadcastMessage;
  onClick: (id: BroadcastMessage) => void;
};

export const BroadcastMessageRelatedPreview = ({ broadcastMessage, onClick }: BroadcastMessageRelatedPreviewProps) => (
  <Card bordered size="small" hoverable onClick={() => onClick(broadcastMessage)}>
    <div
      style={{
        marginTop: -4,
        marginBottom: 8,
        fontSize: 13,
        lineHeight: '18px',
        fontWeight: 500,
        color: getColor('link'),
      }}
    >
      {broadcastMessage.writerName}
    </div>
    <Space style={{ marginBottom: -4, width: '100%', justifyContent: 'space-between' }}>
      <BroadcastMessageCategoryName id={broadcastMessage.idCategory} tag />
      {broadcastMessage.isHistoric && <BroadcastMessageHistoricTag />}
    </Space>
  </Card>
);

import { Button, Divider, Popover, Space } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useMessengerContext } from '../../lib/messengerContext';

interface Props {
  total: number;
  limit: number;
  onLimitChange: (limit: number) => void;
}

// TODO: Performance
export const MessengerLimitDivider: FC<Props> = ({ total, limit, onLimitChange }) => {
  const [isLoading, setLoading] = useState(false);
  const { config } = useMessengerContext();

  const setLimit = useCallback(
    (limit) => {
      setLoading(true);
      setTimeout(() => onLimitChange(limit), 100);
    },
    [onLimitChange],
  );

  useEffect(() => {
    setLoading(false);
  }, [limit]);

  if ((!total && total !== limit) || total <= limit) return null;

  return (
    <Divider plain style={{ fontSize: 12, color: config?.colors.muted }}>
      <Popover
        trigger="hover"
        content={
          <Space>
            <Button size="small" type="text" onClick={() => setLimit(limit + 50)}>
              Load more
            </Button>
            {limit !== total && (
              <Button size="small" type="text" onClick={() => setLimit(500)}>
                Show all
              </Button>
            )}
          </Space>
        }
      >
        {isLoading ? <>{config?.icons.spinner} Loading more...</> : `Displayed ${limit} of ${total}`}
      </Popover>
    </Divider>
  );
};

import { useSearchPageParams } from '@/shared/components/searchEngine';
import { notificationsOrdersPopoverView, notificationsOrdersView } from '@/components/search/config';
import { orderNotificationParams } from '@/helpers/notifications';
import { SearchEngine } from '@/components/search/SearchEngine';
import { useSearchWriterOrdersQuery } from '@/api/writerOrders';
import { WriterOrdersTable } from '@feature/order/writerOrder/WriterOrdersTable';
import { NotificationOpenAllButton } from '@feature/notification/NotificationOpenAlllButton';
import { Spin } from 'antd';
import { InputSearch } from '@/components/search/InputSearch';
import { t } from '@/services/translator';
import { Debug } from '@/components/common/Debug';
import { WriterOrderStatusName } from '@feature/order/writerOrder/WriterOrderStatusName';
import { OrderCards } from '@feature/order/OrderCards';
// import { useSmartPolling } from '@/shared/hooks/useSmartPolling';
// import { COUNTER_INTERVAL_SECONDS } from '@/config/constants';

type NotificationOrdersTabProps = {
  isPopover?: boolean;
};

export const NotificationOrdersTab = ({ isPopover = true }: NotificationOrdersTabProps) => {
  const searchConfig = isPopover ? notificationsOrdersPopoverView : notificationsOrdersView;

  // const { pollingInterval } = useSmartPolling(COUNTER_INTERVAL_SECONDS);

  const { params, setParams } = useSearchPageParams(searchConfig.id, searchConfig.defaultParams);

  const { data, isFetching, isLoading } = useSearchWriterOrdersQuery(
    { ...params, ...orderNotificationParams },
    // { pollingInterval }
  );

  return isLoading ? (
    <div
      style={{
        height: 140,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Spin size="large" />
    </div>
  ) : (
    <SearchEngine
      params={params}
      setParams={setParams}
      total={data?.total}
      config={searchConfig}
      saveParams
      searchField={isPopover ? undefined : <InputSearch placeholder={t('searchEngine.searchById')} />}
      controls={isPopover ? undefined : <></>}
      extra={isPopover && <NotificationOpenAllButton tab="orders" />}
    >
      {isPopover ? (
        <WriterOrdersTable style={{ width: isPopover ? 900 : 'auto' }} items={data?.items} loading={isFetching} />
      ) : (
        <OrderCards
          items={data?.items?.map(({ id, idOrder, idStatus, currentSnapshot, originalSnapshot }) => ({
            idOrder,
            idWriterOrder: id,
            snapshot: currentSnapshot || originalSnapshot,
            feature: 'ActiveWriterOrder',
            headingBottom: (
              <div
                style={{
                  margin: '16px 0',
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <WriterOrderStatusName id={idStatus} tag />
                {/* <Space>*/}
                {/*  <FA type="fa-award" />*/}
                {/*  <FA type="fa-copy" />*/}
                {/*  <FA type="fa-arrows-repeat" />*/}
                {/*  <FA type="fa-chevron-double-down" />*/}
                {/*  <FA type="fa-ticket" />*/}
                {/*  <FA type="fa-message" />*/}
                {/* </Space>*/}
              </div>
            ),
          }))}
          loading={isFetching}
        />
      )}
      <Debug data={data} />
    </SearchEngine>
  );
};

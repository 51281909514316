import { DateTime } from '@/components/common/DateTime';
import { Table, useMemoizedColumns } from '@/components/common/Table';
import { t } from '@/services/translator';
import { TableProps } from 'antd';
import { WriterOrderSearchItem } from '@/types/writerOrder';
import { WriterOrderStatusName } from '@feature/order/writerOrder/WriterOrderStatusName';
import { WriterOrderLink } from '@feature/order/writerOrder/WriterOrderLink';
import { Currency } from '@/components/common/Currency';
import { getColor } from '@/helpers/utils';

type WriterOrdersTableProps = TableProps<WriterOrderSearchItem> & {
  items?: WriterOrderSearchItem[];
};

export const WriterOrdersTable = ({ items = [], loading, ...props }: WriterOrdersTableProps) => {
  const columns = useMemoizedColumns<WriterOrderSearchItem>([
    {
      title: t('misc.status'),
      ellipsis: true,
      render: (_, { idStatus }) => <WriterOrderStatusName id={idStatus} tag />,
    },
    {
      title: t('misc.id'),
      render: (_, { id, idStatus, idOrder }) => <WriterOrderLink id={id} idOrder={idOrder} idStatus={idStatus} showStatus />,
    },
    {
      title: t('order.orderDetails'),
      ellipsis: true,
      render: (_, { currentSnapshot, originalSnapshot }) => {
        const { title, academicDiscipline, customAcademicDiscipline, dynamicUnits, dynamicWords } = currentSnapshot || originalSnapshot;
        return (
          <div style={{ width: 350, wordBreak: 'break-word', whiteSpace: 'normal' }}>
            <div>{title}</div>
            <div style={{ color: getColor('muted') }}>
              {academicDiscipline?.writerName || customAcademicDiscipline} •{' '}
              {`${dynamicUnits} ${t('order.page', { count: dynamicUnits })} / ${dynamicWords}
              ${t('order.word', {
                count: dynamicWords,
              })}
              `}
            </div>
          </div>
        );
      },
    },
    {
      title: t('misc.price'),
      ellipsis: true,
      render: (_, { currentSnapshot, originalSnapshot }) => {
        const dynamicWriterTotalPrice = (currentSnapshot || originalSnapshot).dynamicWriterTotalPrice;
        return <Currency value={dynamicWriterTotalPrice} />;
      },
    },
    {
      title: t('misc.deadline'),
      ellipsis: true,
      render: (_, { currentSnapshot, originalSnapshot }) => {
        const dateDue = (currentSnapshot || originalSnapshot).dateDue;
        return dateDue ? <DateTime value={dateDue} fromNow /> : t('misc.notAvailable');
      },
    },
  ]);

  return <Table dataSource={items} columns={columns} loading={loading} {...props} />;
};

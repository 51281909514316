import { FontAwesomeIcon, FontAwesomeThemes } from '@/types/common/fa';
import { Tooltip } from 'antd';
import cn from 'classnames';
import React, { CSSProperties, MouseEventHandler, ReactNode } from 'react';

// Styles
import s from './FA.module.less';

export interface FontAwesomeIconProps {
  className?: string;
  theme?: FontAwesomeThemes;
  type: FontAwesomeIcon | string;
  color?: string;
  text?: ReactNode;
  prefix?: JSX.Element | string;
  onClick?: MouseEventHandler<HTMLSpanElement>;
  tooltip?: ReactNode | string;
  style?: CSSProperties;
}

export const FA: FC<FontAwesomeIconProps> = ({ className = '', theme = 'fal', type, color, onClick, style, ...props }) => {
  const iconClassName: string = cn(s.ico, theme, type, 'icon');
  return (
    <Tooltip className={s.tooltip} title={props.tooltip}>
      <span className={cn(s.wrapper, className, { [s.clickable]: !!onClick })} style={{ color, ...style }} onClick={onClick}>
        {props.prefix} <i className={cn(s.ico, iconClassName)} />
        {!!props.text && <span className={s.text}>{props.text}</span>}
      </span>
    </Tooltip>
  );
};

import { Table as AntTable, TableProps } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { ReactNode, useMemo } from 'react';

interface Props<T> extends TableProps<T> {}

/**
 * Ant Table HOC
 * Check: OutgoingTransactionsTable.tsx for example
 * @param props
 * @constructor
 */

type ColumnBaseProps<T> = Omit<ColumnProps<T>, 'dataIndex' | 'render'>;

// Typed Column
type ColumnComponentProps<T, V extends keyof T> = {
  dataIndex?: V;
  render?: (value: T[V], record: T) => ReactNode;
} & ColumnBaseProps<T>;

type UseSmartTableReturnType<T> = {
  Column: <V extends keyof T>(props: ColumnComponentProps<T, V>) => JSX.Element;
  tableProps: Partial<TableProps<T>>;
};

/**
 * Decorated antd table...
 * @param className
 * @param props
 * @constructor
 */
export const Table = <T extends { id: number }>({ className, ...props }: Props<T>): JSX.Element => (
  <AntTable className={className} rowKey="id" pagination={false} tableLayout="fixed" size="middle" scroll={{ x: true }} {...props} />
);

Table.SELECTION_INVERT = AntTable.SELECTION_INVERT;
Table.Summary = AntTable.Summary;

/**
 * Ant Table HOC - Column
 */
export const useSmartTable = <T,>(): UseSmartTableReturnType<T> => {
  const Column = <V extends keyof T>(_: ColumnComponentProps<T, V>) => <AntTable.Column />;
  const tableProps = {};
  return { Column, tableProps };
};

type ColumnsMemoizedArray<T> = ({
  hidden?: boolean;
  dataIndex?: keyof T; // when (_, record) -> you can skip this key
  index?: number;
  render?: (value: any, record: T) => ReactNode;
} & ColumnBaseProps<T>)[];

type UseMemoizedColumnsHook = <T>(mainCols: ColumnsMemoizedArray<T>) => ColumnsMemoizedArray<T>;
// Use this hook for memoized typed columns
export const useMemoizedColumns: UseMemoizedColumnsHook = (cols = []) => {
  return useMemo(() => cols.filter(({ hidden }) => !hidden), [cols]);
};

import { useViewCurrentUserProfileQuery } from '@/api/profile';
import { FA } from '@/components/common/icons/FA';
import { CustomLink } from '@/components/common/router/CustomLink';
import { useAppDispatch } from '@/hooks/redux';
import { t } from '@/services/translator';
import { resetSearchPagination } from '@/store/localPreferences/localPreferenceSlice';
import s from './Navigation.module.less';
import cn from 'classnames';
import { useGracefulLogout } from '@/store/auth/authHooks';
import { SITE_MENU, SiteMenuItem } from '@/config/features';
import { WinSm } from '@/hooks/breakpoints/winSizes';
import { usePreferences } from '@/hooks/preferences';
import { useAppContext } from '@/components/AppContext';
import { MenuTooltipParams } from '@/components/common/layout/AppBaseLayout';
import { MouseEvent } from 'react';

type TNavigationProps = {
  setDrawerVisible: (value: boolean) => void;
  onCollapsedMenuItemHover: (value: MenuTooltipParams | null) => void;
};

export const Navigation: FC<TNavigationProps> = ({ setDrawerVisible, onCollapsedMenuItemHover }) => {
  const { data: user } = useViewCurrentUserProfileQuery();
  const { logout } = useGracefulLogout();
  const dispatch = useAppDispatch();

  const { isFullscreenMode } = useAppContext();

  const { preferences } = usePreferences();

  const { isCompactNavigation } = preferences;

  if (!user) return null;

  const menuItems = SITE_MENU.filter((item) => (item.renderCondition ? item.renderCondition(user) : true))
    .reduce((prev, next) => [...prev, ...next.items], [] as SiteMenuItem[])
    .filter((item) => (item.renderCondition ? item.renderCondition(user) : true));

  const handleItemClick = () => {
    dispatch(resetSearchPagination());
    setDrawerVisible(false);
  };

  const menuGroups = SITE_MENU.filter(({ items }) => !!items.find((item) => menuItems.includes(item))).map(({ name, items }) => ({
    name,
    items: items.filter((item) => menuItems.includes(item)),
  }));

  const handleItemMouseEnter = (event: MouseEvent, content: string) => {
    if (!isCompactNavigation) return;
    const { top, left } = (event.target as HTMLElement).getBoundingClientRect();

    const params = {
      top: top + (event.target as HTMLElement).clientHeight / 2 - 10,
      left,
      content,
    };

    onCollapsedMenuItemHover({
      ...params,
      visible: false,
    });

    setTimeout(() => {
      onCollapsedMenuItemHover({
        ...params,
        visible: true,
      });
    }, 0);
  };

  const handleItemMouseLeave = () => {
    onCollapsedMenuItemHover(null);
  };

  return (
    <div className={cn(s.wrapper, { [s.compact]: isCompactNavigation })}>
      {!isFullscreenMode &&
        menuGroups.map(({ name, items }) => (
          <div className={s.menuGroupWrap} key={name}>
            <div className={s.menuGroupName}>{t(name)}</div>
            <ul className={s.menuGroup}>
              {items.map((item) => (
                <li key={item.path || item.title} className={s.item}>
                  <CustomLink
                    to={String(item.path)}
                    onClick={handleItemClick}
                    className={s.link}
                    activeClassName={s.active}
                    tabIndex={1}
                    onMouseEnter={(event) => handleItemMouseEnter(event, t(item.title))}
                    onMouseLeave={handleItemMouseLeave}
                  >
                    {item.icon && <FA type={item.icon} className={s.icon} />}
                    <span className={s.text}>{t(item.title)}</span>
                  </CustomLink>
                </li>
              ))}
            </ul>
          </div>
        ))}

      <WinSm>
        <div className={s.item}>
          <div
            className={s.link}
            onClick={() => {
              setDrawerVisible(false);
              logout();
            }}
          >
            <FA type="fa-sign-out-alt" text={t('profile.logOut')} />
          </div>
        </div>
        <div className={s.item}>
          <div
            className={s.link}
            onClick={() => {
              setDrawerVisible(false);
              logout(true);
            }}
          >
            <FA type="fa-right-from-bracket" theme="fad" text={t('profile.logOutGlobal')} />
          </div>
        </div>
      </WinSm>
    </div>
  );
};

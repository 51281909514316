import { FA } from '@/components/common/icons/FA';
import { Tooltip } from 'antd';
import { ReactNode } from 'react';
import cn from 'classnames';
import { Trunc } from './Trunc';
import { upperFirst } from 'lodash';

import s from './InfoTooltip.module.less';

type InfoTooltipProps = {
  text?: ReactNode | string;
  type?: 'question' | 'info';
  size?: 'small' | 'normal';
  className?: string;
  truncate?: boolean;
};

export const InfoTooltip: FC<InfoTooltipProps> = ({ text, children, type = 'question', size = 'small', className, truncate }) =>
  text ? (
    <Tooltip title={truncate ? <Trunc text={String(text)} max={500} lines={5} /> : text}>
      {children}{' '}
      <sup className={cn('opacity-5', s[`size${upperFirst(size)}`], className)}>
        <FA className="icon-hl" type={`fa-${type}-circle`} theme="far" color="var(--black-color)" />
      </sup>
    </Tooltip>
  ) : null;

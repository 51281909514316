import { useState } from 'react';
import { Popover } from 'antd';
import { NotificationButton } from '@feature/notification/NotificationButton';
import { NotificationTabs } from '@feature/notification/NotificationTabs';

export const NotificationPopover = () => {
  const [visible, setVisible] = useState(false);

  return (
    <Popover
      trigger="click"
      placement="bottomRight"
      open={visible}
      onOpenChange={setVisible}
      destroyTooltipOnHide
      overlayStyle={{
        width: 932,
        maxWidth: '100%',
      }}
      overlayInnerStyle={{
        minHeight: 250,
      }}
      content={
        <NotificationTabs
          onLinkClick={() => {
            setVisible(false);
          }}
        />
      }
    >
      <div>
        <NotificationButton />
      </div>
    </Popover>
  );
};
